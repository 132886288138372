import ContactsPage from './pages/ContactsPage';
import DatePage from './pages/DatePage';
import EditAppointmentPage from './pages/EditAppointmentPage';
import ServicesPage from './pages/ServicesPage';
import StorePage from './pages/StorePage';

export default {
    product: 'IQOS',
    palette: {
        '--primary-soft-white': '#fffdfb',
        '--primary-slate': '#34303d',
        '--secondary-red': '#db3826',
        '--secondary-amber': '#e27d34',
        '--secondary-blue': '#2980b5',
        '--secondary-yellow': '#ffd040',
        '--secondary-deep-red': '#a22f26',
        '--iluma-bright-teal': '#1bd9c5',
        '--iluma-bright-blue': '#00c1ff',
        '--iluma-bright-red': '#ef0117',
        '--iluma-bright-yellow': '#f8b80f',
        '--background-hard-white': '#f6f4f0',
        '--alerts-error-red': '#c73636',
        '--alerts-success-green': '#57760c',
        '--colours-alerts-amber': '#e5b052',
        '--colours-primary-dark-blue-fill-100': '#272a33',
        '--colours-grey-tones-dark-grey': '#545454',
        '--tints-slate-slate-85': '#524f5a',
        '--tints-slate-slate-65': '#7b7881',
        '--tints-slate-slate-50': '#99979e',
        '--tints-slate-slate-30': '#b8b7bb',
        '--tints-slate-slate-15': '#e1e0e2',
        '--primary-white': '#ffffff',
        '--primary-turquoise': '#00d1d2',
    },
    navigation: {
        steps: [
            {
                component: StorePage,
                label: "Negozio",
                configs: {
                    searchMap: {
                        icon: "/assets/home.png",
                        markerIcons: {
                            selected: "/assets/pin-visited.svg",
                            normal: "/assets/pin.svg",
                            selectedSummer: "/assets/pin.svg",
                            normalSummer: "/assets/pin-visited.svg"
                        },
                        infoBox: {
                            titleText: ['name'],
                            bodyText: ['address', '\n', 'zipCode', ' - ', 'city'],
                            buttonText: 'Conferma'
                        }
                    },
                    searchList: {
                        iconItem: "place",
                        itemText: ['name', ' - ', 'address']
                    },
                    notFound: {
                        icon: 'house'
                    }
                },
                number: '0'
            },
            {
                component: ServicesPage,
                label: "Services",
                configs: {
                    searchList: {
                        iconItem: "place",
                        itemText: ['name', ' - ', 'address']
                    },
                },
                number: '0.2'
            },
            {
                component: DatePage,
                label: "Data",
                number: '1'
            },
            {
                component: ContactsPage,
                label: "Dati Personali",
                number: '2'
            }
        ],
        recap: {
            configs: {
                component: EditAppointmentPage,
            }
        }
    },
    mapOptions: {
        disableDefaultUI: true,
        gestureHandling: "cooperative",
        styles: [
            {
                elementType: "geometry",
                stylers: [{ color: "#f7f7f7" }],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#f7f7f7" }],
            },
            {
                elementType: "labels.text.fill",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#31746b" }],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#31746b" }],
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }],
            },
            {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#31746b" }],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "transit.station",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c4dce6" }],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#e4e4e4" }],
            },
            {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#c4dce6" }],
            },
            {
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
        ],
    },
}
