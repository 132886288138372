<template>
  <transition name="fade" mode="out-in">
    <div class="recap" :class="{ 'two-item': appointment || customer }">
      <div>
        <!-- appointment -->
        <div v-if="appointment" class="recap-item boxed">
          <div class="text-recap">
            <img src="/assets/calendar-icon.svg" />
            <span class="text">
              <span class="bold">{{ $d(appointment.startTime, 'long') }}</span>
              <span>{{ `${$t('Ore')} ${$d(appointment.startTime, 'time')}` }}</span>
            </span>
          </div>
          <div class="button-change">
            <md-button v-if="!changingData" @click="editDate()">
              <span class="change-text">{{ $t('Modifica') }}</span>
            </md-button>
          </div>
        </div>

        <!-- edit appointment data -->
        <div class="line" v-if="changingData"></div>
        <transition name="fade" mode="out-in">
          <div v-if="changingData" class="editing-date">
            <div>
              <Calendar
                :disabled-dates="disabledDates"
                :pre-selected-date="selectedDay"
                :store-id="storeId"
                @day-click="selectDay($event)"
              ></Calendar>

              <div class="time-picker">
                <p class="subtitle">{{ $t('Quando desideri venire a trovarci?') }}</p>
                <Timepicker
                  class="picker"
                  :slots="slots"
                  :isLoading="loadingTimeSlots"
                  :type="'full'"
                  @selected="selectTime($event)"
                ></Timepicker>
              </div>
            </div>

            <md-button class="button md-primary" @click="saveChanges()">{{ $t('Salva modifiche') }}</md-button>
          </div>
        </transition>

        <!-- customer -->
        <form
          v-if="customer"
          class="recap-item md-layout"
          novalidate
          @submit.prevent="editCustomer"
        >
          <div class="boxed">
            <div class="text-recap">
              <img src="/assets/user.svg" />
              <span class="text">
                <span class="bold">{{ customer.name }} {{ customer.surname }}</span>
                <span class="email">{{ customer.email }}</span>
                <span class="phone">{{ customer.phone }}</span>
              </span>
            </div>
            <div class="button-change">
              <md-button v-if="!changingCustomer" type="submit">
                <span class="change-text">{{ $t('Modifica') }}</span>
              </md-button>
            </div>
          </div>
          <!-- edit customer data -->
          <div class="line" v-if="changingCustomer"></div>
          <transition name="fade" mode="out-in">
            <div v-if="changingCustomer" class="editing-customer">
              <div class="two-input first">
                <md-field :class="getValidationClass('name')" md-inline>
                  <label for="first-name">{{ $t('Nome') }}*</label>
                  <md-input
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    v-model="form.name"
                    @change="emitChanges"
                  />
                  <span
                    class="md-error"
                    v-if="!$v.form.name.required"
                  >{{ $t('Inserisci il tuo nome per proseguire') }}</span>
                  <span
                    class="md-error"
                    v-else-if="!$v.form.name.minlength"
                  >{{ $t('Nome troppo corto') }}</span>
                </md-field>

                <md-field :class="getValidationClass('surname')" md-inline>
                  <label for="last-name">{{ $t('Cognome') }}*</label>
                  <md-input
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    v-model="form.surname"
                    @change="emitChanges"
                  />
                  <span
                    class="md-error"
                    v-if="!$v.form.surname.required"
                  >{{ $t('Inserisci il tuo cognome per proseguire') }}</span>
                  <span
                    class="md-error"
                    v-else-if="!$v.form.surname.minlength"
                  >{{ $t('Cognome troppo corto') }}</span>
                </md-field>
              </div>

              <div class="two-input">
                <md-field :class="getValidationClass('email')" md-inline>
                  <label for="email">Email*</label>
                  <md-input
                    disabled
                    type="email"
                    name="email"
                    id="email"
                    autocomplete="email"
                    v-model="form.email"
                    @change="emitChanges"
                  />
                  <span
                    class="md-error"
                    v-if="!$v.form.email.required"
                  >{{ $t('Inserisci la tua email per proseguire') }}</span>
                  <span
                    class="md-error"
                    v-else-if="!$v.form.email.email"
                  >{{ $t('Email non valida') }}</span>
                </md-field>

                <md-field :class="getValidationClass('phone')" md-inline>
                  <label for="phone">{{ $t('Telefono') }}</label>
                  <md-input
                    id="phone"
                    name="phone"
                    autocomplete="phone"
                    v-model="form.phone"
                    @change="emitChanges"
                  />
                  <span
                    class="md-error"
                    v-if="$v.form.phone.$invalid"
                  >{{ $t('Numero di telefono non valido') }}</span>
                </md-field>
              </div>

              <md-button
                class="button md-primary"
                @click="saveChanges()"
              >{{ $t('Salva modifiche') }}</md-button>
            </div>
          </transition>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import { ApiService } from "../services/api";
import { GTMService } from "../services/gtm";
import { required, email, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Calendar from "./Calendar";
import Timepicker from "./Timepicker";
import utils from "../helpers/utils";

const phoneValidator = value => (value ? /^[+]?\d+$/.test(value) : true);

export default {
  name: "RecapEdit",
  data: () => ({
    changingData: false,
    changingCustomer: false,
    disabledDates: [],
    form: {
      name: null,
      surname: null,
      phone: null,
      email: null
    },
    formTouched: false,
    loadingTimeSlots: false,
    selectedTime: null,
    slots: null
  }),
  props: {
    store: Object,
    appointment: Object,
    customer: Object,
    reservationId: String,
    storeId: String
  },
  computed: {
    today() {
      return new Date();
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      phone: {
        phoneValidator
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    // methods for changing data
    async editDate() {
      this.changingData = !this.changingData;

      if (this.changingData) {
        this.loadingTimeSlots = true;

        this.selectedDay = this.appointment.startTime;
        this.slots = [];

        this.selectDay({
          date: this.appointment.startTime
        });
      }
    },
    async getSlots() {
      const slots = (
        await ApiService.get(
          `/api/store_services/${this.storeId}/timeslots`,
          {
            date: `${new Date(this.selectedDay).getDate()}-${new Date(
              this.selectedDay
            ).getMonth() + 1}-${new Date(this.selectedDay).getFullYear()}`
          },
          []
        )
      ).data["hydra:member"];

      const currentSlot = `${(
        "0" + this.appointment.startTime.getHours().toString()
      ).slice(-2)}:${(
        "0" + this.appointment.startTime.getMinutes().toString()
      ).slice(-2)}`;

      // if is today filtered out slots before current time
      this.slots = (utils.isToday(this.selectedDay)
        ? slots.filter(x => {
            const dateTimeSlot = new Date(this.selectedDay);
            dateTimeSlot.setHours(+x.slot.split(":")[0]);
            dateTimeSlot.setMinutes(+x.slot.split(":")[1]);

            return dateTimeSlot.getTime() > new Date().getTime();
          })
        : slots
      ).filter(x => x.status !== "closed" || x.slot === currentSlot);

      // add preselected time-slot
      for (const x of this.slots) {
        x.isSelected = x.slot === currentSlot;
      }
    },
    async selectDay(day) {
      if (!day.isDisabled) {
        this.selectedDay = day.date;
        await this.getSlots();

        const timeLabel = `${(
          "0" + this.appointment.startTime.getHours().toString()
        ).slice(-2)}:${(
          "0" + this.appointment.startTime.getMinutes().toString()
        ).slice(-2)}`;

        for (const x of this.slots) {
          x.isSelected = x.slot === timeLabel;
        }

        if (!this.slots.find(x => x.isSelected)) {
          this.slots[0].isSelected = true;
        }

        this.slots = [...this.slots];
        setTimeout(() => {
          this.selectTime(this.slots.find(x => x.isSelected));
        }, 100);

        this.emitChanges();

        if (window.innerWidth <= 768) {
          window.parent.postMessage(
            {
              message: "scrollBottom",
              animated: true,
              coordinate: document
                .querySelector(".time-picker .subtitle")
                .getBoundingClientRect().y
            },
            "*"
          );
        }
      } else {
        this.selectedDay = null;
      }

      this.loadingTimeSlots = false;
    },
    saveChanges() {
      this.$emit("saveEvent", null);
    },
    selectTime(time) {
      const startTime = new Date(this.selectedDay);
      startTime.setHours(Number(time.slot.split(":")[0]));
      startTime.setMinutes(Number(time.slot.split(":")[1]));

      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + time.duration);

      Object.assign(this.appointment, { startTime, endTime });

      this.emitChanges();
    },
    // methods for changing customer
    async editCustomer() {
      this.changingCustomer = !this.changingCustomer;

      if (this.changingCustomer) {
        if (!Object.values(this.form).some(x => !!x)) {
          this.form = {
            name: this.customer.name,
            surname: this.customer.surname,
            email: this.customer.email,
            phone: this.customer.phoneNumber || ""
          };
        }
      } else {
        // save changes
        this.$v.$touch();
        this.formTouched = true;
        if (!this.$v.$invalid) {
          Object.assign(this.customer, {
            name: this.form.name,
            surname: this.form.surname,
            email: this.form.email,
            phone: this.form.phone || ""
          });

          this.emitChanges();
        } else {
          // GTM events
          if (this.$v.form.email.$invalid) {
            if (this.form.phone) {
              GTMService.send("Form Failed", "Wrong email", null, false);
            } else {
              GTMService.send(
                "Form Failed",
                "Wrong email & Missing number",
                null,
                false
              );
            }
          } else if (!this.form.phone) {
            GTMService.send("Form Failed", "Wrong email", null, false);
          }
        }
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    collapseEdit() {
      if (this.changingData) {
        this.editDate();
      }
      if (this.changingCustomer) {
        this.editCustomer();
      }
    },
    emitChanges() {
      if (!Object.values(this.form).some(x => !!x)) {
        this.form = {
          name: this.customer.name,
          surname: this.customer.surname,
          email: this.customer.email,
          phone: this.customer.phone || ""
        };
      }

      // save changes
      this.$v.$touch();
      if (!this.$v.$invalid) {
        Object.assign(this.customer, {
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.email,
          phoneNumber: this.form.phone || ""
        });
        this.$emit("reservationChanged", {
          startTime: this.appointment.startTime,
          endTime: this.appointment.endTime,
          clients: [this.customer]
        });
      } else {
        this.$emit("reservationChanged", null);
      }
    }
  },
  components: {
    Calendar,
    Timepicker
  }
};
</script>

<style lang="scss" scoped>
.boxed {
  border: 1px solid var(secondaryDark);
  background: white;
  min-height: 84px;
  font-size: 13px;
  line-height: 1.3;
  display: flex;
  width: 100%;
}

.subtitle {
  margin: 30px 0 20px 0;
}
.recap {
  display: flex;

  &.two-item {
    .recap-item {
      width: 45%;
      overflow: hidden;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  &.two-lines {
    flex-direction: column;
    .recap-item {
      width: 100%;
      margin: 12px 0;
    }
  }

  .recap-item {
    display: flex;
    flex: 1;
    width: 100%;
    background: var(--primary-white);
    border-radius: 4px;

    .text-recap {
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      padding: 0 16px;

      .text {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 16px;
        font-size: 14px;

        overflow: hidden;

        .bold {
          font-family: iqos-bold;
          font-size: 16px;
        }

        span {
          overflow: hidden;
          text-transform: capitalize;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:last-child {
            padding-top: 2px;
          }
        }

        .email {
          text-transform: unset;
        }

        .phone {
          padding-top: 0 !important;
        }
      }

      img {
        width: 20px;
      }
    }
  }

  .button-change {
    display: flex;
    align-items: center;
    padding-right: 8px;

    & > button {
      margin: 0;
      line-height: 0;
      color: var(--primary-turquoise);
      text-decoration: underline;
      text-transform: unset !important;

      & > div {
        padding: 0 !important;
      }

      .icon-settings {
        font-size: 18px !important;
      }
    }

    .change-text {
      padding-right: 4px;
      font-size: 16px;
    }
  }
}

.bold {
  font-weight: 600;
}

.line {
  width: 100%;
  height: 1px;
  background: var(--primary-white);
  padding: 0 24px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: var(--tints-slate-slate-15);
    margin: 0 auto;
  }
}

// for editing parts
.editing-date {
  display: flex;
  flex-direction: column;
  background: var(--primary-white);
  padding: 40px 24px;
  border-bottom: 24px solid var(--background-hard-white);

  > div {
    display: flex;
  }

  .calendar__container {
    min-width: unset;
    flex: 0.5;
    margin-right: 40px;
  }

  .time-picker {
    flex: 0.5;

    .subtitle {
      margin-top: 0;
    }
  }

  button {
    width: 180px;
    margin-top: 24px;
  }
}

.editing-customer {
  flex: 1;
  padding: 24px;
  background: var(--primary-white);

  form {
    display: flex;
    flex-direction: column;
  }

  > div:first-child {
    margin-top: 0 !important;
  }

  .two-input {
    display: flex;
    flex: 1;
    margin: 16px 0 0 0;

    &.first {
      margin-top: -8px;
    }
  }

  .md-field {
    margin: 0;

    label {
      background: var(--primary-white);
      top: 30px;
      left: 14px;
    }

    input {
      border-radius: 4px;
      border: 1px solid var(--tints-slate-slate-15);
      height: 48px;
      padding: 0 16px;
      margin: 0;
    }

    &.md-focused,
    &.md-has-value {
      label {
        color: var(--primary-slate) !important;
        top: 4px;
        padding: 0 4px;
        opacity: 1;
        z-index: 999;
        font-size: 12px;
      }
      input {
        border-color: var(--primary-slate);
        border-width: 1.5px;
      }
    }

    &:before,
    &:after {
      display: none;
    }

    &:first-child {
      margin-right: 24px;
    }
  }

  button {
    width: 180px;
    margin-top: 24px;
  }

  #email {
    opacity: 0.7;
  }
}

@media (max-width: 768px) {
  .boxed {
    background: white;
    flex-wrap: wrap;
  }

  .recap {
    &.two-item {
      flex-direction: column;
      width: auto;

      .recap-item {
        width: 100% !important;

        &:first-child {
          margin-right: 0;
        }

        .text-recap {
          .text {
            letter-spacing: 1px;
            font-size: 14px;
          }
        }
      }
    }

    .recap-item {
      padding: 8px 0;
    }
  }

  .editing-date {
    > div {
      flex-direction: column;
    }

    .time-picker {
      margin: 0 0 20px 0;
    }

    .calendar__container {
      margin-right: 0 !important;
      flex: 1;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .editing-customer {
    .two-input {
      flex-direction: column;
      margin: 0 !important;
    }

    .md-field {
      margin-bottom: 18px !important;

      &:first-child {
        margin-right: 0;
      }

      input {
        height: 52px;
      }

      &:not(.md-has-value) label {
        top: 33px;
      }

      &.md-focused,
      &.md-has-value {
        label {
          top: 4px;
        }
      }
    }
  }
}
@media (max-width: 812px) and (orientation: landscape) {
  .editing-date {
    flex-direction: column;

    .calendar__container {
      flex: 1;
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
  }
}
</style>
