 <template>
    <div>
        <div class="gmap__container">
            <div class="gmap__input-container">
                <img class="gmap__search-icon" src="/assets/search.svg" />
                <gmap-autocomplete id="gmap__input"
                                   ref="gmapsearch"
                                   class="md-field md-autocomplete gmap__input"
                                   :class="{'md-error': errorGmapInput}"
                                   :options="autocompleteOptions"
                                   :placeholder="placeholder"
                                   :value="stringAddressValue"
                                   @click="errorGmapInput = false"
                                   @focus="setFocus($event)"
                                   @blur="setFocus($event)"
                                   @place_changed="onPlaceChanged($event)">
                </gmap-autocomplete>

                <md-button @click="clearInput()" class="md-icon-button gmap__clear-icon" v-show="stringAddressValue && stringAddressValue.length" :class="{ 'hidden': !stringAddressValue || !stringAddressValue.length }">
                    <img src="/assets/close.svg" />
                </md-button>

                <div class="gmap__locate">
                    <md-button @click="getMyLocation()" class="md-icon-button gmap__locate-icon" v-show="!isGettingLocation">
                        <img src="/assets/locate.svg" />
                    </md-button>
                    <md-progress-spinner class="gmap__spinner"
                                         :class="{ 'hidden': !isGettingLocation }"
                                         :md-diameter="6"
                                         :md-stroke=".5"
                                         md-mode="indeterminate"></md-progress-spinner>
                </div>
            </div>
        </div>
        <span class="md-error" v-if="errorGmapInput">{{ $t('Seleziona un indirizzo dalla lista') }}</span>
        <div v-if="searchedPlace && getStoresNear && getStoresNear.length != 0 && !searching" class="gmap-map">
            <!-- map -->
            <gmap-map class="gmap-map__map"
                      ref="map"
                      :options="mapOptions"
                      :center="searchedPlace ? { lat: searchedPlace.latitude ? searchedPlace.latitude : ( searchedPlace.lat ? searchedPlace.lat : searchedPlace.geometry.location.lat()), lng: searchedPlace.longitude ? searchedPlace.longitude : ( searchedPlace.lng ? searchedPlace.lng : searchedPlace.geometry.location.lng())} : { lat: 0, lng: 0 }"
                      :zoom="10">

                <gmap-marker v-for="(marker, key) in markers" :key="key"
                             :position="{ lat: marker.latitude, lng: marker.longitude }"
                             :clickable="true"
                             :icon="{ scaledSize: { width: 25, height: 40, f: 'px', b: 'px' }, url: computeIcon(marker) }"
                             @click="showInfo(marker)" />
            </gmap-map>
            <!-- marker info -->
            <div class="gmap-map__info" v-if="infoBoxInfo['@id']">
                <p class="gmap-map__info__title">{{ infoBoxInfo.name }}</p>
                <p class="gmap-map__info__address">{{ infoBoxInfo.address }}</p>
                <!-- <div v-if="this.currentHours.length">
                    <p v-if="currentDayStatus">
                        {{ $t('Oggi siamo aperti: ') }}
                        <span>{{this.currentHours[0]}}</span>
                        <span v-if="this.currentHours.length == 2">, {{this.currentHours[1]}}</span>
                    </p>
                     <p v-else>
                        {{ $t('Oggi siamo chiusi') }}
                    </p>
                </div> -->
                
                <!-- <div class="all-services">
                    <p class="fake-button" v-for="(service, key) in infoBoxInfo.storeServices" :key="key">
                        {{service.externalName}}
                    </p>
                </div> -->

                <md-button class="button" @click="select(infoBoxInfo)">
                    {{ $t('Conferma') }}
                </md-button>
            </div>
        </div>
        <transition name="fade" mode="in-out">
            <div>
                <div class="map-throbber-container" v-show="searchedPlace && searching">
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                </div>
                <div v-show="searchedPlace && getStoresNear && getStoresNear.length == 0 && !searching">
                    <StoreNotFoundPage :configs="notFoundConfigs"></StoreNotFoundPage>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GTMService } from "../services/gtm";
import configs from "../configs";
import StoreNotFoundPage from "../pages/StoreNotFoundPage";
import utils from "../helpers/utils";
import Vue from "vue";
import {ApiService} from "@/services/api";
import {SET_STORES_NEAR} from "@/store/action.type";
var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export default {
    name: "GMAPSearch",
    props: {
        selectedMarker: Object,
        markerIcons: Object,
        icon: String,
        button: String,
        notFoundConfigs: Object,
        placeholder: String,
        markers: Array,
        mapText: String,
        searching: Boolean,
    },
    async created() {
      if (this.selectedMarker) {
        this.searchedPlace = this.selectedMarker;
      }

      setTimeout(() => {
        if (this.$refs.gmapsearch) {
          this.$refs.gmapsearch.$el.addEventListener('keydown', (x) => {
            this.stringAddressValue = x.target.value;
          });
        }
      }, 1000)
    },
    computed: {
        ...mapGetters(["getStoresNear"]),
    },
    data: () => ({
        isGettingLocation: false,
        gettingLocationTimeout: null,
        searchedPlace: null,
        currentPlace: null,
        stores: null,
        stringAddressValue: "",
        errorGmapInput: false,
        autocompleteOptions: {
            componentRestrictions: { country: "it" },
            types: [],
            fields: ["geometry", "formatted_address", "address_components"],
        },
        mapOptions: configs.mapOptions,
        infoBoxInfo: {},
        currentDay: days[new Date().getDay()],
        currentHours: [],
        currentDayStatus: false,
    }),
    methods: {
        ...utils,
        clearInput() {
            this.stringAddressValue = '';
        },
        async checkIfThereIsAtLeastOneStoreForSelectedZipcode(zipcode) {
          const stores = Array.from(
              new Set(
                  (
                      await ApiService.get(
                          "/api/stores",
                          {products: this.$store.getters.getProductUUID, zipCodeForCity: zipcode, pagination: false },
                          [
                            "name",
                            "city",
                            "address",
                            "storeType",
                            "storeServices",
                            "latitude",
                            "longitude",
                            "zipCode",
                            "province",
                            "phoneNumber",
                            "businessHours",
                            "slug"
                          ]
                      )
                  ).data["hydra:member"]
              )
          );
/*          if (typeof stores[0] !== 'undefined') {
            var eachCounter = 0;
            stores.forEach(function(element) {
              if(element.storeServices.length == 0) {
                stores.splice(eachCounter, 1);
              }
              eachCounter++;
            });
          }*/
          setTimeout(() => {
          this.$store.dispatch(SET_STORES_NEAR, stores);
            this.searchIsFinished = true;
            if (typeof stores[0] !== 'undefined'){
               this.stores = stores;
               this.stringAddressValue = stores[0].city;
               this.currentPlace = {lat: stores[0].latitude, lng: stores[0].longitude, "geometry": [{ latitude: stores[0].latitude, longitude: stores[0].longitude }]};
               this.onAutoSubmitSearch();
            }

          }, 500);
        },
        async getStoreHour(storeId) {
          return Array.from(
              new Set(
                  (
                      await ApiService.get(
                          "/api/stores/"+storeId+"/week",
                          {pagination: false },
                          [
                            
                          ]
                      )
                  ).data["hydra:member"]
              )
          );
        },
        setFocus(event) {
            event.target.parentNode.classList.toggle('md-focused');
        },
        computeIcon(marker) {
            return this.selectedMarker && this.selectedMarker["@id"] === marker["@id"]
                ? this.markerIcons.selected
                : this.infoBoxInfo["@id"] !== marker["@id"] ? this.markerIcons.normal : this.markerIcons.selected;

            // var setMarker = null;
            // if(marker.storeType == "Summer"){
            //     setMarker = this.selectedMarker && this.selectedMarker["@id"] === marker["@id"] ? this.markerIcons.selectedSummer : this.infoBoxInfo["@id"] !== marker["@id"] ? this.markerIcons.normalSummer : this.markerIcons.selectedSummer;
            // } else {
            //     setMarker = this.selectedMarker && this.selectedMarker["@id"] === marker["@id"] ? this.markerIcons.selected : this.infoBoxInfo["@id"] !== marker["@id"] ? this.markerIcons.normal : this.markerIcons.selected;
            // }
            //  return setMarker
        },
        select(store) {
            //console.log("click select store -> ", store);
            this.$emit("selected", store);
            GTMService.send('Click Button Store Item', store.name, null, false);
        },
        searchAddress() {
            this.searchedPlace = this.currentPlace;
            this.search(
                this.currentPlace.lat ? this.currentPlace.lat : this.currentPlace.geometry.location.lat(),
                this.currentPlace.lng ? this.currentPlace.lng : this.currentPlace.geometry.location.lng()
            );
        },
        onSubmitSearch() {
            if (this.currentPlace && (this.currentPlace.lat || this.currentPlace.geometry)) {
                this.search(
                    this.currentPlace.lat ? this.currentPlace.lat : this.currentPlace.geometry.location.lat(),
                    this.currentPlace.lng ? this.currentPlace.lng : this.currentPlace.geometry.location.lng()
                );
                this.searchedPlace = this.currentPlace;
            }
        },
        onAutoSubmitSearch() {
          if (this.currentPlace && (this.currentPlace.lat || this.currentPlace.geometry)) {
            this.searchedPlace = this.currentPlace;
          }
        },
        search(lat, lng) {
            this.$emit("search", { lat: lat, lng: lng });
        },
        async showInfo(marker) {
            // console.log("Click Pin Item address -> ", marker.address);
            // console.log("Click Pin Item zipCode -> ", marker.zipCode);
            // console.log("Click Pin Item city -> ", marker.city);
            GTMService.send('Click Pin ADDRESS', marker.address, null, false);
            GTMService.send('Click Pin ZIPCODE', marker.zipCode, null, false);
            GTMService.send('Click Pin CITY', marker.city, null, false);

            var hourStore = await this.getStoreHour(marker.uuid);
            this.infoBoxInfo = marker;
            this.currentHours = [];
            const todayPeriod = hourStore.find(period => period.day === this.currentDay);
            if(todayPeriod){
                this.currentDayStatus = !todayPeriod.isClosed;
                if(todayPeriod.timePeriod){
                    for (let index = 0; index<todayPeriod.timePeriod.length; index++) {
                        var currentBusinessHours = todayPeriod.timePeriod[index];
                        this.currentHours.push(currentBusinessHours.openTime + " - " + currentBusinessHours.closeTime);
                    }
                }
            }   
        },
        onPlaceChanged(event) {
            this.searchedPlace = null;
            this.stringAddressValue = event.formatted_address;
            this.currentPlace = event;
            this.onSubmitSearch();
            // console.log("onPlaceChanged city ->", event.address_components[2].long_name );
            // console.log("onPlaceChanged zipcode ->", event.address_components[6].short_name );
            // console.log("onPlaceChanged longname ->", this.stringAddressValue);
            if(event.address_components[2] && event.address_components[2].long_name != "") {
                GTMService.send('Search CITY', event.address_components[2].long_name, null, false);
            }
            if(event.address_components[6] && event.address_components[6].short_name != "") {
                GTMService.send('Search ZIPCODE', event.address_components[6].short_name, null, false);
            }
            if(this.stringAddressValue != "") {
                GTMService.send('Search ADDRESS', this.stringAddressValue, null, false);
            }
        },
        async searchFromCap() {
          // check if there is a cap in url
          var zipCodeFromURL = RegExp("[?&]zipcode=([^&]*)").exec(window.location.search);
          if (!zipCodeFromURL) {
            zipCodeFromURL = RegExp("[?&]bzp=([^&]*)").exec(window.location.search);
          }
          if (zipCodeFromURL && typeof zipCodeFromURL[1] !== 'undefined' && zipCodeFromURL[1].match(/^[0-9]+$/) != null) {
            if (zipCodeFromURL[1].length < 5) {
              var numberOfZeros = '';
              for (var i = zipCodeFromURL[1].length; i < 5; i++) {
                numberOfZeros += '0';
              }
              zipCodeFromURL[1] = numberOfZeros + zipCodeFromURL[1];
            }
            await this.checkIfThereIsAtLeastOneStoreForSelectedZipcode(zipCodeFromURL[1]);
          }
        },
        getMyLocation() {
            this.isGettingLocation = true;

            const vm = this;
            const geoSuccess = function (position) {
                const latLngObj = { lat: position.coords.latitude, lng: position.coords.longitude };

                Vue.$geocoder.send(latLngObj, (response) => {
                    clearTimeout(vm.gettingLocationTimeout);

                    vm.currentPlace = latLngObj;
                    vm.searchedPlace = { latitude: vm.currentPlace.lat, longitude: vm.currentPlace.lng };

                    const streetAddress = response.results.find((a) => a.types.indexOf("street_address") > -1);
                    vm.stringAddressValue = streetAddress ? streetAddress.formatted_address : response.results[0].formatted_address;
                    vm.isGettingLocation = false;

                    GTMService.send('Geolocation Button', vm.stringAddressValue, null, false);

                    vm.onSubmitSearch();
                });
            };
            const geoError = function (error) {
                // permission denied
                if (error.code === 1) {
                    clearTimeout(vm.gettingLocationTimeout);

                    window.dispatchEvent(new Event("locationPermissionDenied"));
                }
                console.log("[GMapSearch] Error code: ", error);
                vm.isGettingLocation = false;
            };

            // check if the geoLocation should be executed by parent or frame
            const match = RegExp("[?&]geosearch=([^&]*)").exec(window.location.search);

            if (match && match[1] === "false") {
                // the parent made the geoLocation
                const eventer = window[window.addEventListener ? "addEventListener" : "attachEvent"];

                eventer(
                    window.addEventListener === "attachEvent"
                        ? "onmessage"
                        : "message",
                    (e) => {
                        if (
                            e.data &&
                            e.data.message === "geoSearchResponse" &&
                            e.data.coords
                        ) {
                            geoSuccess(e.data);
                        } else {
                            console.log("[GMapSearch] Error code: ", e.data);
                        }
                    }
                );

                window.parent.postMessage({ message: "geoSearchRequest", }, "*");
            } else {
                // the iframe made the geoLocation
                navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
            }

            this.gettingLocationTimeout = setTimeout(() => {
                window.dispatchEvent(new Event("apiError"));
            }, 8000);
        },
    },
    components: {
        StoreNotFoundPage,
    },
};
</script>

<style lang="scss" scoped>
.all-services {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .fake-button {
        padding: 5px 10px;
        border: 1px solid #272a33;
        margin: 5px 5px 5px 0px;
        border-radius: 50px;
    }
}
.gmap {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        margin: 0 auto;
    }

    &__input-container {
        display: flex;
        align-items: center;
        flex: 1;
        height: 48px;
        border: 1px solid var(--tints-slate-slate-30);
        border-radius: 4px !important;
        padding: 0 0 0 16px;

        &:focus {
            border: 1px solid red;
        }
    }

    &__search-icon {
        position: relative;
        left: 0px;
        width: 18px;
    }

    &__input {
        margin: 0 0 0 12px;
        padding: 0;
        border: none;
        font-size: 16px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-property: font-size, padding-top, color;

        &:focus {
            transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
            transition-property: font-size, padding-top, color;
            outline: none;
        }
    }

    &__locate {
        border-left: 1px solid var(--tints-slate-slate-15);
        button {
            margin: 0;
        }
    }

    &__locate-icon {
        position: relative;
        width: 18px;
    }

    &__clear-icon {
        width: 18px;
        margin: 0 0 0 6px;
    }

    &__spinner {
        padding: 8px 11px;
        top: 2px !important;
        left: 0px;
    }
}

.gmap-map {
    display: flex;
    height: 400px;
    margin-top: 40px;

    &__map {
        width: 100%;
        height: 100%;
    }

    &__info {
        width: 40%;
        padding: 16px 32px;
        background: var(--primary-turquoise);

        &__title {
            font-family: iqos-bold;
            font-size: 20px;
        }

        &__address {
            font-size: 14px;
        }

        .button {
            margin-top: 24px;
            &:after {
                background: var(--primary-white) !important;
            }

            &:hover {
                background: var(--primary-white);
                color: var(--primary-slate) !important;
                &:after {
                    background: var(--primary-white);
                }
            }
        }
    }
}

.md-focused {
    border: 1.5px solid var(--primary-slate) !important;
}

::v-deep .gmap__spinner .md-progress-spinner-draw {
    width: 18px !important;
    height: 18px !important;
}

::v-deep .md-icon {
    color: var(--md-theme-default-icon-on-background, rgba(0, 0, 0, 0.54));
}

.btn-search-icon {
    margin-top: -8px;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.1s;
}

.map-throbber-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .md-progress-spinner-draw {
        width: 18px !important;
        height: 18px !important;
    }
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    .gmap {
        &__container {
            width: 80%;
        }

        &__input-container {
            height: 54px;
        }
    }

    .gmap-map {
        display: flex;
        flex-direction: column;
        height: 600px;

        &__info {
            width: 100%;
            padding: 16px 32px 32px 32px;
            background: var(--primary-turquoise);

            &__title {
                font-family: iqos-bold;
                font-size: 20px;
            }

            &__address {
                font-size: 14px;
            }

            .button {
                margin-top: 24px;
            }
        }
    }
}
</style>
