export const GOTO_STEP = "gotoStep";
export const INITIALIZE_STEPS_STATUS = "initializeStepsStatus";
export const NEXT_STEP = "nextStep";
export const SET_CUSTOMER = "setCustomer";
export const SET_PRODUCT_UUID = "setProductUUID";
export const SET_REGIONS_LIST = "setRegionsList";
export const SET_SELECTED_APPOINTMENT_TIME = "setSelectedAppointment";
export const SET_SELECTED_REGION = "setSelectedRegion";
export const SET_SELECTED_SERVICE = "setSelectedService";
export const SET_SELECTED_STORE = "setSelectedStore";
export const SET_STEPS_STATUS = "setStepsStatus";
export const SET_STORE_PRESELECTED = "setStorePreselected";
export const SET_STORES_NEAR = "setStoresNear";
export const SET_STORES_REGION = "setStoresRegions";
export const SET_TIME_SPENT = "setTimeSpentOnBookingPlatform";
export const SET_SERVICE_QUERY_STRING = "setServiceQueryString";
export const SET_SELECTED_STORE_VIDEOCALL = "setSelectedStoreVideoCall";