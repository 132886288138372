<template>
    <div class="navigation">
        <div class="back">
            <md-button v-if="!isMobile"
                       :class="{ 'md-plain': true, 'md-button-disabled': disableBack }"
                       @click="goBack()">
                <img src="/assets/chevron-left.svg" />
                <span>{{ $t('Torna indietro') }}</span>
            </md-button> 
            <md-button v-if="isMobile"
                       :md-ripple="false"
                       :class="{ 'md-button-disabled': disableBack }"
                       @click="goBack()">
                <img src="/assets/chevron-left-simple.svg" />
            </md-button>
        </div>
        <Stepper class="stepper"
                 :steps="getStepperSteps()"
                 :stepsStatus="stepsStatus"
                 :activeAllSteps="disableBack"
                 :active="activeStep.split('.')[0]">
        </Stepper>
        <div class="right">
        </div>
    </div>
</template>

<script>
import Stepper from "./Stepper";
import { GTMService } from "../services/gtm";

export default {
    name: "Nav",
    props: {
        activeStep: String,
        configs: Object,
        disableBack: Boolean,
        stepsStatus: Object,
    },
    data: () => ({
        isMobile: false,
    }),
    created() {
        this.checkIsMobile();
        window.addEventListener("resize", (() => {
            this.checkIsMobile();
        }));
    },
    methods: {
        checkIsMobile() {
            this.isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
        },
        goBack() {
            this.$emit("navigationBack", event);
            GTMService.send('Click Back Button', this.getStepperSteps()[this.activeStep.split('.')[0]].label, null, false);
        },
        getStepperSteps() {
            return this.configs.steps.filter((x) => !x.number.includes("."));
        },
    },
    components: {
        Stepper,
    },
};
</script>

<style lang="scss" scoped>
.navigation {
    position: fixed;
    width: 100%;
    height: 130px;
    top: 0;
    display: flex;
    align-items: center;
    background: var(--background-hard-white);
    z-index: 9998;

    .back {
        flex: 0.3;
        margin-left: 40px;

        button {
            text-transform: unset !important;
            width: 95px;
            height: 38px;
            margin: 0;

            span {
                margin: -2px 0 0 4px;
            }

            .icon {
                border: solid var(--primary-slate);
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }

            ::v-deep {
                .md-button-content {
                    display: flex;
                    align-items: center;
                }
            }

            &.md-button-disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    .right {
        display: flex;
        flex: 0.3;
        padding: 0 15px;
    }

    .stepper {
        flex: 1;
        display: flex;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navigation {
        height: 80px;
    }

    .back {
        width: 0;
        flex: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        left: -16px;
    }

    .right {
        display: none !important;
    }
}
</style>
