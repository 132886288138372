<template>
    <div>
        <Nav :configs="configs.navigation"
             :stepsStatus="getStepsStatus()"
             :activeStep="getCurrentStep"
             :disableBack="true"></Nav>

        <div class="first-section">
            <p class="title">{{ getFullName() | capitalize }}, {{ $t('la tua prenotazione è confermata') }}!</p>

            <div class="background confirm">
                <img src="/assets/calendar.svg" />

                <Recap class="recap"
                       :twoLines="true"
                       :readOnly="true"
                       :store="getSelectedStore ? getSelectedStore : store"
                       :appointment="getSelectedAppointmentTime ? getSelectedAppointmentTime : appointment" />

                <p class="text">
                    {{ text }}
                </p>
            </div>

            <div class="blog">
                <p class="title">{{ text2 }}</p>
                <md-button @click="navigateToBlog()" class="button md-primary">
                    {{ $t('Vai al blog') }}
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import Recap from "../components/Recap";
import Nav from "../components/Nav";
import { mapGetters } from "vuex";
import { ApiService } from "../services/api";
import configs from "../configs";

export default {
    name: "ThankYouPage",
    props: {
        configs: Object
    },
    created() {
        if (this.$route.params.id) {
            this.getAppointmentDatas(this.$route.params.id);
        }
        this.productName = configs.product;
    },
    computed: {
        ...mapGetters([
            "getCurrentStep",
            "getCustomer",
            "getSelectedAppointmentTime",
            "getSelectedService",
            "getSelectedStore",
        ])
    },
    data: () => ({
        appointment: null,
        service: null,
        store: null,
        customer: null,
        text: null,
        text2: null
    }),
    methods: {
        getStepsStatus() {
            return { 0: true, 1: true, 2: true, 3: true };
        },
        getFullName() {
            return this.getCustomer && this.getCustomer.name
                ? `${this.getCustomer.name} ${this.getCustomer.surname}`
                : this.customer
                    ? `${this.customer.name} ${this.customer.surname}`
                    : '';
        },
        navigateToBlog() {
            window.parent.postMessage({ message: "navigateToBlog" }, "*");
        },
        async getAppointmentDatas(id) {
            const appointmentResponse = (await ApiService.get("/api/appointments/" + id, null, null)).data;

            this.appointment = {
                startTime: new Date(appointmentResponse.startTime),
                endTime: new Date(appointmentResponse.endTime)
            };

            this.customer = {
                name: appointmentResponse.clients[0].name,
                surname: appointmentResponse.clients[0].surname
            };

            this.service = appointmentResponse.storeService;
            this.store = appointmentResponse.storeService.store;

            this.updateTexts();
        },
        updateTexts() {
            this.text = this.service && this.service.externalName === 'Videocall'
                ? this.$t('Riceverai a breve la mail con la conferma. Ti suggeriamo di controllare anche la cartella della posta indesiderata qualora non dovessi trovarla.')
                : this.$t('A breve riceverai una mail di conferma. Un\'ora prima del tuo appuntamento, ti invieremo un SMS per ricordarti ora e luogo della tua prenotazione.');

            this.text2 = this.service && this.service.externalName === 'Videocall'
                ? `${this.$t('Conosci già il Blog IQOS?')} \n${this.$t('News e aggiornamenti su tutti i prodotti e molto altro ancora.')}`
                : this.$t('In attesa del tuo appuntamento scopri le ultime novità sul nostro blog.');
        }
    },
    components: {
        Nav,
        Recap
    }
};
</script>

<style lang="scss" scoped>
.host {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 40px;
}

.background {
    width: 100%;

    .recap {
        width: 30%;
        margin: 0 auto 20px auto;
        text-align: left;
    }

    .button {
        margin-top: 20px;
    }

    &.confirm {
        margin-top: 100px;
        align-items: center;
        text-align: center;

        > img {
            width: 93px;
            margin: -76px 0 30px 0;
        }
    }

    &.center-flex {
        justify-content: center;
    }

    .text {
        width: 30%;
        font-size: 14px;
        text-align: justify;
        margin: 0 auto;
    }
}

.blog {
    margin: 60px auto;
    width: 60%;

    button {
        display: flex;
        margin: 60px auto;
    }
}

@media (max-width: 768px) {
    .background {
        display: flex;
        flex-direction: column;

        .recap,
        .text {
            width: 100%;
        }

        &.confirm {
            > img {
                width: 93px;
                margin: -73px 0 16px 0;
            }
        }
    }

    .banner {
        display: none !important;
    }

    .title {
        margin-top: 40px !important;
    }

    ::v-deep .text-recap-margin .text {
        margin-top: -13px !important;
    }

    .text {
        font-size: 14px;
        color: var(text) !important;
        text-align: center;
    }

    img.calendar {
        background: none !important;
    }

    .blog {
        width: 80% !important;
    }
}
</style>
