<template>
    <div class="wrap-times">
        <div class="timepicker-throbber-container" v-show="isLoading">
            <md-progress-spinner class="timepicker-throbber" md-mode="indeterminate" :md-diameter="30" :md-stroke="3"></md-progress-spinner>
        </div>
        <div class="current-times" v-if="type === 'full' && slots && slots.length && !isLoading">
            <transition-group name="fade" mode="out-in" tag="div">
                <div class="current-time"
                     v-for="slot in slots"
                     :key="slot.slot">
                    <p class="time"
                       :class="{ 'selected': slot.isSelected }"
                       @click="select($event, slot)">
                        {{ slot.slot }}
                    </p>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { GTMService } from "../services/gtm";

export default {
    name: "Timepicker",
    props: {
        slots: Array,
        type: String,
        isLoading: Boolean,
    },
    data: () => ({
        timepickerOpened: false,
        selectedSlot: null,
        selected: false,
    }),
    methods: {
        select(event, time) {
            for (const node of document.querySelectorAll("p.time")) {
                node.classList.remove("selected");
            }
            event.target.classList.add("selected");
            this.$emit("selected", time);
            GTMService.send('Click Time', time.slot, null, false);
        },
        choose(event, slot) {
            this.selectedSlot = slot;
            this.timepickerOpened = false;
            this.selected = true;
        },
        toggle(event) {
            if (
                (event.target.parentNode.parentNode.id === "list-root") ||
                (event.target.parentNode.parentNode.parentNode.id === "list-root") ||
                (event.target.parentNode.parentNode.parentNode.parentNode.id === "list-root") ||
                (event.target.parentNode.parentNode.parentNode.parentNode.parentNode.id === "list-root")
            ) {
                this.timepickerOpened = !this.timepickerOpened;
                if (this.selectedSlot) {
                    this.selected = false;
                }
            }
        },
        isTimepickerOpened() {
            return this.timepickerOpened;
        },
    },
};
</script>

<style lang="scss" scoped>
.current-times {
    &-wrap {
        display: flex;
        flex-wrap: wrap;
    }
    .current-time {
        display: inline-block;
        width: 16.6%;
        text-align: center;
        margin-bottom: 15px;

        .time {
            margin-bottom: 0;
            padding: 10px;
            width: calc(100% - 10px);
            font-weight: 500;
            margin: auto;
            transition: all 0.5s;

            &:hover {
                background: var(--primary-turquoise);
                color: var(--primary-white);
                cursor: pointer;
            }
            &.selected {
                color: white;
                background: var(--primary-turquoise) !important;
            }
        }
    }
}

::v-deep {
    .md-progress-spinner-circle {
        stroke: var(--primary-slate) !important;
    }
}

.timepicker-throbber-container {
    display: flex;
    justify-content: center;
}

@media (max-width: 1280px) {
    .current-time {
        width: 25% !important;
    }
}

@media (max-width: 768px) {
    .current-time {
        margin: 6px 0 !important;
    }
}
</style>
