<template>
    <div>
        <div class="background center padding-top">
            <div class="">
                <p class="title">{{ $t('Mi dispiace!') }}</p>
                <p class="text">
                    <span style="font-size: 12px;">
                        {{ $t('Non ci sono negozi {product} disponibili nel raggio di 50km dal CAP da te indicato', { product: productName }) }}, <br>
                        <b>{{ $t('per fortuna puoi provare {product} direttamente a casa tua', { product: productName }) }}!</b>
                    </span>
                </p>

                <div class="icon-container">
                    <img class="home" src='/assets/home_big.png'>
                </div>
                <md-button @click="tryAthome()" class="select-button md-primary">
                    {{ $t('Prova a casa tua') }}
                    <svg width="32" height="12" viewBox="0 0 32 12">
                        <path d="M30.243,7.414 L30.242,7.414 L26.000,11.656 L24.586,10.242 L27.828,7.000 L-0.000,7.000 L-0.000,4.999 L27.828,4.999 L24.586,1.757 L26.000,0.343 L31.657,6.000 L30.243,7.414 Z"></path>
                    </svg>
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import configs from "../configs";

export default {
    name: "StoreNotFoundPage",
    props: {
        configs: Object,
    },
    created() {
        this.productName = configs.product;
    },
    data: () => ({
        productName: null,
    }),
    methods: {
        tryAthome() {
            window.parent.postMessage(
                {
                    message: "changeForm",
                },
                "*"
            );
        },
    },
};
</script>

<style lang="scss" scoped>
/* old */
/* @media(orientation: portrait){ */
@media (max-width: 768px) {
    .background {
        margin-top: 0px !important;
        padding: 0px !important;
        margin: 2%;
        display: flex;
        flex-direction: column;
        background: transparent !important;
    }

    .title {
        margin-top: 40px !important;
    }

    ::v-deep .text-recap-margin .text {
        margin-top: -13px !important;
    }

    .text {
        padding-top: 6%;
        padding-bottom: 6%;
        padding-left: 6% !important;
        font-size: 14px;
        color: var(text) !important;
        padding-right: 6% !important;
        text-align: center;
    }

    .full-width {
        width: 100%;
        background-color: var(secondaryDark) !important;
        height: 1px;
        opacity: 0.4;
        border: none;
    }

    .select-button {
        width: 100% !important;
        left: 0% !important;
        margin: 0px 0px;
    }
}

.select-button {
    background-color: var(text) !important;
    color: var(white) !important;
    width: 50%;
    text-align: left;
    left: 25%;

    &:hover {
        svg {
            fill: white;
        }
    }

    svg {
        position: relative;
        top: 1px;
        margin-left: 20px;
        fill: var(--primary-slate);
    }
}

.home {
    color: var(--primary-slate) !important;
    font-size: 60px !important;
}
.arrow-forward {
    position: relative;
    left: 9px;
    color: var(--primary-slate) !important;
}
.text {
    text-align: center;
}
.icon-container {
    text-align: center;
    padding-bottom: 45px;
    padding-top: 35px;
}
</style>
