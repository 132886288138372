<template>
  <transition name="fade">
    <div>
      <!--- trade in info --->
      <div v-for="(service, key) in getSelectedStore.storeServices || []" :key="key">
        <!--- popup trade in --->
        <div class="custom-popup custom-big-popup" v-if="service.internalName === 'Trade In'  && valuePopupTradeIn == true">
        <div class="custom-popup__tradein">
          <span class="material-icons custom-popup__close" @click="hidePopupTradeIn()">close</span>

          <div class="content-text-popup">
            <p class="font-18 mt-0 md-font-16">
              <strong> Non sei ancora passato ad IQOS ILUMA e Possiedi dispositivi IQOS con lamina o Lil SOLID 2.0 che non usi più?</strong>
            </p>
            <p class="font-14 mt-20 md-font-12">
              Lasciali nei negozi IQOS aderenti all’iniziativa Take Back e, se il punto vendita aderisce anche all’iniziativa commerciale Trade-In, potrai acquistare un dispositivo della linea IQOS ILUMA a un prezzo speciale*:
            </p>
            <p class="font-14 mt-0 md-font-12">
              - Se lasci 1 dispositivo, potrai acquistare IQOS ILUMA ONE al prezzo suggerito di 19€*, IQOS ILUMA al prezzo suggerito di 29€*<!-- e IQOS ILUMA PRIME al prezzo suggerito di 59€*-->;
            </p>
            <p class="font-14 mt-20 mb-20 md-font-12 md-mt-10 md-mb-10">
               - Se lasci 2 dispositivi, potrai acquistare IQOS ILUMA ONE al prezzo suggerito di 9€*; 
            </p>
            <!-- <p class="font-14 mt-20 mb-20 md-font-12 md-mt-10 md-mb-10">
               - Se lasci 3 o più dispositivi, potrai acquistare IQOS ILUMA al prezzo suggerito di di 1€*.
            </p> -->
            <md-button class="button md-primary mt-20" @click="chooseService(service, true)" >{{ $t('Prenota un appuntamento') }}</md-button>
            <p class="font-14 mt-20 md-font-14 md-mt-10">
              Prima di prenotare l’appuntamento verifica con il negozio IQOS se ha aderito al Trade In.
            </p>
            <p class="font-14 mb-0 md-font-12">
               <!-- *Valido per utilizzatori IQOS con lamina e Lil SOLID 2.0 registrati su iqos.com prima del 9 giugno 2024, che non hanno un dispositivo della linea IQOS ILUMA associato. Utilizzabile una sola volta per consumatore e per un solo dispositivo della linea IQOS ILUMA e non cumulabile. Dovranno essere portate entrambe le componenti. Chiedi al punto vendita i dettagli dell’iniziativa. Il rivenditore è sempre libero di scegliere il prezzo finale da applicare.  -->
               *Valido per possessori IQOS con lamina, Lil SOLID 2.0 registrati su iqos.com prima del 9 giugno 2024 che non hanno un dispositivo della linea IQOS ILUMA associato. Utilizzabile una sola volta per consumatore per un dispositivo IQOS ILUMA ONE e IQOS ILUMA e non cumulabile. Dovranno essere portate entrambe le componenti. Prezzo suggerito - verifica con il punto vendita se aderisce all’iniziativa, i dettagli, la durata e il prezzo finale applicato.
            </p>
          </div>
        </div>
      </div>
      <!-- END popup trade in --->

        <div class="background-00D1D2 text-center mb-50 wrap-custom-full" v-if="service.internalName === 'Trade In'" >
          <p class="font-24 mt-0 md-font-18">
            <strong>
              Non sei ancora passato ad IQOS ILUMA e Possiedi dispositivi IQOS con lamina o Lil SOLID 2.0 che non usi più?
            </strong>
          </p>
          <p class="font-16 mt-0 mb-20">
            Lasciali nei negozi IQOS aderenti all’iniziativa Take Back e, se il punto vendita aderisce anche all’iniziativa commerciale Trade-In, potrai acquistare un dispositivo della linea IQOS ILUMA a un prezzo speciale*:
          </p>
          <p class="font-16 mt-0 mb-5">
            -  Se lasci 1 dispositivo, potrai acquistare IQOS ILUMA ONE al prezzo suggerito di 19€*, IQOS ILUMA al prezzo suggerito di 29€*<!-- e IQOS ILUMA PRIME al prezzo suggerito di 59€*-->; 
          </p>
          <p class="font-16 mt-0 mb-30 md-mb-20">
            - Se lasci 2 dispositivi, potrai acquistare IQOS ILUMA ONE al prezzo suggerito di 9€*;
          </p>
          <!-- <p class="font-16 mt-0 mb-30 md-mb-20">
            - Se lasci 3 o più dispositivi, potrai acquistare IQOS ILUMA al prezzo suggerito di di 1€*.
          </p> -->
          <md-button
            class="button md-primary button-white"
            @click="chooseService(service, true)"
            >{{ $t('Prenota un appuntamento') }}</md-button>
          <p class="mt-30 md-mt-20">
            Prima di prenotare l’appuntamento verifica con il negozio IQOS se ha aderito al Trade In.
          </p>
          <p>
            <!-- *Valido per utilizzatori IQOS con lamina e Lil SOLID 2.0 registrati su iqos.com prima del 9 giugno 2024, che non hanno un dispositivo della linea IQOS ILUMA associato. Utilizzabile una sola volta per consumatore e per un solo dispositivo della linea IQOS ILUMA e non cumulabile. Dovranno essere portate entrambe le componenti. Chiedi al punto vendita i dettagli dell’iniziativa. Il rivenditore è sempre libero di scegliere il prezzo finale da applicare. -->
            *Valido per possessori IQOS con lamina, Lil SOLID 2.0 registrati su iqos.com prima del 9 giugno 2024 che non hanno un dispositivo della linea IQOS ILUMA associato. Utilizzabile una sola volta per consumatore per un dispositivo IQOS ILUMA ONE e IQOS ILUMA e non cumulabile. Dovranno essere portate entrambe le componenti. Prezzo suggerito - verifica con il punto vendita se aderisce all’iniziativa, i dettagli, la durata e il prezzo finale applicato.
          </p>
        </div>
      </div>
      <!--- END trade in info --->

      <div class="custom-popup" v-if="showService && showPopup">
        <div class="custom-popup__videocall">
          <span class="material-icons custom-popup__close" @click="hidePopup()">close</span>

          <img src="/assets/popup-icon.svg" alt="Videocall" />
          <div class="content-text-popup">
            <p class="title">3.</p>
            <p>{{ $t("Vieni in negozio per ritirare il prodotto ed iniziare la prova gratuita di 14 giorni.") }}</p>

            <div class="custom-popup__actions">
              <md-button class="button md-primary" @click="clickCta()">{{ $t("Scopri di più") }}</md-button>
              <md-button
                class="button md-primary"
                @click="hidePopup()"
              >{{ $t("Continua prenotazione") }}</md-button>
            </div>
            <!-- <p
              style="font-size: 12px; text-align: center;"
            >{{ $t('Il servizio di spedizione è attivo dal lunedì al venerdì.') }}</p>-->
          </div>
        </div>
      </div>

      <!---- popup clickAndLending ---->
      <div class="custom-popup" v-if="showPopupClickAndLending">
        <div class="custom-popup__click">
          <span class="material-icons custom-popup__close" @click="hidePopup()">close</span>

          <img src="/assets/ClickLending.webp" alt="ClickAndLending" class="img-popup">
          <div class="content-text-popup">
            <p style="font-size: 16px">{{ $t("Vieni in negozio per ritirare il prodotto e iniziare la prova gratuita di 14 giorni") }}</p>
          </div>
        </div>
      </div>
      <!--- END popup clickAndLending--->
      <!--- popup services --->
      <div class="custom-popup" v-if="valuePopupServices">
        <div class="custom-popup__click">
          <span class="material-icons custom-popup__close" @click="hidePopupServices()">close</span>

          <!-- <img class="img-popup" src="/assets/BC_icon_BGbianco.svg" alt="IQOS" /> -->
          <div class="content-text-popup">
            <div v-if="this.currentServicePopup == 'Incontra un Expert'">
              <img src="/assets/iqosexpert.webp" alt="You&IQOS" class="img-popup">
              <p style="font-size: 16px">
                {{ $t("Tutto quello di cui hai bisogno per ottenere il massimo dal tuo IQOS.") }}
              </p> 
            </div>
            <div v-if="this.currentServicePopup == 'Trade In'">
              <img src="/assets/TradeIn.webp" alt="Trade In" class="img-popup">
              <p style="font-size: 16px">
                Lasciando i dispositivi IQOS a lamina o Lil Solid 2.0 che non usi più in negozio e, se non lo hai già, potrai acquistare un dispositivo della linea IQOS ILUMA a un prezzo speciale*.
                <br>
                <br>
                Prima di prenotare l’appuntamento verifica con il negozio IQOS se ha aderito al Trade In.
                <!-- {{ $t("Lascia i dispositivi a lamina o a punta che non usi più in negozio e, se non lo hai già, potrai acquistare IQOS ILUMA a un prezzo speciale. Un’occasione per te e per l’ambiente.") }}</p> -->
              </p>
              <p style="margin: 20px 0 0 0; font-size: 12px;">
                *Valido per utilizzatori IQOS con lamina e Lil SOLID 2.0 registrati su iqos.com prima del 9 giugno 2024, che non hanno un dispositivo della linea IQOS ILUMA associato. Utilizzabile una sola volta per consumatore e per un solo dispositivo della linea IQOS ILUMA e non cumulabile. Dovranno essere portate entrambe le componenti. Chiedi al punto vendita i dettagli dell’iniziativa. Il rivenditore è sempre libero di scegliere il prezzo finale da applicare.
              </p>
            </div>
            <div v-if="this.currentServicePopup == 'Take Back'">
              <img src="/assets/Takeback.webp" alt="Take Back" class="img-popup">
              <p style="font-size: 16px">
                Lascia il tuo dispositivo IQOS o LIL che non usi più in negozio. Il nostro obiettivo è di recuperarne in media oltre l’80% delle materie prime, trasformando un rifiuto in una risorsa.
                <!-- {{ $t("Fai di un rifiuto una risorsa! Lascia il tuo dispositivo che non usi più in negozio. Il nostro obiettivo è recuperare in media oltre 80% delle materie prime.") }} -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--- END popup services --->

      <p
        class="title"
        v-if="showService" 
      >{{ (getSelectedStore.storeServices || []).find(x => x.internalName === 'Lunae Lumen') ? $t("Scegli cosa prenotare"): $t("Quale servizio vuoi prenotare?") }}</p>
      <div class="md-layout md-gutter services-container">
        <div class="services-card" v-if="showService">
          <!-- controllo click and lending -->
          <md-card class="md-layout-item service-card p-relative" v-if="showClickAndLending">
              <div class="open-info" @click="showPopupClickAndlending">
                <span>i</span>
              </div>
            <div class="clickable" @click="showCardClickAndlending">
              <md-card-media>
                <img :src="this.imageClickAndLending" />
              </md-card-media>
              <md-card-header>
                <div class="md-title">{{this.nameClickAndLending}}</div>
              </md-card-header>
            </div>
          </md-card>
          <!-- END controllo click and lending -->

          <md-card
            :class="{ 'md-layout-item service-card': true, 'lunae-lumen': service.internalName === 'Lunae Lumen', 'selected' : getSelectedService? service.internalName===getSelectedService.internalName: false }"
            v-for="(service, key) in getSelectedStore.storeServices || []"
            :key="key"
          >
            <div class="open-info" @click="showPopupServices(service.internalName)" v-if="service.internalName === 'Incontra un Expert' || service.internalName === 'Trade In' || service.internalName === 'Take Back'">
              <span>i</span>
            </div>
            <div class="clickable" @click="chooseService(service, false)">
              <md-card-media>
                <img :src="getServiceImage(service)" />
              </md-card-media>
              <md-card-header>
                <div class="md-title">{{ $t(service.externalName) }}</div>
              </md-card-header>
              <md-card-content v-if="service.internalName === 'Lunae Lumen'">Installazione di Felice Limosani in esclusiva per l'IQOS Boutique Firenze</md-card-content>
              <div v-if="service.internalName === 'Incontra un Expert'" class="custom-bottom-text">
                YOU&IQOS
              </div>
            </div>
          </md-card>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  SET_SELECTED_SERVICE,
  SET_SELECTED_STORE_VIDEOCALL,
  SET_SERVICE_QUERY_STRING
} from "../store/action.type";
import { GTMService } from "../services/gtm";
import { mapGetters } from "vuex";
import utils from "../helpers/utils";
import { ApiService } from "../services/api";

export default {
  name: "StorePage",
  props: {
    configs: Object
  },
  async created() {
    // controllo se lo store ha ClickAndLendingReservation
    var currentStoreSlug = this.$store.getters.getSelectedStore.slug;

    const getStoreClickAndLending = (
                await ApiService.get(
                    "/api/stores",
                    {
                        slug: currentStoreSlug,
                        serviceContext: "ClickAndLendingReservation",
                        page: 1,
                        itemsPerPage: 30
                    },
                    [
                        "name",
                        "city",
                        "storeServices",
                        "image"
                    ]
                )
            ).data["hydra:member"];
      
      if(getStoreClickAndLending.length > 0) {
        // servizio esiste 
        //console.log("getStoreClickAndLending -> ", getStoreClickAndLending);
        this.showClickAndLending = true;
        this.imageClickAndLending = getStoreClickAndLending[0]["storeServices"][0]["service"]["image"];
        this.nameClickAndLending = getStoreClickAndLending[0]["storeServices"][0]["externalName"];
      } 

      // END controllo se lo store ha ClickAndLendingReservation

    const queryString = RegExp("[?&]bsv=([^&]*)").exec(window.location.search);

    if (
      queryString !== null &&
      this.$store.getters.getServiceQueryString === false
    ) {
      this.$store.dispatch(SET_SERVICE_QUERY_STRING, true);
      const services = this.$store.getters.getSelectedStore.storeServices;

      // controllo se c'è un record con lo stesso valore passato in get
      const internalNameByQueryString = (() => {
        switch (decodeURI(queryString[1]).toLowerCase()) {
          case "care":
            return "assistenza";
          case "buy":
            return "prova";
          case "videocall":
            return "videocall";
          default:
            return decodeURI(queryString[1]).toLowerCase();
        }
      })();

      const requestedService = services.find(
        x => x.internalName.toLowerCase() === internalNameByQueryString
      );

      if (requestedService) {
        this.$store.dispatch(SET_SELECTED_SERVICE, requestedService);
        setTimeout(() => {
          window.parent.postMessage(
            { message: "scrollTop", animated: false, coordinate: 0 },
            "*"
          );
          this.$emit("navigation", 1);
        }, 250);
      } else {
        this.showService = true;
      }
    } else {
      this.showService = true;
    }

    // show popup
    const slug = this.$store.getters.getSelectedStore.slug;
    if (slug === "boutique-palermo" || slug === "boutique-verona") {
      const currentStoreServices = this.$store.getters.getSelectedStore
        .storeServices;

      currentStoreServices.forEach(value => {
        if (value.externalName.toLowerCase() === "videocall") {
          this.valuePostMessage = slug;

          if (this.$store.getters.getSelectedStoreVideoCall === null) {
            this.$store.dispatch(SET_SELECTED_STORE_VIDEOCALL, slug);
            this.showPopup = true;
            // add postMessage impession
            window.parent.postMessage(
              { message: "addImpression", store: slug },
              "*"
            );
          } else if (
            this.$store.getters.getSelectedStoreVideoCall !== null &&
            this.$store.getters.getSelectedStoreVideoCall !== slug
          ) {
            this.showPopup = true;
            // add postMessage impession
            window.parent.postMessage(
              { message: "addImpression", store: slug },
              "*"
            );
          }
        }
      });
    }
  },
  data: () => ({
    searchIsFinished: true,
    showService: false,
    showPopup: false,
    valuePostMessage: null,
    showClickAndLending: false,
    nameClickAndLending: null,
    imageClickAndLending: null,
    showPopupClickAndLending: false,
    valuePopupServices: false,
    currentServicePopup: null,
    valuePopupTradeIn: false
  }),
  computed: {
    ...mapGetters([
      "getSelectedStore",
      "getSelectedService",
      "getShowPopupVideoCall"
    ])
  },
  methods: {
    ...utils,
    getServiceImage(service) {
      return (
        service.service.image ||
        (() => {
          switch (service.internalName) {
            case "Assistenza":
              return "/assets/service-assistenza.svg";
            case "Prova":
              return "/assets/service-buy.svg";
            case "Videocall":
              return "/assets/service-videocall.svg";
            case "Lunae Lumen":
              return "/assets/lumen.svg";
            default:
              return "";
          }
        })()
      );
    },
    chooseService(service, diffentButton) {
      this.$store.dispatch(SET_SELECTED_SERVICE, service);
      if(diffentButton == false){
        GTMService.send("Click Store Service", service.externalName, null, false);
        // console.log("Click service FALSE -> ",service.externalName );
      } else if(diffentButton == true) {
        GTMService.send("Click Button Service", service.externalName, null, false);
        // console.log("Click service TRUE -> ",service.externalName );
      }
      
      setTimeout(() => {
        window.parent.postMessage(
          { message: "scrollTop", animated: false, coordinate: 0 },
          "*"
        );
        this.$emit("navigation", 1);
      }, 250);
    },
    hidePopup() {
      this.showPopup = false;
      // popup click and lending 
      this.showPopupClickAndLending = false;
    },
    clickCta() {
      if (this.valuePostMessage == null) {
        this.showPopup = false;
      } else if (this.valuePostMessage === "boutique-palermo") {
        window.parent.postMessage({ message: "ClickPopupPalermo" }, "*");
      } else if (this.valuePostMessage === "boutique-verona") {
        window.parent.postMessage({ message: "ClickPopupVerona" }, "*");
      }
    },
    showPopupClickAndlending() {
      //console.log("click show popup");
      this.showPopupClickAndLending = true;
      window.parent.postMessage({ message: "OpenPopupInfo" }, "*");
    },
    hidePopupServices() {
      this.valuePopupServices = false;
    },
    hidePopupTradeIn() {
      this.valuePopupTradeIn = false;
    },
    showPopupServices(currentService) {
      if(currentService != "") {
        this.valuePopupServices = true;
        this.currentServicePopup = currentService
      }
    },
    showCardClickAndlending() {
      //console.log("click Card popup -> ", this.nameClickAndLending);
      
      this.$store.dispatch(SET_SELECTED_SERVICE, "");
      
      GTMService.send("Click Store Service", this.nameClickAndLending, null, false);
      setTimeout(() => {
        window.parent.postMessage(
          { message: "scrollTop", animated: false, coordinate: 0 },
          "*"
        );

      this.$router.push("device-request/");
      }, 250);
    },
  }
};
</script>

<style lang="scss" scoped>
/*** trade in info ***/
.wrap-custom-full {
  margin-top: -100px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 50px;

  @media (max-width: 768px) {
    margin-top: -50px;
    margin-left: 0;
    margin-right: 0;
    padding: 50px 20px;
  }
}
.text-center {
  text-align: center;
}
.font-24 {
  font-size: 24px;
  line-height: 32px;
}
.font-18 {
  font-size: 18px;
  line-height: 24px;
}
.font-14 {
  font-size: 14px;
  line-height: 18px;
}
.font-16 {
  font-size: 16px;
  line-height: 22px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-0 {
  margin-top: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}
@media(max-width: 768px){
  .md-font-18 {
    font-size: 18px;
    line-height: 22px;
  }
  .md-font-16 {
    font-size: 16px;
    line-height: 20px;
  }
  .md-font-14 {
    font-size: 14px;
    line-height: 18px;
  }
  .md-font-12 {
    font-size: 12px;
    line-height: 16px;
  }
  .md-mt-10 {
    margin-top: 10px;
  }
  .md-mb-10 {
    margin-bottom: 10px;
  }
  .md-mb-20 {
    margin-bottom: 20px;
  }
  .md-mt-20 {
    margin-top: 20px;
  }
}

.button-white {
  &:after {
    background: var(--primary-soft-white);
  }
  &:hover {
    background: var(--primary-soft-white);
    color: var(--primary-slate) !important;
  }
}
.background-00D1D2 {
  background: #00D1D2;
}
/*** END trade in info ***/
.custom-bottom-text {
  text-align: center;
}
/*** new card ***/
.open-info {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99;

  span {
    padding: 2px 11px;
    background: #ccc;
    border-radius: 50px;
    transition: all 0.5s;
    color: var(--primary-slate);

    &:hover {
      background: var(--tints-slate-slate-15);
    }
  }
}
/** END new card **/
.services-container {
  margin-top: 60px;

  .services-card {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    max-width: 1480px;
    margin: auto;

    .service-card {
      border: 1px solid var(--tints-slate-slate-15);
      border-radius: 4px;
      margin: 15px;
      cursor: pointer;
      box-shadow: none;

      padding-left: 0 !important;
      padding-right: 0 !important;
      flex: 0 0 14%;
      height: 250px;
      outline: 1px solid;

      @media (max-width: 1500px) {
        flex: 0 0 20%;
      }
      @media (max-width: 1240px) {
        flex: 0 0 30%;
      }

      &:hover {
        background: var(--primary-turquoise);
        border: 1px solid var(--primary-turquoise);
      }

      .clickable {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .md-card-media {
          display: flex;
          justify-content: center;
          margin-top: 24px;

          img {
            width: auto;
            height: 90px;
          }
        }

        .md-card-media + .md-card-header {
          padding-top: 15px;
        }

        .md-card-content {
          text-align: center;
          font-size: 12px;
        }

        .md-title {
          margin-top: 0;
          text-align: center;
          font-size: 22px;
          line-height: 30px;
        }
      }

      &.lunae-lumen {
        .md-card-media {
          margin-top: 18px !important;

          img {
            height: 90px !important;
          }
        }
        .md-card-header {
          padding-top: 0 !important;
          // margin-top: -10px;
        }
      }
    }
  }
}

.selected {
  background: var(--primary-turquoise);
  border: 1px solid var(--primary-turquoise);
}

.md-card-actions {
  justify-content: center !important;
}

.custom-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 130px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media(max-width: 768px) {
      top: 80px;
      align-items: start;
      padding-top: 30px;
      
      &.custom-big-popup {
        padding-top: 5px;
      }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__videocall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-white);
    border-radius: 4px;
    padding: 24px;
    max-width: 400px;
    text-align: center;

    img {
      height: 90px;
    }

    .title {
      font-size: 16px;
      margin-top: 16px;
    }
  }
  &__tradein {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-white);
    border-radius: 4px;
    padding: 24px;
    max-width: 850px;
    text-align: center;
    
    @media (max-width: 991px) {
      max-width: 768px;
    }
    @media (max-width: 860px) {
      max-width: 98%;
      padding: 5px;
    }
    .button {
      width: auto;
    }
  }

  &__click {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-white);
    border-radius: 4px;
    padding: 24px;
    max-width: 600px;
    text-align: center;

    @media (max-width: 768px) {
      max-width: 400px;
    }

    img {
      height: 80px;
    }

    .title {
      font-size: 16px;
      margin-top: 16px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    button {
      width: 220px;

      &:last-child {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 768px) {
  .services-container {
    margin-top: 24px;

    .services-card {
      flex-direction: column;
      align-items: center;
      margin: 16px;

      .service-card {
        width: 60%;
        padding: 10px 0;
        flex: 1;

        img {
          width: auto !important;
          height: 90px !important;
        }

        .md-card-media {
          margin: 0;
        }

        .md-card-header {
          padding: 0;
          margin: 0;

          .md-title {
            margin: 0;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
