export default {
    applyPalette(palette) {
        if (palette) {
            for (const key in palette) {
                if (Object.prototype.hasOwnProperty.call(palette, key)) {
                    document.documentElement.style.setProperty(key, (palette)[key], 'important');
                }
            }
        }
    },
    isToday(date) {
        if (!date) {
            return;
        }
        const today = new Date();
        return (
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
        );
    },
};