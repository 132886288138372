<template>
    <div>
        <Nav :configs="configs.navigation"
             :stepsStatus="getStepsStatus"
             :activeStep="getCurrentStep"
             @navigationBack="handleGoBack()"></Nav>

        <transition name="fade" mode="out-in">
            <component class="first-section"
                       :is="getComponentByStep(getCurrentStep)"
                       :configs="getConfigByStep(getCurrentStep)"
                       @navigation="handleNavigationEvent($event)"></component>
        </transition>
    </div>
</template>

<script>
import Nav from "../components/Nav";
import {
    GOTO_STEP,
    INITIALIZE_STEPS_STATUS,
    SET_SELECTED_SERVICE,
    SET_SELECTED_STORE,
    SET_STORES_NEAR,
} from "../store/action.type";
import { mapGetters } from "vuex";
import configs from "../configs";

export default {
    name: "HomePage",
    props: {
        configs: Object,
    },
    created() {
        this.$store.dispatch(INITIALIZE_STEPS_STATUS, configs.navigation.steps);
    },
    computed: {
        ...mapGetters([
            "getCurrentStep",
            "getStepsStatus",
            "getSelectedAppointmentTime",
            "getSelectedService",
            "getSelectedStore",
            "getStoresNear",
        ]),
    },
    methods: {
        handleNavigationEvent(event) {
            this.$store.dispatch(GOTO_STEP, event);
        },
        getComponentByStep(step) {
            return this.configs.navigation.steps.find((x) => x.number === step)
                .component;
        },
        getConfigByStep(step) {
            return this.configs.navigation.steps.find((x) => x.number === step)
                .configs;
        },
        currentStep() {
            return this.getCurrentStep.includes(".")
                ? this.getCurrentStep.split(".")[0]
                : this.getCurrentStep;
        },
        handleGoBack() {
            if (this.getCurrentStep >= 1) {
                if (
                    this.getCurrentStep == 1 &&
                    this.getSelectedStore &&
                    this.getSelectedStore.storeServices.length == 1
                ) {
                    this.$store.dispatch(SET_SELECTED_STORE, null);
                    this.$store.dispatch(SET_SELECTED_SERVICE, null);
                }

                this.$store.dispatch(
                    GOTO_STEP,
                    (Number(this.getCurrentStep) - 1).toString()
                );
            } else {
                if (this.getSelectedStore) {
                    this.$store.dispatch(SET_SELECTED_STORE, null);
                    this.$store.dispatch(SET_SELECTED_SERVICE, null);
                    this.$store.dispatch(GOTO_STEP, "0");
                } else {
                    window.parent.postMessage(
                        { message: "closeMe" },
                        "*"
                    );
                    window.dispatchEvent(new Event("trackUserTime"));
                }
            }
            if (this.getStoresNear && this.getStoresNear.length == 0) {
                this.$store.dispatch(SET_STORES_NEAR, null);
            }
        },
    },
    components: {
        Nav,
    },
};
</script>

<style lang="scss">
.section {
    padding: 134px 20% 20px 20%;
    width: 100%;
    height: 100%;
}
.first-section {
    padding: 230px 40px 150px 40px;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .first-section,
    .section {
        padding: 120px 0 40px 0 !important;
    }
}
</style>
