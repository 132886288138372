import Vue from 'vue';
import VueI18n from 'vue-i18n';
import resources from '../resources';

Vue.use(VueI18n);

const dateTimeFormats = {
    short: {
        year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
        year: 'numeric', month: 'long', day: 'numeric',
        weekday: 'long'
    },
    time: {
        hour: 'numeric', minute: 'numeric'
    }
};

// check language
const match = RegExp("[?&]lang=([^&]*)").exec(window.location.search);
const language = match && match[1] ? match[1] : 'it';

const i18n = new VueI18n({
    locale: language,
    fallbackLocale: 'en',
    messages: resources,
    silentFallbackWarn: true,
    dateTimeFormats: {
        'it-IT': dateTimeFormats,
        'it': dateTimeFormats,
        'en': dateTimeFormats
    }
});

export default i18n;