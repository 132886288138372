<template>
    <div class="recap" :class="{ 'two-item': appointment, 'two-lines': twoLines }">
        <!-- store -->
        <div class="recap-item boxed"
             v-if="store">
            <div class="text-recap">
                <img src="/assets/pin-visited.svg" />

                <span class="text">
                    <span class="bold">{{ store.storeType }} {{ store.city }}</span>
                    <span>{{ store.address }}{{ store.zipCode ? ' - ' + store.zipCode : '' }}</span>
                </span>
            </div>
            <div class="button-change">
                <md-button @click="changeStore()" v-if="!readOnly">
                    <span class="change-text">{{ $t('Modifica') }}</span>
                </md-button>
            </div>
        </div>

        <!-- appointment -->
        <div class="recap-item boxed"
             v-if="appointment">
            <div class="text-recap">
                <img src="/assets/calendar-icon.svg" />
                <span class="text">
                    <span class="bold">{{ $d(appointment.startTime, 'long') }}</span>
                    <span>{{ `${$t('Ore')} ${$d(appointment.startTime, 'time')}` }}</span>
                </span>
            </div>
            <div class="button-change">
                <md-button @click="changeData()" v-if="!readOnly">
                    <span class="change-text">{{ $t('Modifica') }}</span>
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import { GOTO_STEP, SET_SELECTED_SERVICE, SET_SELECTED_STORE } from "../store/action.type";
import { mapGetters } from "vuex";
import utils from "../helpers/utils";
import { GTMService } from "../services/gtm";
import configs from "../configs";

export default {
    name: "Recap",
    props: {
        appointment: Object,
        currentStep: String,
        twoLines: Boolean,
        readOnly: Boolean,
        reservationAlreadySaved: Boolean,
        store: Object,
    },
    computed: {
        ...mapGetters([
            "getCurrentStep",
            "getStepsStatus",
            "getSelectedAppointmentTime",
            "getSelectedService",
            "getSelectedStore",
            "getStoresNear",
        ]),
    },
    methods: {
        ...utils,
        changeData() {
            const currentStep = this.getCurrentStep;
            for (let i = 1; i < currentStep; i++) {
                setTimeout(() => {
                    this.handleGoBack();
                }, 60);
            }

            const steps = configs.navigation.steps.filter((x) => !x.number.includes("."));
            GTMService.send('Click edit button', `${steps[this.currentStep.split('.')[0]].label}, Change data`, null, false);
        },
        changeStore() {
            const currentStep = this.getCurrentStep;
            for (let i = 0; i < currentStep; i++) {
                setTimeout(() => {
                    this.handleGoBack();
                }, 60);
            }

            const steps = configs.navigation.steps.filter((x) => !x.number.includes("."));
            GTMService.send('Click edit button', `${steps[this.currentStep.split('.')[0]].label}, Change store`, null, false);
        },
        handleGoBack() {
            if (this.getCurrentStep > 0) {
                if (this.getCurrentStep == 1 && this.getSelectedStore) {
                    this.$store.dispatch(SET_SELECTED_STORE, null);
                    this.$store.dispatch(SET_SELECTED_SERVICE, null);
                }
                this.$store.dispatch(
                    GOTO_STEP,
                    (Number(this.getCurrentStep) - 1).toString()
                );
            } else {
                if (this.getCurrentStep == 0 && this.getSelectedStore) {
                    this.$store.dispatch(SET_SELECTED_STORE, null);
                    this.$store.dispatch(SET_SELECTED_SERVICE, null);
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.boxed {
    min-height: 84px;
}

.recap {
    display: flex;

    &.two-item {
        .recap-item {
            width: 45%;
            overflow: hidden;

            &:first-child {
                margin-right: 24px;
            }
        }
    }

    &.two-lines {
        flex-direction: column;
        .recap-item {
            width: 100%;
            margin: 12px 0;
        }
    }

    .recap-item {
        display: flex;
        flex: 1;
        width: 100%;
        background: var(--primary-white);
        border-radius: 4px;

        .text-recap {
            display: flex;
            align-items: center;
            flex: 1;
            overflow: hidden;
            padding: 0 16px;

            .text {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 16px;
                font-size: 14px;

                overflow: hidden;

                .bold {
                    font-family: iqos-bold;
                    font-size: 16px;
                }

                span {
                    overflow: hidden;
                    text-transform: capitalize;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:last-child {
                        padding-top: 2px;
                    }
                }
            }

            img {
                width: 20px;
            }
        }
    }

    .button-change {
        display: flex;
        align-items: center;
        padding-right: 8px;

        & > button {
            margin: 0;
            line-height: 0;
            color: var(--primary-turquoise);
            text-decoration: underline;
            text-transform: unset !important;

            & > div {
                padding: 0 !important;
            }

            .icon-settings {
                font-size: 18px !important;
            }
        }

        .change-text {
            padding-right: 4px;
            font-size: 16px;
        }
    }
}

@media (max-width: 768px) {
    .boxed {
        background: white;
        flex-wrap: wrap;
    }

    .recap {
        .recap-item {
            flex-direction: column;
            padding: 24px 0;

            .text-recap {
                width: 100%;

                .text {
                    overflow: hidden;
                    text-transform: capitalize;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .button-change {
                margin-left: 42px;
                margin-bottom: -6px;
            }
        }

        &.two-item {
            flex-direction: column;
            width: auto;

            .recap-item {
                width: 100% !important;
                flex-direction: column;

                &:first-child {
                    margin-right: 0;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .text {
        letter-spacing: 1px;
        font-size: 14px;
    }
}

@media (orientation: landscape) {
    .md-layout {
        flex-wrap: nowrap !important;
    }
}
</style>
