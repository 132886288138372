<template>
  <div>
    <p class="title">{{ $t('Quando desideri venire a trovarci?') }}</p>
    <div class="background">
      <Recap
        class="recap"
        v-if="getSelectedStore | (getCurrentStep > 0)"
        :store="getCurrentStep > 0 ? getSelectedStore : null"
        :appointment="getCurrentStep > 1 ? getSelectedAppointmentTime : null"
        currentStep="1"
        @changeData="changeData()"
        @changeStore="changeStore()"
      />
      <div class="appointment-container">
        <transition name="fade" mode="out-in">
          <div class="calendar">
            <Calendar
              :disabled-dates="disabledDates"
              :store-id="getSelectedService.uuid"
              :pre-selected-date="selectedDay"
              @day-click="selectDay($event)"
            ></Calendar>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div class="time-picker">
            <p class="subtitle">{{ $t('Quando desideri venire a trovarci?') }}</p>

            <Timepicker
              class="picker"
              :class="{ 'disabled': !selectedDay }"
              :slots="slots"
              :isLoading="loadingSlots"
              :type="'full'"
              @selected="selectTime($event)"
            ></Timepicker>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_SELECTED_APPOINTMENT_TIME } from "../store/action.type";
import Timepicker from "../components/Timepicker";
import { ApiService } from "../services/api";
import Calendar from "../components/Calendar";
import Recap from "../components/Recap";
import utils from "../helpers/utils";

export default {
  name: "DatePage",
  props: {
    configs: Object
  },
  async created() {
    this.selectedDay = this.getSelectedAppointmentTime
      ? this.getSelectedAppointmentTime.startTime
      : new Date();
    this.getSlots();
  },
  data: () => ({
    selectedDay: null,
    slots: null,
    disabledDates: [],
    loadingSlots: false
  }),
  computed: {
    ...mapGetters([
      "getSelectedStore",
      "getSelectedService",
      "getCurrentStep",
      "getSelectedAppointmentTime"
    ]),
    today() {
      return new Date();
    }
  },
  methods: {
    ...utils,
    selectTime(time) {
      const startTime = new Date(this.selectedDay);
      startTime.setHours(Number(time.slot.split(":")[0]));
      startTime.setMinutes(Number(time.slot.split(":")[1]));

      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + time.duration);
      this.$store.dispatch(SET_SELECTED_APPOINTMENT_TIME, {
        startTime: startTime,
        endTime: endTime
      });

      setTimeout(() => {
        this.$emit("navigation", 2);
        window.parent.postMessage(
          { message: "scrollTop", animated: false, coordinate: 0 },
          "*"
        );
        window.scrollTo(0, 0);
      }, 350);
    },

    async selectDay(day) {
      if (!day.isDisabled) {
        this.selectedDay = day.date;
        await this.getSlots();

        this.datePickerExpanded = window.screen.availWidth < 768 ? false : true;

        if (window.innerWidth <= 768) {
          window.parent.postMessage(
            {
              message: "scrollBottom",
              animated: true,
              coordinate:
                document
                  .querySelector(".time-picker .subtitle")
                  .getBoundingClientRect().top - 20
            },
            "*"
          );
        }
      } else {
        this.selectedDay = null;
      }
    },

    async getSlots() {
      this.loadingSlots = true;
      const slots = (
        await ApiService.get(
          `/api/store_services/${this.getSelectedService.uuid}/timeslots`,
          {
            date: `${new Date(this.selectedDay).getDate()}-${new Date(
              this.selectedDay
            ).getMonth() + 1}-${new Date(this.selectedDay).getFullYear()}`
          },
          []
        )
      ).data["hydra:member"];

      // if is today filtered out slots before current time
      this.slots = (utils.isToday(this.selectedDay)
        ? slots.filter(x => {
            const dateTimeSlot = new Date(this.selectedDay);
            dateTimeSlot.setHours(+x.slot.split(":")[0]);
            dateTimeSlot.setMinutes(+x.slot.split(":")[1]);

            return dateTimeSlot.getTime() > new Date().getTime();
          })
        : slots
      ).filter(x => x.status !== "closed");

      if (this.getSelectedAppointmentTime) {
        const currentSlot = `${(
          "0" + this.getSelectedAppointmentTime.startTime.getHours().toString()
        ).slice(-2)}:${(
          "0" +
          this.getSelectedAppointmentTime.startTime.getMinutes().toString()
        ).slice(-2)}`;

        // add preselected time-slot
        for (const x of this.slots) {
          x.isSelected = x.slot === currentSlot;
        }
      }

      this.loadingSlots = false;
    }
  },
  components: {
    Calendar,
    Timepicker,
    Recap
  }
};
</script>

<style lang="scss" scoped>
.selected-date {
  display: none;
}

.appointment-container {
  display: flex;
  margin-top: 24px;

  .calendar,
  .time-picker {
    background: var(--primary-white);
    padding: 24px 16px;
    flex: 0.5;
    border-radius: 4px;

    .picker {
      min-height: 48px;
      margin-top: 60px;
    }
  }

  .calendar {
    margin-right: 24px;
  }
}

::v-deep .time-picker {
  .picker.disabled {
    pointer-events: none;

    .time {
      background: transparent;
      color: var(secondaryDark);
    }
  }
}

.background {
  display: flex;
  flex-direction: column;
}
.is-disabled {
  pointer-events: none !important;
}

::v-deep .vc-container {
  width: 100%;
}

@media (max-width: 768px) {
  .background {
    margin: 0 !important;
    padding: 0 24px !important;
  }

  .appointment-container {
    flex-direction: column;
    padding-bottom: 24px;

    .calendar {
      margin-right: 0 !important;
    }

    .time-picker {
      margin-top: 26px;

      .picker {
        padding: 8px 10px;
        margin-top: 14px !important;
        background: white;
      }
    }
  }

  .recap {
    margin-top: 24px;
  }

  .title {
    margin: 0 0 40px 0;
  }

  ::v-deep .vc-title {
    text-transform: capitalize !important;
    font-size: 18px !important;
  }

  .selected-date {
    background: white;
    padding-left: 16px;
    margin: 0 4px;
    text-transform: capitalize;
    display: flex !important;
    align-items: center;
    cursor: pointer;

    span {
      flex: 1;
    }
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 46px;
      margin: 1px;
      background: #d6a76f;

      &:after {
        content: "";
        border: solid white;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 7px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: relative;
        top: -5px;
      }
    }
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .appointment-container {
    flex-direction: column;

    .vc-container {
      flex: 1;
      width: 100%;
      margin-right: 0 !important;
    }
  }
}
</style>
