export const GTMService = {
    send(action, label, value, nonInteraction) {
        window.parent.postMessage(
            {
                message: "GTM",
                data: {
                    category: 'Booking Platform',
                    action,
                    label,
                    value,
                    nonInteraction
                }
            },
            "*"
        );
    }
};