 <template>
    <div class="host">
        <md-steppers :class="{ 'steppers': true, 'md-steppers--en': $i18n.locale === 'en', 'mobile': isMobile }"
                     :md-active-step="active"
                     md-alternative>
            <md-step v-for="(step, index) in steps"
                     :key="index"
                     :md-editable="false"
                     :md-done="step.number == active - 1 || activeAllSteps"
                     :id="index | toString"
                     :md-label="$t(step.label)">
            </md-step>
        </md-steppers>
    </div>
</template>

<script>
export default {
    name: "Stepper",
    props: {
        active: String,
        activeAllSteps: Boolean,
        steps: Array,
        stepsStatus: Object,
    },
    data: () => ({
        isMobile: false,
    }),
    created() {
        this.checkIsMobile();
        window.addEventListener("resize", (() => {
            this.checkIsMobile();
        }));
    },
    methods: {
        checkIsMobile() {
            this.isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
        },
    }
};
</script>

<style lang="scss" scoped>
.steppers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--background-hard-white);

    &-portrait {
        display: none;
    }
}

::v-deep {
    .md-steppers {
        &--en {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            //left: 32px !important;
                        }
                    }
                }
            }
        }

        .md-button {
            &.md-active {
                .md-stepper-number {
                    color: var(--primary-white) !important;
                    background: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                    border-color: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                }
            }

            &.md-done {
                .md-stepper-number {
                    background: var(--primary-turquoise) !important;
                    border-color: var(--primary-turquoise) !important;
                    svg {
                        fill: var(--primary-slate) !important;
                    }
                }
            }
        }

        .md-steppers-wrapper {
            display: none;
        }

        .md-steppers-navigation {
            box-shadow: none !important;
            width: 80%;
            margin-top: 20px;
        }

        .md-button-content {
            display: flex;
            flex-direction: column;
            padding-top: 0;
            color: var(--primary-slate) !important;

            &:after {
                height: 3px;
            }
        }

        .md-stepper-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            height: 100% !important;
            pointer-events: none;

            .md-stepper-number {
                display: flex;
                align-items: center;
                justify-content: center;
                position: unset !important;
                width: 36px;
                height: 36px;
                white-space: nowrap;
                margin: 0 8px !important;
                color: var(--tints-slate-slate-50) !important;
                border: 2px solid var(--tints-slate-slate-50) !important;
                background-color: var(--background-hard-white) !important;
                font-size: 16px;
                font-family: iqos-bold;
                z-index: 999;
            }

            .md-stepper-text {
                margin-top: 5px;
                height: auto;
                span {
                    font-family: iqos-bold !important;
                }
            }

            .md-ripple {
                padding: 0 !important;
                height: 60px;
            }

            .md-button-content {
                &:before {
                    left: 0 !important;
                }

                &:after {
                    height: 3px !important;
                    left: 0 !important;
                }
            }
        }

        &:not(.mobile) {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            left: 73px;
                        }
                    }
                }

                &:nth-child(2) {
                    .md-stepper-number {
                        &::before {
                            right: 70px !important;
                        }
                        &:after {
                            left: 70px !important;
                        }
                    }
                }

                &:last-child {
                    .md-stepper-number {
                        &::before {
                            right: 91px !important;
                        }
                    }
                }

                .md-stepper-number {
                    &:after,
                    &:before {
                        height: 2px !important;
                        top: 33px !important;
                        background: var(--tints-slate-slate-50) !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    ::v-deep {
        .md-steppers-navigation {
            margin-top: 0 !important;
        }
        .md-stepper-header {
            &:first-child {
                .md-stepper-number {
                    &:after {
                        left: 63px !important;
                    }
                }
            }

            &:nth-child(2) {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                    &:after {
                        left: 60px !important;
                    }
                }
            }

            &:last-child {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                }
            }

            .md-stepper-number {
                &:after,
                &:before {
                    height: 2px !important;
                    top: 16px !important;
                    background: var(--tints-slate-slate-50) !important;
                }
            }

            .md-button-content {
                padding-top: 0 !important;
                .md-stepper-text {
                    display: none !important;
                }
            }
        }
    }
}
</style>
