<template>
  <div>

    <!----- navbar ------>
    <div class="navigation">
        <div class="back">
            <md-button v-if="!isMobile"
                       @click="goToHome()">
                <img src="/assets/chevron-left.svg" />
                <span>{{ $t('Torna indietro') }}</span>
            </md-button> 
            <md-button v-if="isMobile"
                       :md-ripple="false"
                       @click="goToHome()">
                <img src="/assets/chevron-left-simple.svg" />
            </md-button>
        </div>
        
        <md-steppers :class="{ 'steppers': true, 'md-steppers--en': $i18n.locale === 'en', 'mobile': isMobile }" md-alternative :md-active-step="1">
            <md-step id="first" :md-label="$t('Negozio')" :md-done="true" :md-editable="false"></md-step>
            <md-step id="second" :md-label="$t('Prenota e Prova')" ></md-step>
        </md-steppers>

        <div class="right">
        </div>
    </div>
    <!--- END navbar --->

    <!--- LOADER --->
    <div class="wrap-loader" v-if="showLoader">
        <div class="loader"></div>
    </div>
    <!--- END LOADER --->

    <!---- popup results ---->
      <div class="custom-popup" v-if="showPopupResult">
        <div class="custom-popup__results">
          <span class="material-icons custom-popup__close" @click="hidePopup()">close</span>

          <!-- content 409 this request already exists -->
          <div class="content-popup" v-if="contentPopup409">
          <img class="img-popup" src="/assets/error-popup.svg" alt="IQOS" />
            <div class="content-text-popup">
                <p class="title-popup">
                    {{ $t("Errore") }}
                </p>
                <p class="text-popup">
                    {{ $t("Ci dispiace ma sembra che sia già stata presa in carico una richiesta a tuo nome.") }}
                    <br/>
                    {{ $t("Ti ricordiamo che è possibile richiedere un solo dispositivo per la prova.") }}
                </p>
            </div>
          </div>
        <!-- END content 409 -->

        <!-- content 406 user not eligible -->
          <div class="content-popup" v-if="contentPopup406">
          <img class="img-popup" src="/assets/error-popup.svg" alt="IQOS" />
            <div class="content-text-popup">
                <p class="title-popup">
                    {{ $t("Siamo spiacenti ma questo servizio è disponibile solo per nuovi consumatori.") }}
                </p>
                <p class="subtitle-popup">
                    {{ $t("Ci risulta che tu faccia già parte del mondo IQOS.") }}
                </p>
                <p class="text-popup">
                    {{ $t("Scopri le iniziative a te dedicate.") }}
                </p>
                <div class="box-info-popup">
                    <div class="col-info-popup img-background">
                        <img src="https://d1sgjabyqzb9dz.cloudfront.net/promo-mgm/new-pic/desk-lau.jpg" alt="IQOS">
                    </div>
                    <div class="col-info-popup">
                        <div class="content-info-popup">
                            <p class="text-info-popup">
                                {{ $t("Ottieni fino a 20€ di voucher sconto: consiglia IQOS a un amico fumatore adulto.") }}
                            </p>
                            <md-button class="button md-primary button-popup" @click="InviteFriends()">
                                {{ $t("Invita amico") }}
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        <!-- END content 406 -->

        </div>
      </div>
      <!--- END popup results--->

    <div class="wrap-form">
        <div class="col-content border-dx">
            
            <div class="info-page">
                <div class="col-img-info-page">
                    <img loading="lazy" class="" src="/assets/pencil.svg" alt="IQOS">
                </div>
                <div class="col-text-info-page">
                    <p class="text-little">
                        {{ $t("Prenota e Prova") }}
                    </p>
                    <p class="text-big">
                        <strong>
                            {{ $t("Scrivi i tuoi dati per il ritiro in negozio") }}
                        </strong>
                    </p>
                </div>
            </div>

            <p class="defaultP mb20">
                {{ $t("Ci siamo quasi! Non ti resta che inserire i dati di contatto ed inviare la richiesta. Ti risponderemo sulla disponibilità del prodotto nel negozio selezionato, entro 3 ore lavorative.*") }}
            </p>

            <p class="defaultP mb50">
                <b>
                    {{ $t("Ti ricordiamo che il servizio è attivo solo per nuovi utilizzatori. Se fai già parte del mondo IQOS, ") }}
                    <span @click="goToOffer()" class="underline"> {{$t("scopri le offerte a te dedicate.")}}</span>
                </b>
            </p>

            <div v-if=" this.nameStore != '' && this.addressStore != ''">
                <p class="subHeading mb10">
                    {{ $t("Negozio selezionato:") }}
                    
                </p>
                <div class="greyBlock mb50" id="selectedStoreBlock">
                    <div id="selectedStore_leftBlock">
                        <p id="selectedStore-name">{{ this.nameStore }}</p>
                        <p id="selectedStore-address">{{ this.addressStore }}</p>
                    </div>
                    <div id="selectedStore_rightBlock">
                        <span id="goBackCustom" @click="goToStores()">{{ $t("Cambia") }}</span>
                    </div>
                </div>
            </div>
            

            <div class="block-device-info" v-if=" this.nameSelectedDevice != '' && this.variantSelectedDevice != '' && this.imageSelectedDevice != '' ">
                <p class="subHeading mb10">
                    {{ $t("Prodotto selezionato:") }}
                </p>
                <div class="greyBlock mb20" id="selectedProductBlock">
                    <div id="selectedProduct_leftBlock">
                        <p id="selectedProduct-name">{{ this.nameSelectedDevice }}</p>
                        <p id="selectedProduct-variant" class="mb20">{{ this.variantSelectedDevice }}</p>
                        <p class="small">
                            {{ $t("Il colore del dispositivo è a titolo esemplificativo") }}
                        </p>
                    </div>
                    <div id="selectedProduct_rightBlock">
                        <img loading="lazy" id="selectedProduct-image" v-bind:src="this.imageSelectedDevice" alt="IQOS">
                    </div>
                </div>
            </div>
            
            
            <div class="block-info" v-if="showInfo">
                <!--- Block info --->
            <p class="subHeading mb10">{{ $t("Informazioni importanti") }} </p>
            <p class="defaultP mb20" v-html="this.descriptionInfo">
            </p>
                <!--- END Block info --->
            </div>
            

        </div>
        <div class="col-content">
            <!-- add fields form -->
            <form novalidate class="md-layout form-click-and-lending" @submit.prevent="validateForm">

            <!-- Name  -->
            <md-field :class="getValidationClass('name')">
              <label for="first-name">{{ $t('Nome') }}*</label>
              <md-input
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                :disabled="sending"
                v-model="form.name"
                @change="detectInput('name')"
              />
              <span
                class="md-error"
                v-if="!$v.form.name.required"
              >{{ $t('Inserisci il tuo nome per proseguire') }}</span>
              <span
                class="md-error"
                v-else-if="!$v.form.name.minlength"
              >{{ $t('Nome troppo corto') }}</span>
            </md-field>
            <!-- END Name -->

            <!-- Surname -->
            <md-field :class="getValidationClass('surname')">
              <label for="last-name">{{ $t('Cognome') }}*</label>
              <md-input
                name="last-name"
                id="last-name"
                autocomplete="family-name"
                :disabled="sending"
                v-model="form.surname"
                @change="detectInput('surname')"
              />
              <span
                class="md-error"
                v-if="!$v.form.surname.required"
              >{{ $t('Inserisci il tuo cognome per proseguire') }}</span>
              <span
                class="md-error"
                v-else-if="!$v.form.surname.minlength"
              >{{ $t('Cognome troppo corto') }}</span>
            </md-field>
            <!-- END Surname -->

            <!-- Email -->
            <md-field :class="getValidationClass('email')">
              <label for="email">Email*</label>
              <md-input
                type="email"
                name="email"
                id="email"
                autocomplete="email"
                :disabled="sending"
                v-model="form.email"
                @change="detectInput('mail')"
              />
              <span
                class="md-error"
                v-if="!$v.form.email.required"
              >{{ $t('Inserisci la tua email per proseguire') }}</span>
              <span class="md-error" v-else-if="!$v.form.email.email">{{ $t('Email non valida') }}</span>
            </md-field>
            <!-- END Email -->

            <!-- Phone -->
            <div :class="{ 'value-phone-exist' : this.form.phone != ''}" class="wd-100">
                <md-field :class="getValidationClass('phone') ">
                <label for="phone" class="custom-label" :class="{ 'activate-focus' : isFocused === true}">{{ $t('Telefono') }}*</label>
                <vue-tel-input
                    :autoFormat="false"
                    class="custom-phone"
                    id="phone"
                    name="phone"
                    autocomplete="phone"
                    :disabled="sending"
                    v-model="form.phone"
                    placeholder="Inserisci il numero di telefono"
                    v-on:country-changed="countryChanged"
                    @focus="setFocus(true)"
                    @blur="setFocus(false)"
                />
                <span
                    class="md-error"
                    v-if="$v.form.phone.$invalid"
                >{{ $t('Numero di telefono non valido') }}</span>
                </md-field>
            </div>
            <!-- END Phone -->

            <!-- END add fields form -->
                <div>
                    <p class="description-notice mb-md-description-notice">
                    {{$t('Sei un nuovo utente non registrato su IQOS.com? Fatto salvo il diritto di trattare dati personali per finalità di marketing sulla base del legittimo interesse come precisato nell’ ')}}
                    <span class="link-notice underline" @click="openPrivacyPolicyNotice()" >{{$t('Informativa Privacy')}}</span>,
                    {{$t('desidero fornire a Philip Morris Italia il consenso a:')}}
                    </p>
                </div>
                <div class="box-cont">
                    <input class="input-checkbox" type="checkbox" id="checkbox" v-model="valueMarketResearch" />
                    <span class="checkmark"></span>
                    <label for="checkbox" class="">{{ $t('Inviami novità legate ai prodotti senza fumo e ricevere informazioni sui prodotti e servizi che mi permettano di usare al meglio tali prodotti senza fumo') }}</label>
                </div>
                <div>
                    <p class="description-notice mt-md-description-notice">
                    {{$t('Puoi cambiare idea o revocare il tuo consenso in qualsiasi momento, direttamente dal link nelle nostre comunicazioni oppure contattando il')}}
                    <span class="link-notice underline" @click="openCostumerCare()" >{{$t('Servizio Clienti')}}</span>.
                    </p>
                </div>
                <md-progress-bar md-mode="indeterminate" v-if="sending" class="progress" />

                <md-button class="invisible-submit" type="submit"></md-button>
            </form>

            <md-button
            class="button md-primary button-full"
            :disabled="sending"
            @click="submitForm()"
            >{{ $t('Conferma') }}</md-button>
            
        </div>
    </div>  
  </div>
</template>

<script>
import axios from 'axios';
import { ApiService } from "../services/api";
import { required, email, minLength } from "vuelidate/lib/validators";
import utils from "../helpers/utils";
import { mapGetters } from "vuex";
const phoneValidator = value => (value ? /^[+]?\d+$/.test(value) : true);
//import { GTMService } from "../services/gtm";

export default {
  name: "DeviceRequest",
  props: {
    configs: Object
  },
  computed: {
    ...mapGetters([
        "getSelectedStore"
    ])
  },
  data: () => ({
    form: {
      name: null,
      surname: null,
      phone: null,
      email: null
    },
    sending: false,
    requiredFields: false,
    valueMarketResearch: false,
    formTouched: false,
    changedVal: false,
    imageSelectedDevice: "",
    nameSelectedDevice: "",
    variantSelectedDevice: "",
    codeSelectedDevice: "",
    nameStore: "",
    addressStore: "",
    showPopupResult: false,
    contentPopup409: false,
    contentPopup406: false,
    showLoader: false,
    isMobile: false,
    showInfo: false,
    descriptionInfo: null,
    isFocused: false,
    countryDialCode: "",
    generatedPhone: ""
  }),
  created(){
    window.parent.postMessage(
        { message: "scrollTop", animated: false, coordinate: 0 },
          "*"
    );

    // Send Impression Form
    window.parent.postMessage({ message: "ImpressionFormClickAndLending" }, "*");

    this.checkIsMobile();
        window.addEventListener("resize", (() => {
            this.checkIsMobile();
    }));
    
    if(this.getSelectedStore == null){
        // store not exist come back
        this.$router.push("/");
    } else {
        this.nameStore = this.getSelectedStore.name;
        this.addressStore = this.getSelectedStore.address;
        //console.log("this.getSelectedStore -> ", this.getSelectedStore.city);
        // send city //
        window.parent.postMessage({ message: "InfoSelectedStoreCity", detail: this.getSelectedStore.city }, "*");
        // send address //
        window.parent.postMessage({ message: "InfoSelectedStoreAddress", detail: this.getSelectedStore.address }, "*");
        // send name //
        window.parent.postMessage({ message: "InfoSelectedStoreName", detail: this.getSelectedStore.name }, "*");
    }
    // info device
    axios.get("https://s3.eu-west-1.amazonaws.com/it.iqos-bp-web/products.json")
        .then((response) => {
            //this.getAmount = response.data;
            //console.log("detailDevice -> ", response.data);
            this.imageSelectedDevice = response.data[0].alternativeImageUrl,
            this.nameSelectedDevice = response.data[0].alternativeProductName,
            this.variantSelectedDevice = response.data[0].alternativeProductVariant,
            this.codeSelectedDevice = response.data[0].alternativeProductCode
        })
    // detail info
    axios.get("https://s3.eu-west-1.amazonaws.com/it.iqos-bp-web/informations.json")
        .then((response) => {
            if(this.$i18n.locale == "it"){
                this.descriptionInfo = response.data["IT"]
                this.showInfo = true;
            } else if (this.$i18n.locale == "en") {
                this.descriptionInfo = response.data["EN"]
                this.showInfo = true;
            }
        })
  },
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(3)
        },
        surname: {
            required,
            minLength: minLength(3)
        },
        phone: {
            required,
            phoneValidator,
            minLength: minLength(9)
        },
        email: {
            required,
            email
        }
    }
  },
  methods: {
    ...utils,
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    setFocus(value) {
      this.isFocused = value;

      //var CurrentPhoneUser = this.form.phone;
      
      if(this.form.phone != "" && !value){
        
        this.form.phone = this.form.phone.replace(/\s/g, "");
        // send postMessage
        if(this.changedVal == false) {
         //console.log("send post message phone");
         this.startSubmission("phone");
        }
      }
    },
    countryChanged(country) {
        //console.log("current country dialCode -> ", country.dialCode);
        this.form.phone = this.form.phone.replace(/\s/g, "");
        this.countryDialCode = country.dialCode;
    },
    checkIsMobile() {
        this.isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
    },
    goToHome() {
        this.$router
          .push({ path: '/' })
          .then(() => { this.$router.go(0) })
    },
    goToStores() {
       window.parent.postMessage({ message: "clickChangeStore" }, "*");
    //    this.$router
    //       .push({ path: '/' })
    //       .then(() => { this.$router.go(0) }) 
    this.$router
          .push({ path: '/' })
          .then(() => {  }) 
    },
    hidePopup() {
        this.showPopupResult = false;
    },
    validateForm() {
      
      this.$v.$touch();

      this.formTouched = true;
      if (!this.$v.$invalid) {
        window.parent.postMessage({ message: "FormCALSubmitted" }, "*");
        this.setAppointment();
        //GTMService.send( "Form Submitted", "Prenota un appuntamento", null, false);
      } else {
        // GTM events
        // if (this.$v.form.email.$invalid) {
        //   if (this.form.phone) {
        //     GTMService.send("Form Failed", "Wrong email", null, false);
        //   } else {
        //     GTMService.send("Form Failed", "Wrong email & Missing number", null, false);
        //   }
        // } else if (!this.form.phone) {
        //   GTMService.send("Form Failed", "Wrong email", null, false);
        // }
        if (this.$v.form.name.$invalid) {
            //console.log("name invalid");
            window.parent.postMessage({ message: "invalidForm", field: "name"  }, "*");
        }
        if (this.$v.form.surname.$invalid) {
            //console.log("surname invalid");
            window.parent.postMessage({ message: "invalidForm", field: "surname"  }, "*");
        }
        if (this.$v.form.email.$invalid) {
            //console.log("email invalid");
            window.parent.postMessage({ message: "invalidForm", field: "email"  }, "*");
        }
        if (this.$v.form.phone.$invalid) {
            //console.log("phone invalid");
            window.parent.postMessage({ message: "invalidForm", field: "phone"  }, "*");
        }
      }
    },

    showResultPopup(idError) {
        this.showPopupResult = true;

        if(idError == 406){
            this.contentPopup406 = true;
        } else if (idError == 409) {
            this.contentPopup409 = true;
            //this.contentPopup406 = true;
        } 
        
    },
    disableSendingAndHideLoader(){
        this.sending = false;
        this.showLoader = false;
        //console.log("inside disableSendingAndHideLoader");
    },
    showBlockLoader(){
        //console.log("acxtivate slider");
        this.showLoader = true;
    },
    async setAppointment() {
      this.sending = true;
      this.showBlockLoader();
      
      var CurrentPhoneUser = this.form.phone;
      // controllo se i primi due caratteri sono 00
        if(this.form.phone.substring(0, 2) == "00"){
            CurrentPhoneUser = CurrentPhoneUser.substring(2);
        }
        // rimuovo spazi e il prefisso se viene inserito
        CurrentPhoneUser = CurrentPhoneUser.replace(/\s/g, "").replace("+"+this.countryDialCode, "");
        // console.log("CurrentPhoneUser -> ", CurrentPhoneUser);
        this.generatedPhone = "+"+this.countryDialCode+CurrentPhoneUser;
        //console.log(" generatedPhone -> ", this.generatedPhone);
      
      try {
        const appointmentResponse = await ApiService.post(
          `/api/click_and_lending_reservations`,
          {
            "store": this.getSelectedStore.uuid,
            "productName": this.nameSelectedDevice,
            "productVariant": this.variantSelectedDevice,
            "productCode": this.codeSelectedDevice,
            "productQuantity": 1,
            "userName": this.form.name,
            "userSurname": this.form.surname,
            "userEmail": this.form.email,
            //"userPhone": this.form.phone,
            "userPhone": this.generatedPhone,
            "outcome": "",
            "reservationNotes": "",
            "subscribedFlag": false,
            "marketResearch": this.valueMarketResearch,
            "imageUrl": this.imageSelectedDevice,
            "referral" : ""
          }
        )
          //console.log("before appointmentResponse -> ", appointmentResponse);

          if (appointmentResponse instanceof Error){
            //console.log("appointmentResponse.message -> ",appointmentResponse.response.status)
            if(appointmentResponse.response.status == 409){
                //richiesta già presente
                this.showResultPopup("409");
                //console.log("richiesta già presente");
                this.disableSendingAndHideLoader();
            } else if(appointmentResponse.response.status == 406){
                //user non elegibile
                this.showResultPopup("406");
                //console.log("user non elegibile");
                this.disableSendingAndHideLoader();
            } else {
                //console.log("Else error");
                this.disableSendingAndHideLoader();
            }

            window.parent.postMessage({ message: "submitKo", errorCode: appointmentResponse.response.status  }, "*");

          } else {
            // done
            // console.log("done -> ", appointmentResponse.status);
            if(appointmentResponse.status == 201 || appointmentResponse.status == 200){
                //console.log("richiesta andata a buon fine");
                window.parent.postMessage({ message: "submitOk", store: this.nameStore }, "*");
                this.disableSendingAndHideLoader();
                this.$router.push("/thankyou-click/");

            } else {
                //console.log("richiesta ERRORE");
                this.disableSendingAndHideLoader();
            }
          }
        // controllo response dentro appointmentResponse.data

      }  catch (e) {
        this.sending = false;
        window.parent.postMessage({ message: "submitKo", errorCode: e.message }, "*");
      }
    },

    // click link 
    openPrivacyPolicyNotice() {
      window.parent.postMessage({ message: "openPrivacyPolicyNotice" }, "*");
    },
    openCostumerCare() {
      window.parent.postMessage({ message: "openCostumerCare" }, "*");
    },
    // ADD POSTMESSAGE //
    goToOffer() {
      window.parent.postMessage({ message: "goToOffer" }, "*");
    },
    InviteFriends() {
      window.parent.postMessage({ message: "inviteFriends" }, "*");
    },
    // END click link 
    submitForm() {
      document.querySelector(".invisible-submit").click();
    },
    startSubmission(value) {
      this.changedVal = true;
      window.parent.postMessage({ message: "startSubmissionClickAndLending", type: value  }, "*");
      //console.log("Postmessage -> startSubmission ", value );
    },
    detectInput(typeInput) {
      if(this.changedVal == false) {
        // post message start submission
        if(typeInput == "name") {
          this.startSubmission("name");

        } else if (typeInput == "surname") {
          this.startSubmission("surname");

        } else if (typeInput == "mail") {
          this.startSubmission("mail");

        // } else if (typeInput == "phone") {
        //   this.startSubmission("phone");
        } 
      }
      
      // post message detect validation and required field 
      if(this.$v.form.name.$invalid == false && this.$v.form.surname.$invalid == false && this.$v.form.email.$invalid == false && this.$v.form.phone.$invalid == false && this.requiredFields == false) {
        this.requiredFields = true;
        window.parent.postMessage({ message: "validationAndRequiredFieldsCAL" }, "*");
        //console.log("campi required validi");
      }
    }

  },
  components: {
    //Nav
  }
}

</script>

<style>
.custom-phone ul {
    max-width: 300px;
}
.custom-phone ul.below {
    top: 47px !important;
}
.custom-phone input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
}
.custom-phone input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
}
.custom-phone input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
}
.custom-phone input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
}
.custom-phone input::placeholder {
    color: transparent;
}

</style>

<style lang="scss" scoped>
.wd-100 {
    width: 100%;
    .md-field {
        height: 68px !important;
    }
}
.custom-label {
    left: 60px !important;
}
.custom-label.activate-focus {
    color: var(--primary-slate) !important;
    top: 4px !important;
    padding: 0 4px !important;
    //left: 20px !important;
    font-size: 12px !important;
    z-index: 99;
}
.value-phone-exist {
    .custom-label {
        color: var(--primary-slate) !important;
        top: 4px !important;
        padding: 0 4px !important;
        //left: 20px !important;
        font-size: 12px !important;
        z-index: 99; 
    }
    .custom-phone {
        box-shadow: none !important;
        border: 1px solid var(--primary-slate);
    }
}

.custom-phone {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--tints-slate-slate-15);
    height: 48px;
    padding: 0;

    &:focus,
    &:focus-within {
      box-shadow: none !important;
      border: 1px solid var(--primary-slate);
    }
}
.vti__dropdown {
    background: #fff !important;
}
.underline {
    text-decoration: underline;
    cursor: pointer;
}
.content-popup {
    .title-popup {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
    }
    .subtitle-popup {
        font-size: 18px;
        line-height: 24px;
    }
    .text-popup {
        margin-bottom: 30px;
    }
}
/**** loader ****/
.wrap-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.loader {
  border: 10px solid var(--background-hard-white);
  border-radius: 50%;
  border-top: 10px solid var(--primary-turquoise);
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/**** END loader ****/

/*** custom popup ***/
.custom-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 130px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  //
  align-items: start;
  padding-top: 50px;

  @media(max-width: 768px) {
      top: 80px;
      align-items: start;
      padding-top: 30px;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-white);
    border-radius: 4px;
    padding: 24px;
    max-width: 600px;
    text-align: center;

    @media(max-width: 768px) {
      max-width: 95%;
      padding: 10px;
    }

    .img-popup {
      height: 60px;
    }

    .title {
      font-size: 16px;
      margin-top: 16px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    button {
      width: 220px;

      &:last-child {
        margin-top: 24px;
      }
    }
  }

  .box-info-popup {
    display: flex;
    background: var(--primary-slate);

    .col-info-popup {
        width: 50%;
        position: relative;
        min-height: 200px;

        .content-info-popup {
            position: absolute;
            top: 50%;
            left: 5px;
            right: 5px;
            transform: translateY(-50%);

            .text-info-popup {
                margin-top: 0;
                margin-bottom: 20px;
                color: var(--primary-soft-white) !important;
            }
        }

        .button-popup {
            color: var(--primary-slate) !important;
            background: var(--primary-soft-white);
        }
    }
  }
}
  /*** END custom popup ***/

.button-full {
    width: 100%;
}
.link-notice {
  color: var(--primary-slate) !important;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
/**** checkbox ****/
.box-cont {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
.box-cont label {
  cursor: pointer;
}
.box-cont .input-checkbox {
    margin: 0;
    left: 0;
    position: absolute;
    z-index: 9;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
    top: 4px;
}
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid var(--primary-slate);
  border-radius: 5px;
  cursor: pointer;
}
.box-cont .input-checkbox:checked ~ .checkmark {
  background-color: var(--primary-slate);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.box-cont .input-checkbox:checked ~ .checkmark:after {
  display: block;
}
.box-cont .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/**** END checkbox ****/
/***** col dx *****/
.form-click-and-lending {
    .md-theme-default {
        label {
            background: var(--primary-white);
            color: var(--tints-slate-slate-65);
            top: 30px;
            left: 14px;
        }
        input {
            border-radius: 4px;
            border: 1px solid var(--tints-slate-slate-15);
            height: 48px;
            padding: 0 16px;
            margin: 0;
        }
        &.md-focused,
        &.md-has-value {

            label {
                color: var(--primary-slate)!important;
                top: 4px;
                padding: 0 4px;
            }
            input {
                border: 1px solid var(--primary-slate);
            }
        }
    }
}
/*** END col dx ***/
/*** col sx ***/
#selectedProduct_rightBlock img {
    width: 100px;
}
.small {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
}
#selectedProduct-variant {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 12px;
    line-height: 16px;
}
#selectedProduct-name {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
}
#selectedProduct_leftBlock {
  flex-grow: 1;
}
#selectedProductBlock {
  display: flex;
  align-items: center;
}
#selectedStore-address {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
}
#selectedStore-name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
}
#selectedStore_leftBlock {
    flex-grow: 1; 
    padding-right: 10px;
}
#selectedStore_rightBlock #goBackCustom {
    text-decoration: underline;
    letter-spacing: 0px;
    color: var(--primary-turquoise);
    cursor: pointer;
}
#selectedStoreBlock {
    display: flex;
    align-items: center;
}
.greyBlock {
    background-color: var(--background-hard-white);
    padding: 15px;
}
.subHeading {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
    line-height: 20px;
}
.defaultP {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
}
.mb50 {
    margin-bottom: 50px;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.info-page {
    display: flex;
    margin-bottom: 20px;
    align-items: center;  
    min-height: 90px;
}
.col-img-info-page {
    position: relative;
    width: 90px;
    margin-right: 10px;
}
.col-img-info-page img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.col-text-info-page {
    padding: 10px;
}
.col-text-info-page .text-little {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.col-text-info-page .text-big {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0px;
    margin-top: 0px;
}
/*** col dx ***/
.border-dx {
  border-right: 1px solid #ccc;
}

.col-content {
  padding: 20px 15px;
  width: 50%;
}

.wrap-form {
  display: flex;
  margin: 150px auto 0 auto;
  padding: 32px;
  width: 72%;
}

@media (max-width: 991px) {
  .border-dx {
    border-right: none;
  }
  .col-content {
    width: 100%;
    padding: 10px 0;
  }
  .wrap-form {
    padding: 0 24px !important;
    width: 100%;
    display: block;
  }
}
@media (max-width: 768px) {
    .wrap-form {
        margin: 100px 0 0 0  !important;
    }
}

/*** custom navbar ***/
.navigation {
    position: fixed;
    width: 100%;
    height: 130px;
    top: 0;
    display: flex;
    align-items: center;
    background: var(--background-hard-white);
    z-index: 9998;

    .back {
        flex: 0.3;
        margin-left: 40px;

        button {
            text-transform: unset !important;
            width: 95px;
            height: 38px;
            margin: 0;

            span {
                margin: -2px 0 0 4px;
            }

            .icon {
                border: solid var(--primary-slate);
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }

            ::v-deep {
                .md-button-content {
                    display: flex;
                    align-items: center;
                }
            }

            &.md-button-disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    .right {
        display: flex;
        flex: 0.3;
        padding: 0 15px;
    }

    .stepper {
        flex: 1;
        display: flex;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navigation {
        height: 80px;
    }

    .back {
        width: 0;
        flex: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        left: -16px;
    }

    .right {
        display: none !important;
    }
}
/*** END custom navbar ***/

/***** steppers *****/
.steppers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--background-hard-white);

    &-portrait {
        display: none;
    }
}

::v-deep {
    .md-steppers {
        &--en {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            //left: 32px !important;
                        }
                    }
                }
            }
        }

        .md-button {
            &.md-active {
                .md-stepper-number {
                    color: var(--primary-white) !important;
                    background: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                    border-color: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                }
            }

            &.md-done {
                .md-stepper-number {
                    background: var(--primary-turquoise) !important;
                    border-color: var(--primary-turquoise) !important;
                    svg {
                        fill: var(--primary-slate) !important;
                    }
                }
            }
        }

        .md-steppers-wrapper {
            display: none;
        }

        .md-steppers-navigation {
            box-shadow: none !important;
            width: 80%;
            margin-top: 20px;

            button:nth-child(2) .md-stepper-number {
                color: var(--primary-white) !important;
                background: var(--colours-primary-dark-blue-fill-100) !important;
                border-color: var(--colours-primary-dark-blue-fill-100) !important;
            }
        }

        .md-button-content {
            display: flex;
            flex-direction: column;
            padding-top: 0;
            color: var(--primary-slate) !important;

            &:after {
                height: 3px;
            }
        }

        .md-stepper-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            height: 100% !important;
            pointer-events: none;

            .md-stepper-number {
                display: flex;
                align-items: center;
                justify-content: center;
                position: unset !important;
                width: 36px;
                height: 36px;
                white-space: nowrap;
                margin: 0 8px !important;
                color: var(--tints-slate-slate-50) !important;
                border: 2px solid var(--tints-slate-slate-50) !important;
                background-color: var(--background-hard-white) !important;
                font-size: 16px;
                font-family: iqos-bold;
                z-index: 999;
            }

            .md-stepper-text {
                margin-top: 5px;
                height: auto;
                span {
                    font-family: iqos-bold !important;
                }
            }

            .md-ripple {
                padding: 0 !important;
                height: 60px;
            }

            .md-button-content {
                &:before {
                    left: 0 !important;
                }

                &:after {
                    height: 3px !important;
                    left: 0 !important;
                }
            }
        }

        &:not(.mobile) {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            left: 73px;
                        }
                    }
                }

                &:nth-child(2) {
                    .md-stepper-number {
                        &::before {
                            right: 70px !important;
                        }
                        &:after {
                            left: 70px !important;
                        }
                    }
                }

                &:last-child {
                    .md-stepper-number {
                        &::before {
                            right: 91px !important;
                        }
                    }
                }

                .md-stepper-number {
                    &:after,
                    &:before {
                        height: 2px !important;
                        top: 33px !important;
                        background: var(--tints-slate-slate-50) !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    ::v-deep {
        .md-steppers-navigation {
            margin-top: 0 !important;
        }
        .md-stepper-header {
            &:first-child {
                .md-stepper-number {
                    &:after {
                        left: 63px !important;
                    }
                }
            }

            &:nth-child(2) {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                    &:after {
                        left: 60px !important;
                    }
                }
            }

            &:last-child {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                }
            }

            .md-stepper-number {
                &:after,
                &:before {
                    height: 2px !important;
                    top: 16px !important;
                    background: var(--tints-slate-slate-50) !important;
                }
            }

            .md-button-content {
                padding-top: 0 !important;
                .md-stepper-text {
                    display: none !important;
                }
            }
        }
    }
}

/*** END steppers ***/
</style>
