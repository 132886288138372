<template>
    <div class="recap-page section">
        <div v-if="reservationStatus === 'edit'">
            <p class="title" v-if="customer">{{ $t('Ciao') }} {{ customer.name }}, <br>{{ $t('ecco il riepilogo della tua prenotazione') }}.</p>

            <div class="recap-container">
                <div class="location">
                    <div>
                        <img src="/assets/pin-visited.svg" />

                        <div class="text">
                            <div v-if="store" class="info">
                                <span class="bold">{{ productName }} {{ store.name }}</span>
                                <span>{{ store.address }} - {{ store.zipCode }}</span>
                            </div>
                            <p class="service-name bold">{{ $t(serviceName) }}</p>
                        </div>
                    </div>

                    <md-button class="navigate-to-store"
                               @click="navigateToStore()">
                        {{ $t('Naviga') }}
                    </md-button>
                </div>

                <!-- recap -->
                <RecapEdit class="recap"
                           ref="recapEdit"
                           :appointment="appointment"
                           :customer="customer"
                           :reservationId="reservationId"
                           :storeId="storeId"
                           @reservationChanged="reservationChanged"
                           @saveEvent="saveChanges" />
            </div>

            <div class="actions">
                <md-button class="button"
                           @click="showDeleteReservationDialog = true">
                    {{ $t('Cancella prenotazione') }}
                </md-button>
            </div>

            <!-- confirm dialog -->
            <md-dialog-confirm :md-active.sync="showDeleteReservationDialog"
                               :md-title="$t('Sei sicuro di voler cancellare la tua prenotazione?')"
                               :md-content="$t('La tua prenotazione verrà cancellata dal sistema in modo permanente.')"
                               :md-confirm-text="$t('Si')"
                               md-cancel-text="No"
                               @md-confirm="deleteReservation" />
        </div>
        <div v-else-if="reservationStatus === 'notFound'" style="text-align: center;">
            <p class="title">{{ $t('Nessuna prenotazione trovata') }}.</p>
            <p>{{ $t('Puoi') }} <span class="url" @click="reload()">{{ $t('effettuare una nuova prenotazione') }}</span> {{ $t('oppure') }} <span class="url" @click="navigateToBlog()">{{ $t('visitare il nostro blog')}}</span>.</p>
        </div>
        <div v-else-if="reservationStatus === 'deleted'" style="text-align: center;">
            <p class="title">{{ $t('La tua prenotazione è stata cancellata') }}.</p>
            <p>{{ $t('Puoi') }} <span class="url" @click="reload()">{{ $t('effettuare una nuova prenotazione') }}</span> {{ $t('oppure') }} <span class="url" @click="navigateToBlog()">{{ $t('visitare il nostro blog')}}</span>.</p>
        </div>

        <!-- snackbar -->
        <md-snackbar :md-duration="2500" :md-active.sync="snackbarOptions.active" md-persistent>
            <span>{{ snackbarOptions.message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
import { ApiService } from "../services/api";
import { GTMService } from "../services/gtm";
import configs from "../configs";
import RecapEdit from "../components/RecapEdit";

export default {
    name: "EditAppointmentPage",
    created() {
        if (this.$route.params.id) {
            this.getAppointmentDatas(this.$route.params.id);
            GTMService.send('View', 'Appointment Recap', null, false);
        }
        this.productName = configs.product;
    },
    data: () => ({
        appointment: null,
        showDeleteReservationDialog: false,
        customer: null,
        markerIcon: null,
        store: null,
        storeCoords: null,
        infoBox: {
            isOpened: false,
            info: {},
            options: {
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
        },
        productName: null,
        reservationId: null,
        reservationStatus: "edit",
        serviceName: null,
        snackbarOptions: {
            active: false,
            message: null,
        },
        storeId: null,
        editedData: null,
    }),
    props: {
        configs: Object,
    },
    methods: {
        async getAppointmentDatas(id) {
            try {
                const reservation = (
                    await ApiService.get("/api/appointments/" + id, null, null)
                ).data;

                this.appointment = {
                    startTime: new Date(reservation.startTime),
                };

                this.customer = {
                    name: reservation.clients[0].name,
                    surname: reservation.clients[0].surname,
                    email: reservation.clients[0].email,
                    phoneNumber: reservation.clients[0].phoneNumber,
                    clientType: reservation.clients[0].clientType,
                    crmClientId: reservation.clients[0].crmClientId,
                };

                //console.log("customer -> ", this.customer);
                // if we ve bcid and bct parameters we update new values
                const bcid = RegExp('[?&]bcid=([^&]*)').exec(window.location.search);
                const bct = RegExp('[?&]bct=([^&]*)').exec(window.location.search);
                if (bcid) {
                    this.customer.crmClientId = bcid && decodeURIComponent(bcid[1].replace(/\+/g, ' '));
                }

                if (bct) {
                    this.customer.clientType = bct && decodeURIComponent(bct[1].replace(/\+/g, ' '));
                }

                this.reservationId = reservation.uuid;
                this.service = reservation.storeService;
                this.serviceName = this.service.externalName;
                this.storeId = this.service.uuid;
                this.store = this.service.store;
            } catch (e) {
                this.reservationStatus = "notFound";
                console.log(e);
            }
        },
        reservationChanged(e) {
            this.editedData = e;
        },
        async deleteReservation() {
            await ApiService.delete(
                `/api/appointments/${this.reservationId}`,
                null,
                null
            );
            this.reservationStatus = "deleted";
        },
        async saveChanges() {
            this.$refs.recapEdit.emitChanges();
            if (this.editedData) {
                this.$refs.recapEdit.collapseEdit();

                await ApiService.patch(
                    `/api/appointments/${this.reservationId}`,
                    this.editedData,
                    []
                );

                window.parent.postMessage(
                    { message: "scrollTop", animated: true, coordinate: 0 },
                    "*"
                );

                setTimeout(() => {
                    this.snackbarOptions = {
                        active: true,
                        message: this.$t('La tua prenotazione è stata modificata.'),
                    };
                }, 250);
            }
        },
        navigateToStore() {
            if (!this.store) {
                return;
            }

            const url = ["iPhone", "iPad", "iPod"].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
                ? `https://maps.apple.com?daddr=${this.store.latitude},${this.store.longitude}`
                : `https://maps.google.com?daddr=${this.store.latitude},${this.store.longitude}`;

            const win = window.open(url, "_blank");
            win.focus();
        },
        navigateToBlog() {
            window.parent.postMessage(
                { message: "navigateToBlog" },
                "*"
            );
        },
        reload() {
            window.location = window.location.origin;
        }
    },
    components: {
        RecapEdit,
    },
};
</script>

<style lang="scss" scoped>
.section {
    margin-top: 76px;
}
a.disabled {
    cursor: default;
}
.bold {
    font-weight: 600;
}
span.url {
    color: var(--primary-slate);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
// location
.location {
    display: flex;
    align-items: center;
    padding: 16px 0 8px 0;
    margin-bottom: 24px;
    background: var(--primary-white);
    border-radius: 4px;

    > div {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 16px;

        img {
            margin-right: 8px;
        }
    }

    .text {
        flex: 1;
        margin-left: 8px;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        line-height: 18px;

        a {
            display: block;
            font-weight: 600;
            margin-top: 16px;
        }
    }

    .service-name {
        margin: 8px 0;
        text-transform: uppercase;
    }

    .navigate-to-store {
        margin: 0;
        padding-right: 8px;
        line-height: 0;
        color: var(--primary-turquoise);
        text-decoration: underline;
        text-transform: unset !important;
        font-size: 16px !important;
    }
}

// recap
.recap-page {
    padding: 0 20% 40px 20%;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.recap-container {
    width: 100%;
    background: var(--background-hard-white);
    padding: 30px;
    margin-top: 30px;

    .recap {
        &.two-item {
            flex-direction: column;
        }
    }
}

::v-deep {
    .md-dialog-container {
        background: var(--primary-white);
    }
    .recap.two-item .recap-item {
        width: 100%;
    }

    .recap.two-item .recap-item:nth-child(2) {
        margin-top: 24px;
    }
}

// delete reservation
.actions {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 24px;

    button {
        width: auto;
        text-transform: uppercase;

        &:first-child {
            box-shadow: inset 0 0 0 2px var(--primary-slate);
            background: transparent !important;
            color: var(--primary-slate) !important;

            &:hover {
                border-color: var(--primary-turquoise);
                background: var(--primary-turquoise) !important;
                box-shadow: none;
            }
        }
    }

    .button {
        &--disabled {
            opacity: 0.7;
        }
    }
}

// mobile
@media (max-width: 768px) {
    .section {
        margin-top: 0;
    }
    .recap-page {
        padding: 0 6% 40px 6%;
    }
    .actions {
        margin-top: 32px;
    }
}
</style>
