 <template>
    <div id="selector" class="container">
        <div :class="{'level__one': true, 'expanded': listSelectorIsOpen}"
             @click="toggle(null)">
            <span class="label">{{ placeholderOpen }}</span>
            <span>{{ placeholderClose }}</span>
            <img src="/assets/chevron-down.svg" />
        </div>

        <md-list v-if="listSelectorIsOpen"
                 class="dropdown"
                 :md-expand-single="true">
            <md-list-item md-expand v-for="(firstStepItem, firstStepItemKey) in firstStepItems"
                          :md-expanded="expandFirstStep(firstStepItem)"
                          :key="firstStepItemKey"
                          class="level__two"
                          @click="expand(firstStepItem, $event)">
                <span class="md-list-item-text">{{ firstStepItem }}</span>
                <md-list slot="md-expand">
                    <md-progress-bar :class="{ 'loader': true, 'loader--hidden': secondStepItems && secondStepItems.length }"
                                     md-mode="indeterminate" />
                    <transition-group v-if="listSelectorIsExpanded && secondStepItems && secondStepItems.length" name="fade" mode="out-in">
                        <md-list-item v-for="secondStepItem in secondStepItems"
                                      class="md-inset level__three"
                                      :class="{ highlight: highlightSecondStep(secondStepItem) }"
                                      :key="secondStepItem.name"
                                      @click="select(secondStepItem, $event)">

                            <div class="store-info">
                                <img src="/assets/pin-visited.svg" />
                                <div class="info">
                                    <p>{{ secondStepItem.name }}</p>
                                    <p>{{ secondStepItem.address }}</p>
                                    <p></p>
                                </div>
                            </div>
                        </md-list-item>
                    </transition-group>
                </md-list>
            </md-list-item>
        </md-list>
    </div>
</template>

<script>
import utils from "../helpers/utils";
import { GTMService } from "../services/gtm";
export default {
    name: "TwoStepListSelector",
    props: {
        firstStep: String,
        secondStep: Object,
        placeholderClose: String,
        placeholderOpen: String,
        firstStepItems: Array,
        secondStepItems: Array,
        secondStepItemsText: Array,
    },
    data: () => ({
        listSelectorIsOpen: false,
        listSelectorIsExpanded: false,
        listSelectorExpandedRegion: null,
    }),
    created() {
        if (this.expandZeroStep()) {
            this.listSelectorIsOpen = true;
        }
    },
    methods: {
        ...utils,
        toggle(event) {
            if (event) {
                event.stopPropagation();
            }

            this.listSelectorIsOpen = !this.listSelectorIsOpen;

            if (this.listSelectorIsOpen) {
                this.emitScrollBottom(0);
            }

            GTMService.send('Click Store List', this.listSelectorIsOpen ? 'Open' : 'Close', null, false);
        },
        expand(region, event) {
            event.stopPropagation();
            this.listSelectorIsExpanded = region !== this.listSelectorExpandedRegion
                ? true
                : !this.listSelectorIsExpanded;

            this.listSelectorExpandedRegion = region;

            this.$emit("expand", region);

            GTMService.send('Click Region Accordion', this.listSelectorIsExpanded ? 'Open' : 'Close', region, false);
        },
        select(secondStepItem, event) {
            event.stopPropagation();
            this.$emit("select", secondStepItem);
            GTMService.send('Click Store Item', secondStepItem.name, null, false);
        },
        expandZeroStep() {
            return !!this.firstStep;
        },
        expandFirstStep(firstStepItem) {
            return this.firstStep === firstStepItem;
        },
        highlightSecondStep(secondStepItem) {
            return this.secondStep
                ? this.secondStep["@id"] === secondStepItem["@id"]
                : undefined;
        },
        emitScrollBottom(time) {
            setTimeout(() => {
                if (window.innerWidth <= 768) {
                    window.parent.postMessage(
                        {
                            message: "scrollBottom",
                            animated: true,
                            coordinate:
                                document
                                    .querySelector("#selector")
                                    .getBoundingClientRect().top - 20,
                        },
                        "*"
                    );
                }
            }, time || 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    width: 60%;
    margin: 0 auto;

    .dropdown {
        padding: 0;
        border-color: var(--primary-slate);
        border-width: 1.5px;
        border-style: none solid solid solid;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .level {
        &__one {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            border: 1px solid var(--tints-slate-slate-30);
            border-radius: 4px;
            padding: 0 16px;
            font-size: 16px;
            color: var(--tints-slate-slate-65);
            position: relative;
            width: 100%;
            cursor: pointer;

            span:not(.label) {
                flex: 1;
            }

            .label {
                display: none;
                font-size: 12px;
                background: var(--primary-white);
                color: var(--primary-slate);
                top: -12px;
                left: 13px;
                -webkit-transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
                transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
                position: absolute;
                z-index: 4;
                padding: 0 4px;
            }

            img {
                width: 20px;
                position: relative;
                left: -2px;
            }

            &.expanded {
                color: var(--primary-slate);
                border: 1.5px solid var(--primary-slate);

                img {
                    transform: rotate(180deg);
                }

                .label {
                    display: block;
                }
            }
        }
    }
}

::v-deep {
    .level {
        &__two {
            .md-list-item-expand {
                &.md-active {
                    background: var(--background-hard-white);
                    border: none !important;

                    span {
                        color: var(--primary-slate) !important;
                    }

                    .md-list-expand {
                        border: none;
                        ul {
                            background: var(--background-hard-white);
                            padding: 8px 0 16px 0;
                            margin-top: 6px;
                        }
                    }
                }

                .md-list-expand {
                    border: none;
                }

                .md-list-expand-icon {
                    fill: var(--primary-slate) !important;
                    width: 18px;
                    height: 18px;
                    content: url("/assets/chevron-down.svg");
                    margin: 0;
                }

                .md-list-item-content {
                    border: none;
                    border-radius: 0 !important;
                    color: var(--tints-slate-slate-65) !important;

                    &:hover {
                        background: var(--tints-slate-slate-15);
                    }
                }
            }
        }

        &__three {
            margin: 0 16px;
            padding: 0 16px;
            background: var(--primary-white) !important;

            button {
                padding: 10px 8px;
                border-bottom: 1px solid var(--tints-slate-slate-15);

                &:hover {
                    background-color: transparent !important;
                }
            }

            &:last-child button {
                border-bottom: none !important;
            }

            .md-list-item-expand {
                &.md-active {
                    .md-list-expand {
                        background: none !important;
                    }
                }
            }

            .md-list-item-content {
                display: flex;
                justify-content: flex-start;
                padding: 0;
                height: unset !important;

                &:hover {
                    background: transparent !important;
                }

                .store-info {
                    display: flex;
                    flex: 1;

                    .info {
                        color: var(--primary-slate);
                        overflow: hidden;
                        line-height: 17px;
                        margin-left: 16px;

                        p {
                            &:first-child {
                                font-family: iqos-bold;
                                font-size: 16px;
                                padding-bottom: 8px;
                            }

                            font-size: 14px;
                            margin: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

.loader {
    background-color: var(--primary-slate) !important;
    width: 100%;
    height: 3px;
    margin-top: -12px;

    &--hidden {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .container {
        width: 80%;
    }
    ::v-deep {
        .level {
            &__one {
                width: 80%;

                .md-list-item-expand {
                    > .md-list-item-content {
                        height: 52px;
                    }
                }
            }

            &__three {
                button {
                    height: unset;
                }
            }
        }
    }
}
</style>
