export default {
    it: {
        'A breve riceverai una mail di conferma. Un\'ora prima del tuo appuntamento, ti invieremo un SMS per ricordarti ora e luogo della tua prenotazione.': 'A breve riceverai una mail di conferma. Un\'ora prima del tuo appuntamento, ti invieremo un SMS per ricordarti ora e luogo della tua prenotazione.',
        'Acquisto': 'Acquisto',
        'Assistenza': 'Assistenza',
        'C\'è un nuovo servizio nella tua città!': 'C\'è un nuovo servizio nella tua città!',
        'Cancella prenotazione': 'Cancella prenotazione',
        'Ciao': 'Ciao',
        'Cognome troppo corto': 'Cognome troppo corto',
        'Cognome': 'Cognome',
        'Conferma': 'Conferma',
        'Conosci già il Blog IQOS?': 'Conosci già il Blog IQOS?',
        'Consenti prima al browser di accedere alla tua posizione corrente.': 'Consenti prima al browser di accedere alla tua posizione corrente.',
        'Continua prenotazione': 'Continua prenotazione',
        'Da oggi puoi videochiamare il tuo negozio, parlare con il Sales Assistant e ricevere il tuo acquisto entro 3 ore direttamente a casa!': 'Da oggi puoi videochiamare il tuo negozio, parlare con il Sales Assistant e ricevere il tuo acquisto entro 3 ore direttamente a casa!',
        'Dai un’occhiata alla mail, te ne abbiamo inviata una con la conferma della prenotazione e i dettagli per partecipare alla videochiamata.': 'Dai un’occhiata alla mail, te ne abbiamo inviata una con la conferma della prenotazione e i dettagli per partecipare alla videochiamata.',
        'Data': 'Data',
        'Dati Personali': 'Dati Personali',
        'ecco il riepilogo della tua prenotazione': 'ecco il riepilogo della tua prenotazione',
        'effettuare una nuova prenotazione': 'effettuare una nuova prenotazione',
        'Email non valida': 'Email non valida',
        'Hai già scoperto com\'è facile personalizzare il tuo IQOS?': 'Hai già scoperto com\'è facile personalizzare il tuo IQOS?',
        'I dati personali sono trattati da Philip Morris Italia, in qualità di titolare del trattamento, al fine di gestire il servizio di appuntamento.': 'I dati personali sono trattati da Philip Morris Italia, in qualità di titolare del trattamento, al fine di gestire il servizio di appuntamento.',
        'In attesa del tuo appuntamento scopri le ultime novità sul nostro blog.': 'In attesa del tuo appuntamento scopri le ultime novità sul nostro blog.',
        'Inserisci il tuo cognome per proseguire': 'Inserisci il tuo cognome per proseguire',
        'Inserisci il tuo indirizzo e premi invio per trovare il negozio più vicino a te o selezionalo dalla lista': 'Inserisci il tuo indirizzo e premi invio per trovare il negozio più vicino a te o selezionalo dalla lista',
        'Inserisci il tuo indirizzo e premi invio': 'Inserisci il tuo indirizzo e premi invio',
        'Inserisci il tuo nome per proseguire': 'Inserisci il tuo nome per proseguire',
        'Inserisci la tua email per proseguire': 'Inserisci la tua email per proseguire',
        'Inserisci le tue informazioni e completa la prenotazione': 'Inserisci le tue informazioni e completa la prenotazione',
        'la tua prenotazione è confermata': 'la tua prenotazione è confermata',
        'La tua prenotazione è stata cancellata': 'La tua prenotazione è stata cancellata',
        'La tua prenotazione è stata modificata.': 'La tua prenotazione è stata modificata.',
        'La tua prenotazione verrà cancellata dal sistema in modo permanente.': 'La tua prenotazione verrà cancellata dal sistema in modo permanente.',
        'Lista dei negozi': 'Lista dei negozi',
        'Mi dispiace!': 'Mi dispiace!',
        'Modifica': 'Modifica',
        'Naviga': 'Naviga',
        'Negozio': 'Negozio',
        'Nessuna prenotazione trovata': 'Nessuna prenotazione trovata',
        'News e aggiornamenti su tutti i prodotti e molto altro ancora.': 'News e aggiornamenti su tutti i prodotti e molto altro ancora.',
        'Nome troppo corto': 'Nome troppo corto',
        'Nome': 'Nome',
        'Non ci sono negozi {product} disponibili nel raggio di 50km dal CAP da te indicato': 'Non ci sono negozi {product} disponibili nel raggio di 50km dal CAP da te indicato',
        'Numero di telefono non valido': 'Numero di telefono non valido',
        'Oppure': 'Oppure',
        'oppure': 'oppure',
        'Ore': 'Ore',
        'per fortuna puoi provare {product} direttamente a casa tua': 'per fortuna puoi provare {product} direttamente a casa tua',
        'Personalizza ora': 'Personalizza ora',
        'Prenota un appuntamento': 'Prenota un appuntamento',
        'Prenotazione nel negozio': 'Prenotazione nel negozio',
        'Privacy policy': 'L\'informativa privacy completa è disponibile al seguente sito:',
        'Prova a casa tua': 'Prova a casa tua',
        'Puoi': 'Puoi',
        'Quale orario preferisci?': 'Quale orario preferisci?',
        'Quale servizio vuoi prenotare?': 'Quale servizio vuoi prenotare?',
        'Scegli cosa prenotare': 'Scegli cosa prenotare',
        'Quando desideri venire a trovarci?': 'Quando desideri venire a trovarci?',
        'Riceverai a breve la mail con la conferma. Ti suggeriamo di controllare anche la cartella della posta indesiderata qualora non dovessi trovarla.': 'Riceverai a breve la mail con la conferma. Ti suggeriamo di controllare anche la cartella della posta indesiderata qualora non dovessi trovarla.',
        'Salva modifiche': 'Salva modifiche',
        'Scegli il negozio dalla lista': 'Scegli il negozio dalla lista',
        'Scopri di più': 'Scopri di più',
        'Se procedi con la prenotazione di un appuntamento dichiari di essere un fumatore.': 'Se procedi con la prenotazione di un appuntamento dichiari di essere un fumatore.',
        'Se procedi con la tua prenotazione, dichiari di essere un fumatore adulto e acconsenti al trattamento dei tuoi dati da Philip Morris Italia in qualità di titolare del trattamento al fine di gestire il servizio di appuntamento, secondo la seguente': 'Se procedi con la tua prenotazione, dichiari di essere un fumatore adulto e acconsenti al trattamento dei tuoi dati da Philip Morris Italia in qualità di titolare del trattamento al fine di gestire il servizio di appuntamento, secondo la seguente',
        'Sei sicuro di voler cancellare la tua prenotazione?': 'Sei sicuro di voler cancellare la tua prenotazione?',
        'Seleziona un indirizzo dalla lista': 'Seleziona un indirizzo dalla lista',
        'Seleziona un negozio sulla mappa': 'Seleziona un negozio sulla mappa',
        'Si': 'Si',
        'Stiamo riscontrando dei problemi, riprova più tardi.': 'Stiamo riscontrando dei problemi, riprova più tardi.',
        'Telefono': 'Telefono',
        'Torna indietro': 'Indietro',
        'Vai al blog': 'Vai al blog',
        'Videocall': 'Videocall',
        'visitare il nostro blog': 'visitare il nostro blog',
        'Il servizio di spedizione è attivo dal lunedì al venerdì.': 'Il servizio di spedizione è attivo dal lunedì al venerdì.',
        'L’informativa privacy completa è disponibile ': 'L’informativa privacy completa è disponibile ',
        'qui': 'qui',
        'Procedendo con la prenotazione dichiari di essere un fumatore.': 'Procedendo con la prenotazione dichiari di essere un fumatore.',
        'L’accesso al negozio è permesso solo ai maggiorenni ed è garantito ad una persona per appuntamento.': 'L’accesso al negozio è permesso solo ai maggiorenni ed è garantito ad una persona per appuntamento.',
        'Inviami novità legate ai prodotti senza fumo e ricevere informazioni sui prodotti e servizi che mi permettano di usare al meglio tali prodotti senza fumo': 'Inviami novità legate ai prodotti senza fumo e ricevere informazioni sui prodotti e servizi che mi permettano di usare al meglio tali prodotti senza fumo',
        'Inserendo i tuoi dati e cliccando su “Conferma”, accetti i ': 'Inserendo i tuoi dati e cliccando su “Conferma”, accetti i ',
        'del sito e dichiari di aver preso visione dell’': 'del sito e dichiari di aver preso visione dell’',
        'Termini e condizioni': 'Termini e condizioni',
        'Informativa Privacy': 'Informativa Privacy',
        'Sei un nuovo utente non registrato su IQOS.com? Fatto salvo il diritto di trattare dati personali per finalità di marketing sulla base del legittimo interesse come precisato nell’ ': 'Sei un nuovo utente non registrato su IQOS.com? Fatto salvo il diritto di trattare dati personali per finalità di marketing sulla base del legittimo interesse come precisato nell’ ',
        'desidero fornire a Philip Morris Italia il consenso a:': 'desidero fornire a Philip Morris Italia il consenso a:',
        'Puoi cambiare idea o revocare il tuo consenso in qualsiasi momento, direttamente dal link nelle nostre comunicazioni oppure contattando il': 'Puoi cambiare idea o revocare il tuo consenso in qualsiasi momento, direttamente dal link nelle nostre comunicazioni oppure contattando il',
        'Servizio Clienti': 'Servizio Clienti',
        // click and lending pages
        'Vieni in negozio per ritirare il prodotto ed iniziare la prova gratuita di 14 giorni.': 'Vieni in negozio per ritirare il prodotto ed iniziare la prova gratuita di 14 giorni.',
        'Errore': 'Errore',
        'Ci dispiace ma sembra che sia già stata presa in carico una richiesta a tuo nome.': 'Ci dispiace ma sembra che sia già stata presa in carico una richiesta a tuo nome.',
        'Ti ricordiamo che è possibile richiedere un solo dispositivo per la prova.': 'Ti ricordiamo che è possibile richiedere un solo dispositivo per la prova.',
        'Siamo spiacenti ma questo servizio è disponibile solo per nuovi consumatori.': 'Siamo spiacenti ma questo servizio è disponibile solo per nuovi consumatori.',
        'Ci risulta che tu faccia già parte del mondo IQOS.': 'Ci risulta che tu faccia già parte del mondo IQOS.',
        'Scopri le iniziative a te dedicate.': 'Scopri le iniziative a te dedicate.',
        'Ottieni fino a 20€ di voucher sconto: consiglia IQOS a un amico fumatore adulto.': 'Ottieni fino a 20€ di voucher sconto: consiglia IQOS a un amico fumatore adulto.',
        'Invita amico': 'Invita amico',
        'Prenota e Prova': 'Prenota e Prova',
        'Scrivi i tuoi dati per il ritiro in negozio': 'Scrivi i tuoi dati per il ritiro in negozio',
        'Ci siamo quasi! Non ti resta che inserire i dati di contatto ed inviare la richiesta. Ti risponderemo sulla disponibilità del prodotto nel negozio selezionato, entro 3 ore lavorative.*': 'Ci siamo quasi! Non ti resta che inserire i dati di contatto ed inviare la richiesta. Ti risponderemo sulla disponibilità del prodotto nel negozio selezionato, entro 3 ore lavorative.*',
        'Ti ricordiamo che il servizio è attivo solo per nuovi utilizzatori. Se fai già parte del mondo IQOS, ': 'Ti ricordiamo che il servizio è attivo solo per nuovi utilizzatori. Se fai già parte del mondo IQOS, ',
        'scopri le offerte a te dedicate.': 'scopri le offerte a te dedicate.',
        'Negozio selezionato:': 'Negozio selezionato:',
        'Cambia': 'Cambia',
        'Prodotto selezionato:': 'Prodotto selezionato:',
        'Il colore del dispositivo è a titolo esemplificativo': 'Il colore del dispositivo è a titolo esemplificativo',
        'Informazioni importanti': 'Informazioni importanti',
        'Richiesta inviata': 'Richiesta inviata',
        'Stiamo verificando la disponibilità del prodotto selezionato nel negozio che hai scelto.': 'Stiamo verificando la disponibilità del prodotto selezionato nel negozio che hai scelto.',
        'Ti risponderemo entro 3 ore lavorative attraverso le modalità che hai indicato.': 'Ti risponderemo entro 3 ore lavorative attraverso le modalità che hai indicato.',
        'Scopri le novità IQOS': 'Scopri le novità IQOS',
        'Vieni in negozio per ritirare il prodotto e iniziare la prova gratuita di 14 giorni': 'Vieni in negozio per ritirare il prodotto e iniziare la prova gratuita di 14 giorni',
        'Tutto quello di cui hai bisogno per ottenere il massimo dal tuo IQOS.': 'Tutto quello di cui hai bisogno per ottenere il massimo dal tuo IQOS.',
        'Lascia i dispositivi a lamina o a punta che non usi più in negozio e, se non lo hai già, potrai acquistare IQOS ILUMA a un prezzo speciale. Un’occasione per te e per l’ambiente.': 'Lascia i dispositivi a lamina o a punta che non usi più in negozio e, se non lo hai già, potrai acquistare IQOS ILUMA a un prezzo speciale. Un’occasione per te e per l’ambiente.',
        'Fai di un rifiuto una risorsa! Lascia il tuo dispositivo che non usi più in negozio. Il nostro obiettivo è recuperare in media oltre 80% delle materie prime.': 'Fai di un rifiuto una risorsa! Lascia il tuo dispositivo che non usi più in negozio. Il nostro obiettivo è recuperare in media oltre 80% delle materie prime.',
        'Oggi siamo aperti: ': 'Oggi siamo aperti: ',
        'Oggi siamo chiusi': 'Oggi siamo chiusi'
    },
    en: {
        'A breve riceverai una mail di conferma. Un\'ora prima del tuo appuntamento, ti invieremo un SMS per ricordarti ora e luogo della tua prenotazione.': 'You will shortly receive a confirmation email. 1 hour before the appointment, you will receive an SMS to remind you date and place of your booking.',
        'Acquisto': 'Purchase',
        'Assistenza': 'Assistance',
        'C\'è un nuovo servizio nella tua città!': 'There is a new service in your city!',
        'Cancella prenotazione': 'Delete reservation',
        'Ciao': 'Hello',
        'Cognome troppo corto': 'Surname too short',
        'Cognome': 'Last name',
        'Conferma': 'Confirm',
        'Consenti prima al browser di accedere alla tua posizione corrente.': 'You must allow the browser to access your current location.',
        'Continua prenotazione': 'Continue booking',
        'Da oggi puoi videochiamare il tuo negozio, parlare con il Sales Assistant e ricevere il tuo acquisto entro 3 ore direttamente a casa!': 'From today you can video call your store, talk to the Sales Assistant and receive your purchase within 3 hours directly at home!',
        'Dai un’occhiata alla mail, te ne abbiamo inviata una con la conferma della prenotazione e i dettagli per partecipare alla videochiamata.': 'Have a look at the email, we sent you one with the booking confirmation and details for video call.',
        'Data': 'Date',
        'Dati Personali': 'Personal Data',
        'ecco il riepilogo della tua prenotazione': 'Please find below your booking recap',
        'effettuare una nuova prenotazione': 'make a new booking',
        'Email non valida': 'Invalid email',
        'Hai già scoperto com\'è facile personalizzare il tuo IQOS?': 'Have you already find out how easy it is to customize your IQOS?',
        'I dati personali sono trattati da Philip Morris Italia, in qualità di titolare del trattamento, al fine di gestire il servizio di appuntamento.': 'Personal data are processed by Philip Morris Italia, as data controller, in order to manage booking service.',
        'In attesa del tuo appuntamento scopri le ultime novità sul nostro blog.': 'While waiting for your appointment, discover latest news in our blog.',
        'Inserisci il tuo cognome per proseguire': 'Enter your surname to continue',
        'Inserisci il tuo indirizzo e premi invio per trovare il negozio più vicino a te o selezionalo dalla lista': 'Enter your address and confirm to find your nearest store or choose it from the list',
        'Inserisci il tuo indirizzo e premi invio': 'Enter your address and press enter',
        'Inserisci il tuo nome per proseguire': 'Enter your name to continue',
        'Inserisci la tua email per proseguire': 'Enter your email to continue',
        'Inserisci le tue informazioni e completa la prenotazione': 'Enter your information and complete the reservation',
        'la tua prenotazione è confermata': 'your booking is confirmed',
        'La tua prenotazione è stata cancellata': 'Your reservation has been deleted',
        'La tua prenotazione è stata modificata.': 'Your booking has been modified.',
        'La tua prenotazione verrà cancellata dal sistema in modo permanente.': 'Your reservation will be permanently deleted from our system.',
        'Lista dei negozi': 'Shop list',
        'Mi dispiace!': 'I\'m sorry!',
        'Modifica': 'Edit',
        'Naviga': 'Go',
        'Negozio': 'Store',
        'Nessuna prenotazione trovata': 'No reservation found',
        'Nome troppo corto': 'Name too short',
        'Nome': 'First name',
        'Non ci sono negozi {product} disponibili nel raggio di 50km dal CAP da te indicato': 'There are no {product} stores available within 50km of the postcode you indicated',
        'Numero di telefono non valido': 'Invalid phone number',
        'oppure': 'or',
        'Oppure': 'Or',
        'Ore': 'Time',
        'per fortuna puoi provare {product} direttamente a casa tua': 'luckily you can try {product} directly at home',
        'Personalizza ora': 'Customize now',
        'Prenota un appuntamento': 'Book an appointment',
        'Prenotazione nel negozio': 'Reservation on store',
        'Privacy policy': 'The complete privacy policy is available at the following site:',
        'Prova a casa tua': 'Try it at your home',
        'Puoi': 'You can',
        'Quale orario preferisci?': 'At what time?',
        'Quale servizio vuoi prenotare?': 'Which service do you want to book?',
        'Scegli cosa prenotare': 'Choose what to book',
        'Quando desideri venire a trovarci?': 'When do you want to come visit us?',
        'Riceverai a breve la mail con la conferma. Ti suggeriamo di controllare anche la cartella della posta indesiderata qualora non dovessi trovarla.': 'You will shortly receive the email with the confirmation. We suggest that you also check your junk folder if you can\'t find it.',
        'Salva modifiche': 'Save changes',
        'Scegli il negozio dalla lista': 'Choose the store from the list',
        'Scopri di più': 'Discover more',
        'Se procedi con la prenotazione di un appuntamento dichiari di essere un fumatore.': 'If you proceed with your booking, you confirm that you are a smoker.',
        'Se procedi con la tua prenotazione, dichiari di essere un fumatore adulto e acconsenti al trattamento dei tuoi dati da Philip Morris Italia in qualità di titolare del trattamento al fine di gestire il servizio di appuntamento, secondo la seguente': 'By proceeding with the booking, you declare to be an adult smoker and you agree with your personal data treatment by Philip Morris Italia as data processor to manage your booking detils, based on this',
        'Sei sicuro di voler cancellare la tua prenotazione?': 'Are you sure you want to delete your booking?',
        'Seleziona un indirizzo dalla lista': 'Choose a shop from the list',
        'Seleziona un negozio sulla mappa': 'Choose a shop in the map',
        'Si': 'Yes',
        'Stiamo riscontrando dei problemi, riprova più tardi.': 'We\'re having some trouble, please try again later.',
        'Telefono': 'Phone number',
        'Torna indietro': 'Back',
        'Vai al blog': 'Go to blog',
        'Videocall': 'Videocall',
        'visitare il nostro blog': 'visit our blog',
        'Il servizio di spedizione è attivo dal lunedì al venerdì.': 'Delivery service available from Monday to Friday',
        'L’informativa privacy completa è disponibile ': 'The complete privacy policy is available ',
        'qui': 'here',
        'Procedendo con la prenotazione dichiari di essere un fumatore.': 'By proceeding with the booking you declare that you are a smoker.',
        'L’accesso al negozio è permesso solo ai maggiorenni ed è garantito ad una persona per appuntamento.': 'Access to the shop is allowed only to adults and is guaranteed to one person per appointment.',
        'Inviami novità legate ai prodotti senza fumo e ricevere informazioni sui prodotti e servizi che mi permettano di usare al meglio tali prodotti senza fumo': 'Send me any news related to smoke-free products, as well as information on products and services that allow me to use such smoke free products at their best',
        'Inserendo i tuoi dati e cliccando su “Conferma”, accetti i ': 'By entering your data and clicking on “Confirm”, you accept the ',
        'del sito e dichiari di aver preso visione dell’': 'and declare to have read the',
        'Termini e condizioni': 'Terms and Conditions',
        'Informativa Privacy': ' Consumer Privacy Notice',
        'Sei un nuovo utente non registrato su IQOS.com? Fatto salvo il diritto di trattare dati personali per finalità di marketing sulla base del legittimo interesse come precisato nell’ ': 'Are you a new non-registered user on IQOS.com? Without prejudice to the processing of personal data for marketing purposes based on legitimate interest, as better specified in the PMI ',
        'desidero fornire a Philip Morris Italia il consenso a:': 'would also like to grant PMI with my consent to:',
        'Puoi cambiare idea o revocare il tuo consenso in qualsiasi momento, direttamente dal link nelle nostre comunicazioni oppure contattando il': 'You can change your mind and withdraw your consent, by entering your profile or contacting the',
        'Servizio Clienti': 'Customer Care',
        // click and lending pages en
        'Vieni in negozio per ritirare il prodotto ed iniziare la prova gratuita di 14 giorni.': 'Vieni in negozio per ritirare il prodotto ed iniziare la prova gratuita di 14 giorni.',
        'Errore': 'Error',
        'Ci dispiace ma sembra che sia già stata presa in carico una richiesta a tuo nome.': 'We’re sorry, but the request has already been processed.',
        'Ti ricordiamo che è possibile richiedere un solo dispositivo per la prova.': 'We kindly remind you that is possible to book only one device for the 14 days free trial.',
        'Siamo spiacenti ma questo servizio è disponibile solo per nuovi consumatori.': 'We are sorry but this service is only available to new users.',
        'Ci risulta che tu faccia già parte del mondo IQOS.': 'We are aware that you are already part of the iqos world.',
        'Scopri le iniziative a te dedicate.': 'Find out more about offers dedicated to you.',
        'Ottieni fino a 20€ di voucher sconto: consiglia IQOS a un amico fumatore adulto.': 'Get up to €20 discount voucher: recommend IQOS to an adult smoking friend.',
        'Invita amico': 'Invite a friend',
        'Prenota e Prova': 'Click & Lend',
        'Scrivi i tuoi dati per il ritiro in negozio': 'Insert your personal data for the collect in store',
        'Ci siamo quasi! Non ti resta che inserire i dati di contatto ed inviare la richiesta. Ti risponderemo sulla disponibilità del prodotto nel negozio selezionato, entro 3 ore lavorative.*': 'We are almost there! All you have to do is enter the contact details and send the request. We will reply to you on the availability of the product in the selected store, within 3 working hours.*',
        'Ti ricordiamo che il servizio è attivo solo per nuovi utilizzatori. Se fai già parte del mondo IQOS, ': 'Please note that the service is active for new users only. If you are already part of the IQOS world, ',
        'scopri le offerte a te dedicate.': 'find out about the offers dedicated to you.',
        'Negozio selezionato:': 'Store selected:',
        'Cambia': 'Edit',
        'Prodotto selezionato:': 'Product selected:',
        'Il colore del dispositivo è a titolo esemplificativo': 'The color of the device is for illustrative purposes only.',
        'Informazioni importanti': 'Important information',
        'Richiesta inviata': 'Request sent',
        'Stiamo verificando la disponibilità del prodotto selezionato nel negozio che hai scelto.': 'We are checking the availability of the selected product in the shop you have chosen.',
        'Ti risponderemo entro 3 ore lavorative attraverso le modalità che hai indicato.': 'We will reply within 3 working hours through the methods you indicated.',
        'Scopri le novità IQOS': 'Discover IQOS news',
        'Vieni in negozio per ritirare il prodotto e iniziare la prova gratuita di 14 giorni': 'Collect the product in store within 2 days and start your 14 days free trial',
        'Tutto quello di cui hai bisogno per ottenere il massimo dal tuo IQOS.': 'Tutto quello di cui hai bisogno per ottenere il massimo dal tuo IQOS.',
        'Lascia i dispositivi a lamina o a punta che non usi più in negozio e, se non lo hai già, potrai acquistare IQOS ILUMA a un prezzo speciale. Un’occasione per te e per l’ambiente.': 'Lascia i dispositivi a lamina o a punta che non usi più in negozio e, se non lo hai già, potrai acquistare IQOS ILUMA a un prezzo speciale. Un’occasione per te e per l’ambiente.',
        'Fai di un rifiuto una risorsa! Lascia il tuo dispositivo che non usi più in negozio. Il nostro obiettivo è recuperare in media oltre 80% delle materie prime.': 'Fai di un rifiuto una risorsa! Lascia il tuo dispositivo che non usi più in negozio. Il nostro obiettivo è recuperare in media oltre 80% delle materie prime.',
        'Oggi siamo aperti: ': 'We are open today: ',
        'Oggi siamo chiusi':'We are closed today'
    }
};