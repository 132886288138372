 <template>
    <div class="calendar__container">
        <div class="calendar__buttons">
            <button ref="previous"
                    :class="{ 'disabled': currentMonth === actualMonth }"
                    @click="previous()">
                <svg width="26px" height="26px" viewBox="0 -1 16 34">
                    <path d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z"></path>
                </svg>
            </button>
            <span class="subtitle">{{ months[currentMonth] }} {{ currentYear }}</span>
            <button ref="next"
                    :class="{ 'disabled': thresholdExceeded }"
                    @click="next()">
                <svg width="26px" height="26px" viewBox="-5 -1 16 34">
                    <path d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z"></path>
                </svg>
            </button>
        </div>

        <table class="calendar__table">
            <thead>
                <tr class="week-days">
                    <th v-for="(day, index) in days"
                        :key="index"
                        :data-days="day">{{ day }}</th>
                </tr>
            </thead>
            <tbody ref="calendar-body"></tbody>
        </table>
    </div>
</template>

<script>
import { ApiService } from "../services/api";
import { GTMService } from "../services/gtm";

export default {
    name: "Calendar",
    props: {
        storeId: String,
        preSelectedDate: Date
    },
    mounted() {
        this.init();
    },
    computed: {
        months() {
            return this.$i18n.locale === 'it'
                ? ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
                : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        days() {
            return this.$i18n.locale === 'it'
                ? ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']
                : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        // allows to limit the calendar: the user can choose a date that does not exceed (today + maxDaysFromToday)
        maxDayStartingFromToday() {
            const maxDaysFromToday = 30;
            let maxDayStartingFromToday = new Date();
            maxDayStartingFromToday.setDate(this.today.getDate() + maxDaysFromToday);
            return new Date(maxDayStartingFromToday.toDateString());
        }

    },
    data: () => ({
        actualMonth: Number,
        currentMonth: Number,
        currentYear: Number,
        disabledDates: Array,
        selectedDay: Date,
        thresholdExceeded: false, // allow to disable next arrow on calendar month if threshold of (today + maxDaysFromToday) is exceeded
        today: Date
    }),
    methods: {
        async init() {
            this.today = new Date();
            this.actualMonth = this.today.getMonth();
            this.selectedDate = this.preSelectedDate || new Date();

            this.currentMonth = this.today.getMonth();
            this.currentYear = this.today.getFullYear();

            await this.getDisabledDates();
            this.showCalendar(this.currentMonth, this.currentYear);
        },
        selectedMonth() {
            return this.selectedDate.getMonth();
        },
        async previous() {
            const month = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
            const year = this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
            await this.getDisabledDates({ month, year });
            this.showCalendar(month, year);
            GTMService.send('Click Sorting Months', 'Previous', null, false);
        },
        async next() {
            const month = (this.currentMonth + 1) % 12;
            const year = this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
            await this.getDisabledDates({ month, year });
            this.showCalendar(month, year);
            GTMService.send('Click Sorting Months', 'Next', null, false);
        },
        showCalendar(month, year) {
            const firstDay = new Date(year, month).getDay();
            const tbl = this.$refs['calendar-body'];

            tbl.innerHTML = '';

            this.currentYear = year;
            this.currentMonth = month;

            // creating all day cells
            let day = 1;
            for (var i = 0; i < 6; i++) {
                const row = document.createElement('tr');
                let cell;
                for (var j = 0; j < 7; j++) {
                    if (i === 0 && j + 1 < firstDay) {
                        cell = document.createElement('td');
                        const cellText = document.createTextNode('');
                        cell.appendChild(cellText);
                        row.appendChild(cell);
                    } else if (day > this.daysInMonth(month, year)) {
                        break;
                    } else {
                        cell = document.createElement('td');
                        cell.setAttribute('data-date', day);
                        cell.setAttribute('data-month', month + 1);
                        cell.setAttribute('data-year', year);
                        cell.innerHTML = `<span class='day'>${day}</span>`;

                        // today
                        if (
                            day === this.today.getDate() &&
                            year === this.today.getFullYear() &&
                            month === this.today.getMonth()
                        ) {
                            cell.className = 'day-today';
                        }

                        // disabled dates
                        const date = new Date(year, month, day);
                        const dateString = `${year}-${('0' + (month + 1)).slice(-2)}-${('0' + day).slice(-2)}`;

                        const isDisabled = date.getTime() < new Date(this.today.toDateString()).getTime() ||    // past dates
                            this.disabledDates.some(x => x.start === dateString) ||                             // in disabledDates
                            date.getTime() > this.maxDayStartingFromToday.getTime()                             // day > (today + maxDaysFromToday)

                        cell.classList.toggle('day-disabled', isDisabled);

                        this.thresholdExceeded = date.getTime() > this.maxDayStartingFromToday.getTime();

                        // selected date (if any)
                        if (this.preSelectedDate && date.toDateString() === this.preSelectedDate.toDateString()) {
                            cell.querySelector('span').classList.add('day-selected');
                        }

                        // click actions
                        if (!isDisabled) {
                            cell.addEventListener('click', (event) => {
                                const el = event.target.tagName === 'SPAN'
                                    ? event.target.parentElement
                                    : event.target;

                                const days = document.querySelectorAll('.day');
                                for (const day of days) {
                                    day.classList.remove('day-selected');
                                }

                                el.querySelector('span').classList.add('day-selected');

                                this.selectedDate = new Date(
                                    el.getAttribute('data-year'),
                                    el.getAttribute('data-month') - 1,
                                    el.getAttribute('data-date')
                                );

                                this.$emit('day-click', {
                                    isDisabled: false,
                                    date: this.selectedDate
                                });


                                GTMService.send('Click Day', this.selectedDate.toLocaleDateString(), null, false);
                            });
                        }

                        row.appendChild(cell);
                        day++;
                    }
                }

                tbl.appendChild(row);
            }
        },
        daysInMonth(month, year) {
            return 32 - new Date(year, month, 32).getDate();
        },
        async getDisabledDates(event) {
            const newDate = new Date();
            if (event) {
                newDate.setDate(1);
                newDate.setMonth(event.month);
                newDate.setYear(event.year);
            }

            const dates = (
                await ApiService.get(
                    '/api/store_services/' +
                    this.storeId +
                    '/calendar',
                    {
                        days: 31,
                        date: `${new Date(newDate).getDate()}-${new Date(newDate).getMonth() + 1}-${new Date(newDate).getFullYear()}`,
                    },
                    []
                )
            ).data['hydra:member'];

            this.disabledDates = [
                ...dates
                    .filter((d) => d.isClosed)
                    .map((d) => ({ start: d.day, end: d.day })),
                ...[
                    {
                        start: null,
                        end: new Date().setDate(new Date().getDate() - 1),
                    },
                ],
            ];
        },
    },
};
</script>

<style lang="scss">
.calendar {
    border-radius: 4px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;

            &.disabled {
                opacity: 0.25;
                pointer-events: none;
                cursor: not-allowed;
            }

            &:hover {
                background-color: var(secondary) !important;
            }
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 26px;

        thead {
            border-bottom: 1px solid var(secondaryDark);

            .week-days {
                border-bottom: 1px solid var(--tints-slate-slate-15);
                padding-bottom: 8px;

                th {
                    font-weight: normal;
                    width: 12%;
                    padding-bottom: 16px;
                    user-select: none;
                    color: var(--tints-slate-slate-65);
                }
            }
        }

        tbody {
            td {
                text-align: center;
                padding: 6px 0;
                user-select: none;
            }

            .day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 34px;
                cursor: pointer;
                transition: all 0.5s;

                &:hover {
                    background-color: var(secondary);
                }

                &-today {
                    span {
                        color: var(--primary-slate);
                        background: var(--background-hard-white);
                        font-family: iqos-bold;
                    }
                }

                &-selected {
                    color: var(--primary-white) !important;
                    background: var(--primary-turquoise) !important;
                    font-family: iqos-bold;
                }

                &-disabled {
                    pointer-events: none;
                    color: var(--tints-slate-slate-30);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .calendar {
        background: white;
        padding: 8px;
    }
}
</style>
