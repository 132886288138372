<template>
    <transition name="fade">
        <div>
            <!-- Select store -->
            <p class="title">{{ $t('Inserisci il tuo indirizzo e premi invio per trovare il negozio più vicino a te o selezionalo dalla lista') }}</p>
            <div class="container-search">
                <GMAPSearch
                            class="search-gmap"
                            ref="gmapsearch"
                            :selectedMarker="(getSelectedStore && getSelectedStore.latitude) ? getSelectedStore : null"
                            :button="configs.searchMap.button"
                            :searching="!searchIsFinished"
                            :icon="configs.searchMap.icon"
                            :placeholder="$t('Inserisci il tuo indirizzo e premi invio')"
                            :markerIcons="configs.searchMap.markerIcons"
                            :markers="getStoresNear"
                            :mapText="configs.searchMap.mapText"
                            :notFoundConfigs="configs.notFound"
                            :infoBoxButtonText="$t('Conferma')"
                            :infoBoxBodyText="configs.searchMap.infoBox.bodyText"
                            :infoBoxTitleText="configs.searchMap.infoBox.titleText"
                            @search="fetchAllStoresNearLocation($event.lat, $event.lng)"
                            @selected="selectStore($event, 'map')"></GMAPSearch>
                <p class="or">{{ $t('Oppure') }}</p>
                <TwoStepListSelector ::firstStep="(getSelectedRegion && getSelectedStore && !getSelectedStore.latitude) ? getSelectedRegion : null"
                                     :secondStep="(getSelectedRegion && (getSelectedStore && !getSelectedStore.latitude)) ? getSelectedStore : null"
                                     :firstStepItems="getRegionsList"
                                     :placeholderOpen="$t('Lista dei negozi')"
                                     :placeholderClose="$t('Scegli il negozio dalla lista')"
                                     :secondStepItems="getStoresRegion"
                                     :secondStepItemsText="configs.searchList.itemText"
                                     @expand="selectRegion($event)"
                                     @select="selectStore($event, 'list')"></TwoStepListSelector>
            </div>
            <div id="loader" v-if="loader">
                <md-progress-spinner md-mode="indeterminate" :md-diameter="30" :md-stroke="3"></md-progress-spinner>
            </div>
        </div>
    </transition>
</template>

<script>
import configs from "../configs";
import { ApiService } from "../services/api";
import { SET_REGIONS_LIST, SET_SELECTED_REGION, SET_SELECTED_SERVICE, SET_SELECTED_STORE, SET_STORE_PRESELECTED, SET_STORES_NEAR, SET_STORES_REGION, SET_PRODUCT_UUID } from "../store/action.type";
import { mapGetters } from "vuex";
import GMAPSearch from "../components/GMapSearch";
import TwoStepListSelector from "../components/TwoStepListSelector";
import utils from "../helpers/utils";


export default {
    name: "StorePage",
    props: {
        configs: Object
    },
    async created() {
        // get product uuid
        this.productUUID = (
            await ApiService.get("/api/products", { productName: configs.product }, [
                "uuid"
            ])
        ).data["hydra:member"].map(x => x.uuid);

        this.$store.dispatch(SET_PRODUCT_UUID, this.productUUID);
        await this.$refs.gmapsearch.searchFromCap();


        // check if the geoLocation should be executed by parent or frame
        const match = RegExp("[?&]slug=([^&]*)").exec(window.location.search);

        if (match && match[1] && !this.$store.getters.getStorePreselected) {
            this.loader = true;
            await this.preselectStore(match[1]);
            this.$store.dispatch(SET_STORE_PRESELECTED, true);
        } else {
            await this.fetchAllRegions();
        }
        this.loader = false;
    },
    data: () => ({
        loader: false,
        searchIsFinished: true,
        productUUID: undefined
    }),
    computed: {
        ...mapGetters([
            "getRegionsList",
            "getStoresNear",
            "getStoresRegion",
            "getSelectedStore",
            "getSelectedRegion"
        ])
    },
    methods: {
        ...utils,
        async fetchAllRegions() {
            const regions = Array.from(
                new Set(
                    (
                        await ApiService.get(
                            "/api/stores",
                            { products: this.productUUID, pagination: false },
                            ["region"]
                        )
                    ).data["hydra:member"].map(r => r.region)
                )
            );
            this.$store.dispatch(SET_REGIONS_LIST, regions);
        },
        async fetchAllStoresNameFromRegion(region) {
            this.$store.dispatch(SET_STORES_REGION, []);
            const stores = (
                await ApiService.get(
                    "/api/stores",
                    { region, products: this.productUUID, pagination: false },
                    [
                        "name",
                        "city",
                        "address",
                        "storeType",
                        "storeServices",
                        "businessHours",
                        "slug",
                        "uuid"
                    ]
                )
            ).data["hydra:member"];

            this.$store.dispatch(SET_STORES_REGION, stores);
        },
        async fetchAllStoresNearLocation(lat, lng) {
            this.searchIsFinished = false;
            const stores = (
                await ApiService.get(
                    "/api/stores",
                    {
                        lat: lat,
                        lng: lng,
                        distance: 50,
                        products: this.productUUID,
                        pagination: false
                    },
                    [
                        "name",
                        "city",
                        "address",
                        "storeType",
                        "storeServices",
                        "latitude",
                        "longitude",
                        "zipCode",
                        "province",
                        "phoneNumber",
                        "businessHours",
                        "slug",
                        "uuid"
                    ]
                )
            ).data["hydra:member"];
            this.$store.dispatch(SET_STORES_NEAR, stores);
            setTimeout(() => {
                this.searchIsFinished = true;
            }, 500);
        },
        async selectRegion(region) {
            await this.fetchAllStoresNameFromRegion(region);
            this.$store.dispatch(SET_SELECTED_REGION, region);
        },
        async selectStore(store, where) {
            window.parent.postMessage(
                { message: "scrollTop", animated: false, coordinate: 0 },
                "*"
            );

            const storeServices = [];
            for (const s of store.storeServices) {
                for (const p of s.products) {
                    if (this.productUUID.includes(p.uuid)) {
                        storeServices.push(s);
                    }
                }
            }

            this.$store.dispatch(SET_SELECTED_STORE, { ...store, storeServices });

            if (where === "map") {
                this.$store.dispatch(SET_SELECTED_REGION, null);
                this.$store.dispatch(SET_STORES_REGION, []);
            } else {
                this.$store.dispatch(SET_STORES_NEAR, null);
            }

            // if there is only one service, select it and go on
            if (storeServices.length === 1) {
                this.$store.dispatch(SET_SELECTED_SERVICE, storeServices[0]);
                this.$emit("navigation", 1);
            } else {
                this.$emit("navigation", 0.2);
            }
        },
        async preselectStore(storeName) {
            const stores = (
                await ApiService.get("/api/stores", {
                    slug: storeName,
                    products: this.productUUID,
                    pagination: false
                })
            ).data["hydra:member"];

            if (stores[0]) {
                this.selectStore(stores[0], "list");
            } else {
                // fallback: user select the store manually
                await this.fetchAllRegions();
            }
        }
    },
    components: {
        GMAPSearch,
        TwoStepListSelector
    }
};
</script>

<style lang="scss" scoped>
.title {
    padding: 0 12vw;
}
.container-search {
    padding-top: 55px;
}

.or {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 14px;
    white-space: pre-line;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
}

.md-card-media + .md-card-header {
    padding-top: 23px;
}

.md-card-media {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.md-title {
    margin-top: 16px;
}

.services-card {
    display: flex;
    width: 100%;
}

.service-card {
    margin: 20px;
    cursor: pointer;
    box-shadow: none;

    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 300px;
}

.services-container {
    margin-top: 20px;
}

.md-card-actions {
    justify-content: center !important;
}

.md-title {
    text-transform: uppercase;
    text-align: center;
}

#loader {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100%;
    height: 100%;
    background: var(primary);
    top: 0;
    left: 0;
    z-index: 10;
}
::v-deep .md-progress-spinner-circle {
    stroke: var(--primary-slate) !important;
}
</style>
