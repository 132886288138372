<template>
    <div>
        
        <!----- navbar ------>
    <div class="navigation">
        <div class="back">
            <md-button v-if="!isMobile"
                       :class="{ 'md-plain': true} " class="md-button-disabled">
                <img src="/assets/chevron-left.svg" />
                <span>{{ $t('Torna indietro') }}</span>
            </md-button> 
            <md-button v-if="isMobile"
                       :md-ripple="false"
                       class="md-button-disabled">
                <img src="/assets/chevron-left-simple.svg" />
            </md-button>
        </div>
         
        <md-steppers :class="{ 'steppers': true, 'md-steppers--en': $i18n.locale === 'en', 'mobile': isMobile }"
                     md-alternative>
            <md-step id="first" :md-done="true" :md-label="$t('Negozio')" :md-editable="false"></md-step>
            <md-step id="second" :md-done="true" :md-label="$t('Prenota e Prova')" :md-editable="false"></md-step>
        </md-steppers>

        <div class="right">
        </div>
    </div>
    <!--- END navbar --->

        <div class="first-section">
            <img class="logo-tnk" src="/assets/confirmation.svg" />
            <p class="title mb20"> {{ $t('Richiesta inviata') }}</p>
            <p class="subtitle">
                {{ $t('Stiamo verificando la disponibilità del prodotto selezionato nel negozio che hai scelto.') }} 
                <br>
                {{ $t('Ti risponderemo entro 3 ore lavorative attraverso le modalità che hai indicato.') }} 
            </p>
            <div class="blog">
                <md-button @click="navigateToNews()" class="button md-primary">
                    {{ $t('Scopri le novità IQOS') }} 
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ThankYouPageClickAndLending",
    props: {
        configs: Object
    },
    computed: {
        ...mapGetters([
            "getCurrentStep",
            "getCustomer",
            "getSelectedAppointmentTime",
            "getSelectedService",
            "getSelectedStore",
        ])
    },
    data: () => ({
        isMobile: false
    }),
    created(){
        window.parent.postMessage(
            { message: "scrollTop", animated: false, coordinate: 0 },
            "*"
        );

        this.checkIsMobile();
        window.addEventListener("resize", (() => {
            this.checkIsMobile();
        }));
    },
    methods: {
        navigateToNews() {
            window.parent.postMessage({ message: "navigateToNews" }, "*");
        },
        checkIsMobile() {
            this.isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
        }
    },
    components: {
        
    }
};
</script>

<style lang="scss" scoped>
.first-section {
    text-align: center;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.logo-tnk {
    margin-bottom: 30px;
}
.subtitle {
    line-height: 24px;
}
@media (max-width: 768px) {
.logo-tnk {
    margin-bottom: 0;
}
 .subtitle {
    font-size: 18px;
 }   
}
.mb20 {
    margin-bottom: 20px;
}
.text-center {
    text-align: center !important;
}
.host {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 40px;
}

.background {
    width: 100%;

    .recap {
        width: 30%;
        margin: 0 auto 20px auto;
        text-align: left;
    }

    .button {
        margin-top: 20px;
    }

    &.confirm {
        margin-top: 100px;
        align-items: center;
        text-align: center;

        > img {
            width: 93px;
            margin: -76px 0 30px 0;
        }
    }

    &.center-flex {
        justify-content: center;
    }

    .text {
        width: 30%;
        font-size: 14px;
        text-align: justify;
        margin: 0 auto;
    }
}

.blog {
    margin: 60px auto;
    width: 60%;

    button {
        display: flex;
        margin: 60px auto;
    }
}

@media (max-width: 768px) {
    .background {
        display: flex;
        flex-direction: column;

        .recap,
        .text {
            width: 100%;
        }

        &.confirm {
            > img {
                width: 93px;
                margin: -73px 0 16px 0;
            }
        }
    }

    .banner {
        display: none !important;
    }

    .title {
        margin-top: 40px !important;
    }

    ::v-deep .text-recap-margin .text {
        margin-top: -13px !important;
    }

    .text {
        font-size: 14px;
        color: var(text) !important;
        text-align: center;
    }

    img.calendar {
        background: none !important;
    }

    .blog {
        width: 80% !important;
    }
}

/*** custom navbar ***/
.navigation {
    position: fixed;
    width: 100%;
    height: 130px;
    top: 0;
    display: flex;
    align-items: center;
    background: var(--background-hard-white);
    z-index: 9998;

    .back {
        flex: 0.3;
        margin-left: 40px;

        button {
            text-transform: unset !important;
            width: 95px;
            height: 38px;
            margin: 0;

            span {
                margin: -2px 0 0 4px;
            }

            .icon {
                border: solid var(--primary-slate);
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }

            ::v-deep {
                .md-button-content {
                    display: flex;
                    align-items: center;
                }
            }

            &.md-button-disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    .right {
        display: flex;
        flex: 0.3;
        padding: 0 15px;
    }

    .stepper {
        flex: 1;
        display: flex;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navigation {
        height: 80px;
    }

    .back {
        width: 0;
        flex: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        left: -16px;
    }

    .right {
        display: none !important;
    }
}
/*** END custom navbar ***/

/***** steppers *****/
.steppers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--background-hard-white);

    &-portrait {
        display: none;
    }
}

::v-deep {
    .md-steppers {
        &--en {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            //left: 32px !important;
                        }
                    }
                }
            }
        }

        .md-button {
            &.md-active {
                .md-stepper-number {
                    color: var(--primary-white) !important;
                    background: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                    border-color: var(
                        --colours-primary-dark-blue-fill-100
                    ) !important;
                }
            }

            &.md-done {
                .md-stepper-number {
                    background: var(--primary-turquoise) !important;
                    border-color: var(--primary-turquoise) !important;
                    svg {
                        fill: var(--primary-slate) !important;
                    }
                }
            }
        }

        .md-steppers-wrapper {
            display: none;
        }

        .md-steppers-navigation {
            box-shadow: none !important;
            width: 80%;
            margin-top: 20px;
        }

        .md-button-content {
            display: flex;
            flex-direction: column;
            padding-top: 0;
            color: var(--primary-slate) !important;

            &:after {
                height: 3px;
            }
        }

        .md-stepper-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            height: 100% !important;
            pointer-events: none;

            .md-stepper-number {
                display: flex;
                align-items: center;
                justify-content: center;
                position: unset !important;
                width: 36px;
                height: 36px;
                white-space: nowrap;
                margin: 0 8px !important;
                color: var(--tints-slate-slate-50) !important;
                border: 2px solid var(--tints-slate-slate-50) !important;
                background-color: var(--background-hard-white) !important;
                font-size: 16px;
                font-family: iqos-bold;
                z-index: 999;
            }

            .md-stepper-text {
                margin-top: 5px;
                height: auto;
                span {
                    font-family: iqos-bold !important;
                }
            }

            .md-ripple {
                padding: 0 !important;
                height: 60px;
            }

            .md-button-content {
                &:before {
                    left: 0 !important;
                }

                &:after {
                    height: 3px !important;
                    left: 0 !important;
                }
            }
        }

        &:not(.mobile) {
            .md-stepper-header {
                &:first-child {
                    .md-stepper-number {
                        &:after {
                            left: 73px;
                        }
                    }
                }

                &:nth-child(2) {
                    .md-stepper-number {
                        &::before {
                            right: 70px !important;
                        }
                        &:after {
                            left: 70px !important;
                        }
                    }
                }

                &:last-child {
                    .md-stepper-number {
                        &::before {
                            right: 91px !important;
                        }
                    }
                }

                .md-stepper-number {
                    &:after,
                    &:before {
                        height: 2px !important;
                        top: 33px !important;
                        background: var(--tints-slate-slate-50) !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    ::v-deep {
        .md-steppers-navigation {
            margin-top: 0 !important;
        }
        .md-stepper-header {
            &:first-child {
                .md-stepper-number {
                    &:after {
                        left: 63px !important;
                    }
                }
            }

            &:nth-child(2) {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                    &:after {
                        left: 60px !important;
                    }
                }
            }

            &:last-child {
                .md-stepper-number {
                    &::before {
                        right: 60px !important;
                    }
                }
            }

            .md-stepper-number {
                &:after,
                &:before {
                    height: 2px !important;
                    top: 16px !important;
                    background: var(--tints-slate-slate-50) !important;
                }
            }

            .md-button-content {
                padding-top: 0 !important;
                .md-stepper-text {
                    display: none !important;
                }
            }
        }
    }
}

/*** END steppers ***/
</style>
