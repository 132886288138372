<template>
    <div id="app">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
        <!-- snackbar -->
        <md-snackbar :md-duration="2500" :md-active.sync="snackbarOptions.active" md-persistent>
            <span>{{ snackbarOptions.message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import configs from "./configs";
import { GTMService } from "./services/gtm";

export default {
    name: "App",
    created() {
        window.addEventListener("apiError", () => {
            this.snackbarOptions = {
                active: true,
                message: this.$t('Stiamo riscontrando dei problemi, riprova più tardi.'),
            };
        });

        window.addEventListener("locationPermissionDenied", () => {
            this.snackbarOptions = {
                active: true,
                message: this.$t('Consenti prima al browser di accedere alla tua posizione corrente.'),
            };
        });

        const resizeObserver = new ResizeObserver(entries => {
            const newHeight = Math.ceil(entries[0].target.clientHeight);
            if (newHeight !== this.iframeHeight) {
                this.iframeHeight = newHeight;

                window.parent.postMessage(
                    {
                        message: "iframeHeight",
                        height: this.iframeHeight,
                    },
                    "*"
                );
            }
        });

        // start observing a DOM node
        resizeObserver.observe(document.body);

        // log user timer
        const time = new Date();
        window.addEventListener("trackUserTime", () => {
            function msToTime(s) {
                function pad(n, z) {
                    z = z || 2;
                    return ('00' + n).slice(-z);
                }

                const ms = s % 1000;
                s = (s - ms) / 1000;
                const secs = s % 60;
                s = (s - secs) / 60;
                const mins = s % 60;

                return `${pad(mins)} minuti, ${pad(secs)} secondi`;
            }

            GTMService.send('Timer Booking', 'Minutes Range', msToTime(new Date() - time), true);
        });
    },
    data: () => ({
        configs,
        iframeHeight: 0,
        snackbarOptions: {
            active: false,
            message: null,
        }
    })
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Material+Icons");

@font-face {
    font-family: iqos-bold;
    src: url("/fonts/IQOSW04-Bold.woff2");
}
@font-face {
    font-family: iqos-regular;
    src: url("/fonts/IQOSW04-Regular.woff2");
}

* {
    font-family: iqos-regular, "Noto Sans", sans-serif;
}

html {
    height: unset;
    background-color: var(--primary-white) !important;
}
body {
    display: flex;
    min-height: unset;
    letter-spacing: unset;
    background-color: var(--primary-white) !important;
    color: var(--primary-slate) !important;
}

#app {
    width: 100%;
    flex: 1;
}

.title {
    font-size: 30px;
    font-family: iqos-bold;
    margin: 0;
    white-space: pre-line;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
}

.subtitle {
    font-size: 20px;
    margin: 0;
    text-align: center;
}

a {
    color: var(--primary-slate) !important;
}

.button {
    background: var(--colours-primary-dark-blue-fill-100);
    border-radius: 24px;
    color: var(--primary-white) !important;
    font-size: 16px;
    margin: 0;
    text-transform: unset !important;
    padding: 0 16px;
    height: 44px;

    transition: background 0.2s, box-shadow 0.3s ease-out, color 0.2s;
    will-change: background, color;

    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        background: var(--primary-turquoise);
        width: 120%;
        height: 120%;
        z-index: -1;
        transition: transform 0.25s ease-out;
        transform: translate(-101%, -50%) skew(0deg);
        opacity: 1;
        z-index: 1;
    }

    &:hover {
        background: var(--primary-turquoise);
        transition: background 0.15s 0.2s, box-shadow 0.15s 0.25s ease-out,
            color 0.2s;

        &:after {
            transform: translate(-10%, -50%) skew(-30deg);
            opacity: 1;
        }

        svg {
            fill: white;
        }
    }

    .md-button-content {
        line-height: 0;
        z-index: 2;
    }
}

.background {
    background: var(--background-hard-white);

    margin: 80px auto 0 auto;
    padding: 32px;
    width: 72%;

    & > div {
        width: 100%;
        flex: 1;
    }
}

.md-field {
    min-height: unset !important;
    height: 100%;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: white;
}
.md-button:not([disabled]):hover:before {
    background-color: inherit !important;
}

.md-error {
    color: red;
}
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.md-snackbar {
    top: 0;
    bottom: unset;
    z-index: 9999 !important;
}

@media (max-width: 768px) {
    .background {
        margin: 80px auto 0 auto;
        padding: 32px;
        width: 100%;
    }

    .title {
        font-size: 24px;
        margin: 0 24px;
    }

    .md-snackbar {
        width: 100%;
    }

    button {
        height: 48px;
        font-size: 16px !important;
    }
}
</style>
