import axios from "axios";
import * as Resize from 'iframe-resizer/js/iframeResizer.contentWindow.js';
import Vue from 'vue';
import VueAxios from "vue-axios";
import VueMaterial from 'vue-material';
// import 'vue-material/dist/theme/default.css';
import 'vue-material/dist/vue-material.min.css';
import VueRouter from 'vue-router';
import * as VueGoogleMaps from "vue2-google-maps";
import Vuelidate from 'vuelidate';
import App from './App.vue';
import configs from './configs';
import "./helpers/filters";
import utils from './helpers/utils';
import EditAppointmentPage from './pages/EditAppointmentPage';
import HomePage from './pages/HomePage';
import ThankYouPage from './pages/ThankYouPage';
import ThankYouPageClickAndLending from './pages/ThankYouPage-clickAndLending';
import DeviceRequest from './pages/DeviceRequest';
import "./services/geocoder";
import i18n from './services/i18n';
import store from './store';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);
Vue.use(Resize);
Vue.use(VueMaterial);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VueGoogleMaps, {
    load: {
        key: window.BP_GKEY,
        libraries: 'places',
        region: 'IT',
        language: 'it',
    },
});

Vue.config.productionTip = false;

utils.applyPalette(configs.palette);

const routes = [
    { path: '/', component: HomePage, props: { configs } },
    { path: '/thankyou/:id', component: ThankYouPage, props: { configs } },
    { path: '/thankyou-click/', component: ThankYouPageClickAndLending, props: { configs } },
    { path: '/device-request/', component: DeviceRequest, props: { configs } },
    { path: '/appointments/:id', component: EditAppointmentPage, props: { configs } },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

new Vue({
    store,
    router,
    render: h => h(App),
    i18n,
}).$mount('#app');

