<template>
  <transition name="fade" mode="out-in">
    <div>
      <p class="title">{{ $t('Inserisci le tue informazioni e completa la prenotazione') }}</p>
      <div class="background" :class="{ 'no-events': sending }">
        <Recap
          v-if="getSelectedStore | getCurrentStep > 0"
          class="recap"
          :store="getCurrentStep > 0 ? getSelectedStore : null"
          :appointment="getCurrentStep > 1 ? getSelectedAppointmentTime : null"
          currentStep="2"
          @changeData="changeData()"
          @changeStore="changeStore()"
        />
        <form novalidate class="md-layout" @submit.prevent="validateForm">

          <div class="box-notice">
            <p class="description-notice">
              {{$t('Inserendo i tuoi dati e cliccando su “Conferma”, accetti i ')}}
              <span class="link-notice" @click="openTermsAndCondition()" >{{$t('Termini e condizioni')}}</span>
               {{$t('del sito e dichiari di aver preso visione dell’')}}<span class="link-notice" @click="openPrivacyPolicyNotice()" >{{$t('Informativa Privacy')}}</span>.
            </p>
          </div>

          <div>
            <!-- Name  -->
            <md-field :class="getValidationClass('name')">
              <label for="first-name">{{ $t('Nome') }}*</label>
              <md-input
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                :disabled="sending"
                v-model="form.name"
                @change="detectInput('name')"
              />
              <span
                class="md-error"
                v-if="!$v.form.name.required"
              >{{ $t('Inserisci il tuo nome per proseguire') }}</span>
              <span
                class="md-error"
                v-else-if="!$v.form.name.minlength"
              >{{ $t('Nome troppo corto') }}</span>
            </md-field>

            <!-- Surname -->
            <md-field :class="getValidationClass('surname')">
              <label for="last-name">{{ $t('Cognome') }}*</label>
              <md-input
                name="last-name"
                id="last-name"
                autocomplete="family-name"
                :disabled="sending"
                v-model="form.surname"
                @change="detectInput('surname')"
              />
              <span
                class="md-error"
                v-if="!$v.form.surname.required"
              >{{ $t('Inserisci il tuo cognome per proseguire') }}</span>
              <span
                class="md-error"
                v-else-if="!$v.form.surname.minlength"
              >{{ $t('Cognome troppo corto') }}</span>
            </md-field>
          </div>

          <div>
            <!-- Email -->
            <md-field :class="getValidationClass('email')">
              <label for="email">Email*</label>
              <md-input
                type="email"
                name="email"
                id="email"
                autocomplete="email"
                :disabled="sending"
                v-model="form.email"
                @change="detectInput('mail')"
              />
              <span
                class="md-error"
                v-if="!$v.form.email.required"
              >{{ $t('Inserisci la tua email per proseguire') }}</span>
              <span class="md-error" v-else-if="!$v.form.email.email">{{ $t('Email non valida') }}</span>
            </md-field>

            <!-- Phone -->
            <md-field :class="getValidationClass('phone')">
              <label for="phone">{{ $t('Telefono') }}</label>
              <md-input
                id="phone"
                name="phone"
                autocomplete="phone"
                :disabled="sending"
                v-model="form.phone"
                @change="detectInput('phone')"
              />
              <span
                class="md-error"
                v-if="$v.form.phone.$invalid"
              >{{ $t('Numero di telefono non valido') }}</span>
            </md-field>
          </div>
          <div>
            <p class="description-notice mb-md-description-notice">
              {{$t('Sei un nuovo utente non registrato su IQOS.com? Fatto salvo il diritto di trattare dati personali per finalità di marketing sulla base del legittimo interesse come precisato nell’ ')}}
              <span class="link-notice" @click="openPrivacyPolicyNotice()" >{{$t('Informativa Privacy')}}</span>,
               {{$t('desidero fornire a Philip Morris Italia il consenso a:')}}
            </p>
          </div>
          <div class="box-cont">
            <input class="input-checkbox" type="checkbox" id="checkbox" v-model="valueMarketResearch" />
            <span class="checkmark"></span>
            <label for="checkbox" class="">{{ $t('Inviami novità legate ai prodotti senza fumo e ricevere informazioni sui prodotti e servizi che mi permettano di usare al meglio tali prodotti senza fumo') }}</label>
          </div>
           <div>
            <p class="description-notice mt-md-description-notice">
              {{$t('Puoi cambiare idea o revocare il tuo consenso in qualsiasi momento, direttamente dal link nelle nostre comunicazioni oppure contattando il')}}
              <span class="link-notice" @click="openCostumerCare()" >{{$t('Servizio Clienti')}}</span>.
            </p>
          </div>
          <md-progress-bar md-mode="indeterminate" v-if="sending" class="progress" />

          <md-button class="invisible-submit" type="submit"></md-button>
        </form>

        <p class="privacy">
          <!-- {{ $t('I dati personali sono trattati da Philip Morris Italia, in qualità di titolare del trattamento, al fine di gestire il servizio di appuntamento.') }} -->
          <!-- <br />
          {{ $t('L’informativa privacy completa è disponibile ') }}
          <span
            class="link"
            @click="openPrivacyPolicy()"
          >{{ $t('qui') }}</span>. -->
          <!-- <br /> -->
          {{ $t('Procedendo con la prenotazione dichiari di essere un fumatore.') }}
          <br />
          {{ $t('L’accesso al negozio è permesso solo ai maggiorenni ed è garantito ad una persona per appuntamento.') }}
        </p>

        <md-button
          class="button md-primary"
          :disabled="sending"
          @click="submitForm()"
        >{{ $t('Conferma') }}</md-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ApiService } from "../services/api";
import { GTMService } from "../services/gtm";
import { mapGetters } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import {
  SET_CUSTOMER,
  INITIALIZE_STEPS_STATUS,
  GOTO_STEP
} from "../store/action.type";
import { validationMixin } from "vuelidate";
import axios from "axios";
import Recap from "../components/Recap";
import utils from "../helpers/utils";

const phoneValidator = value => (value ? /^[+]?\d+$/.test(value) : true);

export default {
  name: "ContactsPage",
  props: {
    configs: Object
  },
  computed: {
    ...mapGetters([
      "getSelectedStore",
      "getSelectedService",
      "getCurrentStep",
      "getSelectedAppointmentTime",
      "getProductUUID"
    ])
  },
  // set firstName - lastName - phone - email and other value //
  async created() {
    const paramsQueryString = new URLSearchParams(window.location.search);
    // set firstName
    if (paramsQueryString.get("bcf") !== null) {
      this.form.name = paramsQueryString.get("bcf");
      this.startSubmission("name");
      //console.log("firstName -> ", paramsQueryString.get("bcf"));
    }
    // set lastName
    if (paramsQueryString.get("bcl") !== null) {
      this.form.surname = paramsQueryString.get("bcl");
      this.startSubmission("surname");
      //console.log("lastName -> ", paramsQueryString.get("bcl"));
    }
    // set userPhone
    if (paramsQueryString.get("bcp") !== null) {
      this.form.phone = paramsQueryString.get("bcp");
      this.startSubmission("phone");
      //console.log("userPhone -> ", paramsQueryString.get("bcp"));
    }
    // set userEmail
    if (paramsQueryString.get("bce") !== null) {
      this.form.email = paramsQueryString.get("bce");
      this.startSubmission("mail");
      //console.log("userEmail -> ", paramsQueryString.get("bce"));
    }
    // post message showInputsForm
    window.parent.postMessage({ message: "showInputsForm" },"*");
    //console.log("Postmessage -> showInputsForm ");
  },
  mixins: [validationMixin],
  beforeDestroy() {
    this.request.cancel();
  },
  data: () => ({
    request: axios.CancelToken.source(),
    form: {
      name: null,
      surname: null,
      phone: null,
      email: null
    },
    valueMarketResearch: false,
    formTouched: false,
    sending: false,
    changedVal: false,
    requiredFields: false
  }),
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      phone: {
        phoneValidator
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    ...utils,
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.name = null;
      this.form.surname = null;
      this.form.phone = null;
      this.form.email = null;
      this.valueMarketResearch = false;
    },
    async setAppointment() {
      this.sending = true;
      try {
        const appointmentResponse = await ApiService.post(
          `/api/store_services/${this.getSelectedService.uuid}/appointments`,
          {
            startTime: this.getSelectedAppointmentTime.startTime,
            endTime: this.getSelectedAppointmentTime.endTime,
            products: this.getProductUUID,
            clients: [
              {
                name: this.form.name,
                surname: this.form.surname,
                email: this.form.email,
                phoneNumber: this.form.phone || ""
              }
            ],
            marketResearch: this.valueMarketResearch,
            notes: ""
          },
          { cancelToken: this.request.token }
        );
        const appointmentUuid = appointmentResponse.data.uuid;
        this.sending = false;
        this.$store.dispatch(SET_CUSTOMER, { ...this.form });
        this.$store.dispatch(INITIALIZE_STEPS_STATUS);
        this.$store.dispatch(GOTO_STEP, "0");
        this.clearForm();
        window.parent.postMessage(
          { message: "scrollTop", animated: false, coordinate: 0 },
          "*"
        );
        this.$router.push("thankyou/" + appointmentUuid);
      } catch (e) {
        this.sending = false;
      }
    },
    validateForm() {
      this.$v.$touch();
      this.formTouched = true;
      if (!this.$v.$invalid) {
        this.setAppointment();
        GTMService.send(
          "Form Submitted",
          "Prenota un appuntamento",
          null,
          false
        );
      } else {
        // GTM events
        if (this.$v.form.email.$invalid) {
          if (this.form.phone) {
            GTMService.send("Form Failed", "Wrong email", null, false);
          } else {
            GTMService.send(
              "Form Failed",
              "Wrong email & Missing number",
              null,
              false
            );
          }
        } else if (!this.form.phone) {
          GTMService.send("Form Failed", "Wrong email", null, false);
        }
      }
    },
    submitForm() {
      document.querySelector(".invisible-submit").click();
    },
    openPrivacyPolicy() {
      window.parent.postMessage({ message: "openPrivacyPolicy" }, "*");
    },
    openTermsAndCondition() {
      window.parent.postMessage({ message: "openTermsAndCondition" }, "*");
    },
    openPrivacyPolicyNotice() {
      window.parent.postMessage({ message: "openPrivacyPolicyNotice" }, "*");
    },
    openCostumerCare() {
      window.parent.postMessage({ message: "openCostumerCare" }, "*");
    },
    startSubmission(value) {
      this.changedVal = true;
      window.parent.postMessage({ message: "startSubmission", type: value  }, "*");
      //console.log("Postmessage -> startSubmission ", value );
    },
    detectInput(typeInput) {
      //console.log("changedVal -> ", this.changedVal);
      if(this.changedVal == false) {
        // post message start submission
        if(typeInput == "name") {
          this.startSubmission("name");

        } else if (typeInput == "surname") {
          this.startSubmission("surname");

        } else if (typeInput == "mail") {
          this.startSubmission("mail");

        } else if (typeInput == "phone") {
          this.startSubmission("phone");

        } 
      }
      
      // post message detect validation and required field 
      if(this.$v.form.name.$invalid == false && this.$v.form.surname.$invalid == false && this.$v.form.email.$invalid == false && this.requiredFields == false) {
        this.requiredFields = true;
        window.parent.postMessage({ message: "validationAndRequiredFields" }, "*");
        //console.log("campi required validi");
      }
      
    }
  },
  components: {
    Recap
  }
};
</script>

<style lang="scss" scoped>
/**** checkbox ****/
.box-cont {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
.box-cont label {
  cursor: pointer;
}
.box-cont .input-checkbox {
    margin: 0;
    left: 0;
    position: absolute;
    z-index: 9;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
    top: -2px;
}
.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #34303d;
  border-radius: 5px;
  cursor: pointer;
}
.box-cont .input-checkbox:checked ~ .checkmark {
  background-color: #34303d;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.box-cont .input-checkbox:checked ~ .checkmark:after {
  display: block;
}
.box-cont .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/**** END checkbox ****/

.link-notice {
  color: var(--primary-slate) !important;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.box-notice {
  margin-bottom: 0;
}
.description-notice {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .mb-md-description-notice {
    margin-bottom: 25px;
  }
  .mt-md-description-notice {
    margin-top: 25px;
  }
}

.background {
  display: flex;
  flex-direction: column;
}
.md-card-content {
  padding: 0px;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.progress {
  background-color: var(--primary-slate) !important;
}

::v-deep .md-progress-bar-fill:after {
  background-color: black !important;
}

::v-deep .md-progress-bar-track:after {
  background-color: black !important;
}

::v-deep .md-progress-bar-fill:after {
  background-color: black !important;
}
.no-shadow {
  box-shadow: none;
}

.arrow-forward {
  position: relative;
  left: 9px;
  top: -2px;
  color: var(--primary-slate) !important;
}

.privacy {
  text-align: center;
  margin: 24px 0 0 0;
  font-size: 14px;

  .link {
    color: var(--primary-slate) !important;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  background: var(--primary-white);
  border-radius: 4px;
  margin-top: 24px;
  padding: 8px 24px 24px;

  > div {
    display: flex;
    flex: 1;
    margin: 8px 0;

    .md-field {
      margin: 0;

      label {
        background: var(--primary-white);
        color: var(--tints-slate-slate-65);
        top: 30px;
        left: 14px;
      }

      input {
        border-radius: 4px;
        border: 1px solid var(--tints-slate-slate-15);
        height: 48px;
        padding: 0 16px;
        margin: 0;
      }

      &.md-focused,
      &.md-has-value {
        label {
          color: var(--primary-slate) !important;
          top: 4px;
          padding: 0 4px;
        }
        input {
          border-color: var(--primary-slate);
          border-width: 1.5px;
        }
      }

      &:before,
      &:after {
        display: none;
      }

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  .invisible-submit {
    display: none;
  }
}

.button {
  margin: 40px auto 0 auto;
}

.api-error {
  text-align: center;
  width: 100%;
  position: absolute;
}

.no-events {
  pointer-events: none;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .background {
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 40px;
  }

  form {
    background: white;
    padding: 8px 14px 20px 14px;
    margin-top: 24px;

    > div {
      flex-direction: column;
      margin: 0 !important;

      .md-field {
        margin-bottom: 16px !important;

        label {
          top: 32px;
        }

        input {
          height: 52px !important;
        }
      }
    }
  }
}
</style>
