import {
    GOTO_STEP,
    INITIALIZE_STEPS_STATUS,
    NEXT_STEP,
    SET_CUSTOMER,
    SET_PRODUCT_UUID,
    SET_REGIONS_LIST,
    SET_SELECTED_APPOINTMENT_TIME,
    SET_SELECTED_REGION,
    SET_SELECTED_SERVICE,
    SET_SELECTED_STORE,
    SET_STEPS_STATUS,
    SET_STORE_PRESELECTED,
    SET_STORES_NEAR,
    SET_STORES_REGION,
    SET_TIME_SPENT,
    SET_SERVICE_QUERY_STRING,
    SET_SELECTED_STORE_VIDEOCALL
} from '../action.type';

const state = () => ({
    currentStep: '0',
    customer: { name: null, surname: null, email: null, phone: null },
    productUUID: null,
    regions: [],
    selectedAppointmentTime: null,
    selectedProduct: null,
    selectedRegion: '',
    selectedService: null,
    selectedStore: null,
    stepsStatus: {},
    storePreselected: false,
    storesNear: null,
    storesRegion: [],
    timeSpentOnBookingPlatform: 0,
    serviceQueryString: false,
    selectedStoreVideoCall: null
});

const getters = {
    getCurrentStep: (state) => state.currentStep.toString(),
    getCustomer: (state) => state.customer,
    getProductUUID: (state) => state.productUUID,
    getRegionsList: (state) => state.regions,
    getSelectedAppointmentTime: (state) => state.selectedAppointmentTime,
    getSelectedRegion: (state) => state.selectedRegion,
    getSelectedService: (state) => state.selectedService,
    getSelectedStore: (state) => state.selectedStore,
    getStepsStatus: (state) => state.stepsStatus,
    getStorePreselected: (state) => state.storePreselected,
    getStoresNear: (state) => state.storesNear,
    getStoresRegion: (state) => state.storesRegion,
    getTimeSpentOnBookingPlatform: (state) => state.timeSpentOnBookingPlatform,
    getServiceQueryString: (state) => state.serviceQueryString,
    getSelectedStoreVideoCall: (state) => state.selectedStoreVideoCall,
};

const actions = {
    [NEXT_STEP]({ commit, state }) {
        commit('setStep', (Number(state.currentStep) + 1).toString());
    },
    [GOTO_STEP]({ commit }, step) {
        commit('setStep', step);
    },
    [INITIALIZE_STEPS_STATUS]({ commit }, steps) {
        commit('initializeStepsStatus', steps);
    },
    [SET_SELECTED_REGION]({ commit }, region) {
        commit('setSelectedRegion', region);
    },
    [SET_SELECTED_STORE]({ commit }, store) {
        commit('setSelectedStore', store);
    },
    [SET_REGIONS_LIST]({ commit }, regions) {
        commit('setRegionList', regions);
    },
    [SET_STORES_NEAR]({ commit }, stores) {
        commit('setStoresNear', stores);
    },
    [SET_STORES_REGION]({ commit }, stores) {
        commit('setStoresRegion', stores);
    },
    [SET_SELECTED_SERVICE]({ commit }, service) {
        commit('setSelectedService', service);
    },
    [SET_SELECTED_APPOINTMENT_TIME]({ commit }, time) {
        commit('setSelectedAppointmentTime', time);
    },
    [SET_STEPS_STATUS]({ commit }, status) {
        commit('setStepsStatus', status);
    },
    [SET_CUSTOMER]({ commit }, customer) {
        commit('setCustomer', customer);
    },
    [SET_STORE_PRESELECTED]({ commit }, storePreselected) {
        commit('setStorePreselected', storePreselected);
    },
    [SET_PRODUCT_UUID]({ commit }, productUUID) {
        commit('setProductUUID', productUUID);
    },
    [SET_TIME_SPENT]({ commit }, time) {
        commit('setTimeSpentOnBookingPlatform', time);
    },
    [SET_SERVICE_QUERY_STRING]({ commit }, status) {
        commit('setServiceQueryString', status);
    },
    [SET_SELECTED_STORE_VIDEOCALL]({ commit }, status) {
        commit('setSelectedStoreVideoCall', status);
    },
}

const mutations = {
    setStep(state, step) {
        state.stepsStatus[step] = true;
        state.currentStep = step;
    },
    setSelectedStore(state, store) {
        state.selectedStore = store;
    },
    setSelectedRegion(state, region) {
        state.selectedRegion = region;
    },
    setRegionList(state, regions) {
        state.regions = regions.sort();
    },
    setStoresNear(state, stores) {
        state.storesNear = stores;
    },
    setStoresRegion(state, stores) {
        state.storesRegion = stores.sort((a, b) => a.name.localeCompare(b, { sensitivity: 'base' }));
    },
    setSelectedService(state, service) {
        state.selectedService = service;
    },
    setSelectedAppointmentTime(state, time) {
        state.selectedAppointmentTime = time;
    },
    setStepsStatus(state, status) {
        state.stepsStatus = status;
    },
    setCustomer(state, customer) {
        state.customer = customer;
    },
    initializeStepsStatus(state, steps) {
        const arraySteps = [];
        for (const s in steps) {
            arraySteps.push(!s);
        }
        state.stepsStatus = { ...arraySteps };
    },
    setSelectedProduct(state, product) {
        state.selectedProduct = product;
    },
    setStorePreselected(state, storePreselected) {
        state.storePreselected = storePreselected;
    },
    setProductUUID(state, productUUID) {
        state.productUUID = productUUID;
    },
    setTimeSpentOnBookingPlatform(state, time) {
        state.productUUID = time;
    },
    setServiceQueryString(state, serviceQueryString) {
        state.serviceQueryString = serviceQueryString;
    },
    setSelectedStoreVideoCall(state, selectedStoreVideoCall) {
        state.selectedStoreVideoCall = selectedStoreVideoCall;
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}