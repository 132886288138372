import Vue from "vue";

export const ApiService = {

    API_ENDPOINT: window.BP_API_URL,

    toQueryString(filter, properties) {
        const str = [];
        for (const p in filter) {
            if (Object.prototype.hasOwnProperty.call(filter, p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
            }
        }

        for (const p in properties) {
            str.push('properties[]' + "=" + encodeURIComponent(properties[p]));
        }
        return str.join("&");
    },

    get(url, filter, properties) {
        const path = `${this.API_ENDPOINT}${url}${filter || properties ? '?' + this.toQueryString(filter, properties) : ''}`;
        return Vue.axios.get(path)
            .catch(() => {
                window.dispatchEvent(new Event('apiError'));
            });
    },

    post(url, body, opts) {
        body = body || {};
        const match = RegExp('[?&]ref=([^&]*)').exec(window.location.search);
        const bcid = RegExp('[?&]bcid=([^&]*)').exec(window.location.search);
        const bct = RegExp('[?&]bct=([^&]*)').exec(window.location.search);

        if (match) {
            body.referral = match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        }

        if (bcid) {
            body.clients[0].crmClientId = bcid && decodeURIComponent(bcid[1].replace(/\+/g, ' '));
        }

        if (bct) {
            body.clients[0].clientType = bct && decodeURIComponent(bct[1].replace(/\+/g, ' '));
        }

        return Vue.axios.post(this.API_ENDPOINT + url, body, opts).catch((e) => {
            window.dispatchEvent(new Event('apiError'));
            return e
        });
    },

    patch(url, body, opts) {
        return Vue.axios.patch(this.API_ENDPOINT + url, body, opts).catch(() => {
            window.dispatchEvent(new Event('apiError'));
        });
    },

    delete(url, body, opts) {
        return Vue.axios.delete(this.API_ENDPOINT + url, body, opts).catch(() => {
            window.dispatchEvent(new Event('apiError'));
        });
    },
};